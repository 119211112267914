// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Modal$DvmAdminFrontendFr from "../../../../../../common/components/App_Modal.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../../../common/components/button/App_Button.bs.js";
import * as SimpleAlert$DvmAdminFrontendFr from "../../../../../../alert/SimpleAlert.bs.js";
import * as Utils_Alert$DvmAdminFrontendFr from "../../../../../../common/utils/Utils_Alert.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../../../context/user/User_Context.bs.js";
import * as Messages_Button$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Button.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../../../common/types/App_Types_Result.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../../../../common/components/App_OverlaySpinner.bs.js";
import * as Messages_Orders_Derogate$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Derogate.bs.js";
import * as Order_Detail_Types_Order_Type$DvmAdminFrontendFr from "../../../Order_Detail_Types_Order_Type.bs.js";
import * as Order_Detail_Bonus_Derogate_Inputs$DvmAdminFrontendFr from "./Order_Detail_Bonus_Derogate_Inputs.bs.js";
import * as Order_Detail_Bonus_Derogate_Confirm$DvmAdminFrontendFr from "../confirm/Order_Detail_Bonus_Derogate_Confirm.bs.js";
import * as Order_Detail_Bonus_Derogate_Refused$DvmAdminFrontendFr from "../confirm/Order_Detail_Bonus_Derogate_Refused.bs.js";
import * as Order_Detail_Bonus_Derogate_Create_Api$DvmAdminFrontendFr from "./Order_Detail_Bonus_Derogate_Create_Api.bs.js";
import * as Order_Detail_Bonus_Derogate_Order_Info$DvmAdminFrontendFr from "./Order_Detail_Bonus_Derogate_Order_Info.bs.js";
import * as Order_Detail_Bonus_Derogate_Action_Info$DvmAdminFrontendFr from "./Order_Detail_Bonus_Derogate_Action_Info.bs.js";
import * as Order_Detail_Bonus_Derogate_Create_Form$DvmAdminFrontendFr from "./Order_Detail_Bonus_Derogate_Create_Form.bs.js";
import * as Order_Detail_Bonus_Derogate_Create_Types$DvmAdminFrontendFr from "./Order_Detail_Bonus_Derogate_Create_Types.bs.js";
import * as Order_Detail_Bonus_Derogate_Inputs_NonFleet$DvmAdminFrontendFr from "./Order_Detail_Bonus_Derogate_Inputs_NonFleet.bs.js";

function whichAmount(dero) {
  var match = dero.amountOriginal;
  var match$1 = dero.amount;
  if (match !== undefined) {
    return Math.abs(match);
  } else if (match$1 !== undefined) {
    return Math.abs(match$1);
  } else {
    return ;
  }
}

function Order_Detail_Bonus_Derogate_Create(Props) {
  var order = Props.order;
  var orderId = Props.orderId;
  var action = Props.action;
  var onClose = Props.onClose;
  var onOk = Props.onOk;
  var orderType = Props.orderType;
  var editedDero = Props.editedDero;
  var usedAction = Props.usedAction;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var userManager = match$1.userManager;
  var selectedRole = match$1.selectedRole;
  var alert = ReactAlert.useAlert();
  var match$2 = React.useState(function () {
        return /* [] */0;
      });
  var setActionIdOptions = match$2[1];
  var actionIdOptions = match$2[0];
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setSaveResult = match$3[1];
  var saveResult = match$3[0];
  var match$4 = React.useState(function () {
        return /* NotStarted */0;
      });
  var actionFetch = match$4[0];
  var match$5 = React.useState(function () {
        return null;
      });
  var setModal = match$5[1];
  var form = Order_Detail_Bonus_Derogate_Create_Form$DvmAdminFrontendFr.Form.useForm(editedDero !== undefined ? ({
            negative: editedDero.negative,
            action: usedAction !== undefined ? usedAction : "",
            actionId: Belt_Option.getWithDefault(editedDero.actionId, ""),
            derogateLabel: Belt_Option.getWithDefault(editedDero.derogateLabel, ""),
            amount: Belt_Option.mapWithDefault(orderType !== 0 ? whichAmount(editedDero) : editedDero.amount, "", (function (prim) {
                    return String(prim);
                  })),
            comment: Belt_Option.getWithDefault(editedDero.comment, ""),
            validation: Belt_Option.getWithDefault(editedDero.validation, "")
          }) : (
          action !== undefined ? ({
                negative: Order_Detail_Bonus_Derogate_Create_Form$DvmAdminFrontendFr.initialState.negative,
                action: action.actionType,
                actionId: action.actionId,
                derogateLabel: Order_Detail_Bonus_Derogate_Create_Form$DvmAdminFrontendFr.initialState.derogateLabel,
                amount: Order_Detail_Bonus_Derogate_Create_Form$DvmAdminFrontendFr.initialState.amount,
                comment: Order_Detail_Bonus_Derogate_Create_Form$DvmAdminFrontendFr.initialState.comment,
                validation: Order_Detail_Bonus_Derogate_Create_Form$DvmAdminFrontendFr.initialState.validation
              }) : (
              orderType === /* SO */0 ? ({
                    negative: Order_Detail_Bonus_Derogate_Create_Form$DvmAdminFrontendFr.initialState.negative,
                    action: "budget",
                    actionId: Order_Detail_Bonus_Derogate_Create_Form$DvmAdminFrontendFr.initialState.actionId,
                    derogateLabel: Order_Detail_Bonus_Derogate_Create_Form$DvmAdminFrontendFr.initialState.derogateLabel,
                    amount: Order_Detail_Bonus_Derogate_Create_Form$DvmAdminFrontendFr.initialState.amount,
                    comment: Order_Detail_Bonus_Derogate_Create_Form$DvmAdminFrontendFr.initialState.comment,
                    validation: Order_Detail_Bonus_Derogate_Create_Form$DvmAdminFrontendFr.initialState.validation
                  }) : Order_Detail_Bonus_Derogate_Create_Form$DvmAdminFrontendFr.initialState
            )
        ), (function (param, submissionCallbacks) {
          Order_Detail_Bonus_Derogate_Create_Api$DvmAdminFrontendFr.saveOrEditDerogate(userManager, setSaveResult, orderId, orderType !== 0 ? "" : param.action, {
                derogateId: Belt_Option.map(editedDero, (function (x) {
                        return x.derogateId;
                      })),
                actionId: param.actionId,
                negative: param.negative,
                derogateLabel: param.derogateLabel,
                amount: param.amount,
                validation: param.validation,
                comment: param.comment,
                orderType: Order_Detail_Types_Order_Type$DvmAdminFrontendFr.toString(orderType)
              }, submissionCallbacks, selectedRole, Belt_Option.isSome(editedDero) ? /* Edit */0 : /* Create */1);
        }));
  React.useEffect((function () {
          if (!Belt_Option.isSome(editedDero) && orderType === 0 && form.input.action !== "budget") {
            if (Belt_Option.isSome(action)) {
              Curry._2(form.updateActionId, (function (input, actionId) {
                      return {
                              negative: input.negative,
                              action: input.action,
                              actionId: actionId,
                              derogateLabel: input.derogateLabel,
                              amount: input.amount,
                              comment: input.comment,
                              validation: input.validation
                            };
                    }), Belt_Option.mapWithDefault(action, "", (function (a) {
                          return a.actionId;
                        })));
              var option_paramOne = Belt_Option.mapWithDefault(action, "", (function (a) {
                      return a.actionId;
                    }));
              var option_paramTwo = Belt_Option.mapWithDefault(action, "", (function (a) {
                      return Belt_Option.getWithDefault(a.actionIdLabel, "");
                    }));
              var option = {
                paramOne: option_paramOne,
                paramTwo: option_paramTwo
              };
              Curry._1(setActionIdOptions, (function (param) {
                      return {
                              hd: option,
                              tl: /* [] */0
                            };
                    }));
            } else {
              Curry._2(form.updateActionId, (function (input, actionId) {
                      return {
                              negative: input.negative,
                              action: input.action,
                              actionId: actionId,
                              derogateLabel: input.derogateLabel,
                              amount: input.amount,
                              comment: input.comment,
                              validation: input.validation
                            };
                    }), "");
              Order_Detail_Bonus_Derogate_Create_Api$DvmAdminFrontendFr.fetchActionSelect(alert, userManager, setActionIdOptions, selectedRole, /* SO */0, form.input.action);
            }
          }
          
        }), [form.input.action]);
  React.useEffect((function () {
          if (Belt_Option.isSome(editedDero) || orderType > 8 || orderType < 1) {
            
          } else {
            Order_Detail_Bonus_Derogate_Create_Api$DvmAdminFrontendFr.fetchActionSelect(alert, userManager, setActionIdOptions, selectedRole, orderType, form.input.action);
          }
        }), []);
  React.useEffect((function () {
          if (typeof saveResult === "number") {
            saveResult === /* NotStarted */0;
          } else if (saveResult.TAG === /* Ok */0) {
            Curry._1(onOk, undefined);
          } else {
            Utils_Alert$DvmAdminFrontendFr.error(undefined, alert, React.createElement(SimpleAlert$DvmAdminFrontendFr.make, {
                      message: {
                        TAG: /* RestError */2,
                        _0: saveResult._0
                      }
                    }));
          }
        }), [saveResult]);
  return React.createElement(App_Modal$DvmAdminFrontendFr.make, {
              open: true,
              onClose: onClose,
              title: intl.formatMessage(Messages_Orders_Derogate$DvmAdminFrontendFr.derogateTitle, {
                    id: order.orderNumberCustomer
                  }),
              minWidth: "868px",
              children: null
            }, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                  show: App_Types_Result$DvmAdminFrontendFr.isPending(saveResult),
                  children: React.createElement("form", {
                        onSubmit: (function ($$event) {
                            $$event.preventDefault();
                            Curry._1(setModal, (function (param) {
                                    if (Belt_Option.getWithDefault(Belt_Float.fromString(form.input.amount), 0.0) >= Order_Detail_Bonus_Derogate_Create_Types$DvmAdminFrontendFr.maxAmount) {
                                      return React.createElement(Order_Detail_Bonus_Derogate_Refused$DvmAdminFrontendFr.make, {
                                                  amount: Order_Detail_Bonus_Derogate_Create_Types$DvmAdminFrontendFr.maxAmount,
                                                  onClose: (function (param) {
                                                      Curry._1(setModal, (function (param) {
                                                              return null;
                                                            }));
                                                    })
                                                });
                                    } else {
                                      return React.createElement(Order_Detail_Bonus_Derogate_Confirm$DvmAdminFrontendFr.make, {
                                                  amount: Belt_Option.mapWithDefault(Belt_Float.fromString(form.input.amount), 0.0, (function (x) {
                                                          if (form.input.negative) {
                                                            return -1 * x;
                                                          } else {
                                                            return x;
                                                          }
                                                        })),
                                                  onClose: (function (param) {
                                                      Curry._1(setModal, (function (param) {
                                                              return null;
                                                            }));
                                                    }),
                                                  onOk: (function (param) {
                                                      Curry._1(setModal, (function (param) {
                                                              return null;
                                                            }));
                                                      Curry._1(form.submit, undefined);
                                                    })
                                                });
                                    }
                                  }));
                          })
                      }, React.createElement(Core.Box, {
                            children: null,
                            marginX: 1
                          }, React.createElement(Core.Grid, {
                                children: null,
                                container: true,
                                direction: "column",
                                spacing: 3
                              }, React.createElement(Order_Detail_Bonus_Derogate_Order_Info$DvmAdminFrontendFr.make, {
                                    order: order
                                  }), React.createElement(Order_Detail_Bonus_Derogate_Action_Info$DvmAdminFrontendFr.make, {
                                    action: action,
                                    actionType: form.input.action,
                                    actionId: form.input.actionId,
                                    orderType: orderType,
                                    actionFetch: actionFetch,
                                    setActionFetch: match$4[1],
                                    displayAmounts: true
                                  })), orderType !== 0 ? React.createElement(Order_Detail_Bonus_Derogate_Inputs_NonFleet$DvmAdminFrontendFr.make, {
                                  action: action,
                                  form: form,
                                  actionIdOptions: actionIdOptions,
                                  editedDero: editedDero,
                                  actionFetch: actionFetch
                                }) : React.createElement(Order_Detail_Bonus_Derogate_Inputs$DvmAdminFrontendFr.make, {
                                  action: action,
                                  form: form,
                                  actionIdOptions: actionIdOptions,
                                  editedDero: editedDero,
                                  actionFetch: actionFetch
                                }), React.createElement(Core.Box, {
                                children: React.createElement(Core.Grid, {
                                      children: React.createElement(Core.Grid, {
                                            children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                  label: intl.formatMessage(Messages_Button$DvmAdminFrontendFr.confirm),
                                                  size: "medium",
                                                  type_: "submit"
                                                }),
                                            item: true
                                          }),
                                      container: true,
                                      justify: "flex-end"
                                    }),
                                mt: 2
                              })))
                }), match$5[0]);
}

var Form;

var Api;

var Types;

var make = Order_Detail_Bonus_Derogate_Create;

export {
  Form ,
  Api ,
  Types ,
  whichAmount ,
  make ,
}
/* react Not a pure module */
