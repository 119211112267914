// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../../common/types/App_Types_Result.bs.js";
import * as Order_Detail_Correct_Api$DvmAdminFrontendFr from "./Order_Detail_Correct_Api.bs.js";

function saveCorrection(formOutput, formPerfOutput, formVehicleOutput, formAdditionalOutput, formSubmissionCallbacks, formPerfSubmissionCallbacks, formVehicleSubmissionCallbacks, formAdditionalSubmissionCallbacks, userManager, setResult, selectedRole, orderId, order) {
  if (formOutput === undefined) {
    return ;
  }
  if (formPerfOutput === undefined) {
    return ;
  }
  if (formVehicleOutput === undefined) {
    return ;
  }
  if (formAdditionalOutput === undefined) {
    return ;
  }
  if (formSubmissionCallbacks === undefined) {
    return ;
  }
  if (formPerfSubmissionCallbacks === undefined) {
    return ;
  }
  if (formVehicleSubmissionCallbacks === undefined) {
    return ;
  }
  if (formAdditionalSubmissionCallbacks === undefined) {
    return ;
  }
  var match = formOutput.orderType;
  var match$1 = formOutput.orderType;
  var match$2 = formOutput.orderType;
  var match$3 = formOutput.orderType;
  var match$4 = formOutput.orderType;
  var match$5 = formOutput.orderType;
  var match$6 = formOutput.orderType;
  var match$7 = formOutput.orderType;
  var match$8 = formOutput.orderType;
  var match$9 = formOutput.orderType;
  var match$10 = formOutput.orderType;
  var match$11 = formOutput.orderType;
  var match$12 = formOutput.orderType;
  var tmp;
  var exit = 0;
  switch (match$12) {
    case /* VE */2 :
    case /* RR */4 :
    case /* RC */5 :
        exit = 1;
        break;
    case /* SO */0 :
    case /* CL */1 :
    case /* VD */3 :
    case /* PG */6 :
    case /* EP */7 :
    case /* ES */8 :
    case /* NOTSET */9 :
        tmp = undefined;
        break;
    
  }
  if (exit === 1) {
    tmp = App_Types_Result$DvmAdminFrontendFr.mapWithDefault(order, formOutput.parcStartDate, (function (x) {
            if (x.TAG === /* Fleet */0) {
              return ;
            }
            var nonFleetOrder = x._0;
            var originalParc = Belt_Option.mapWithDefault(nonFleetOrder.detail.parcStartDate, nonFleetOrder.detail.parcStartCalcDate, (function (x) {
                    return Caml_option.some(x);
                  }));
            var match = formOutput.parcStartDate;
            if (originalParc === undefined) {
              if (match !== undefined) {
                return Caml_option.some(Caml_option.valFromOption(match));
              } else {
                return ;
              }
            }
            if (match === undefined) {
              return ;
            }
            var outputParc = Caml_option.valFromOption(match);
            if (ReDate.isSameDay(Caml_option.valFromOption(originalParc), outputParc)) {
              return ;
            } else {
              return Caml_option.some(outputParc);
            }
          }));
  }
  var match$13 = formOutput.orderType;
  var tmp$1;
  switch (match$13) {
    case /* VE */2 :
    case /* RR */4 :
    case /* RC */5 :
        tmp$1 = formOutput.parcEndDate;
        break;
    case /* SO */0 :
    case /* CL */1 :
    case /* VD */3 :
    case /* PG */6 :
    case /* EP */7 :
    case /* ES */8 :
    case /* NOTSET */9 :
        tmp$1 = undefined;
        break;
    
  }
  Order_Detail_Correct_Api$DvmAdminFrontendFr.saveCorrection({
        orderID: orderId,
        orderType: formOutput.orderType,
        timeStamp: undefined,
        editor: undefined,
        siret: match !== 0 ? undefined : formOutput.siret,
        sirenFinalCustomerLLD: match$1 !== 0 ? undefined : formOutput.finalCustomer,
        companyDiscountPercentage: match$2 !== 0 ? undefined : formOutput.discount,
        acquisitionType: match$3 !== 0 ? undefined : formOutput.acquisitionType,
        budgetType: match$4 !== 0 ? undefined : formOutput.budget,
        newCarRegistrationID: formOutput.registrationNumber,
        newCarRegistrationDate: formOutput.registrationDate,
        customerInvoiceDate: formOutput.invoiceClient,
        usedCarPoliceNumber: formVehicleOutput.policeNumber,
        usedCarRegistrationID: formVehicleOutput.usedCarRegistrationID,
        usedCarLastRegistrationDate: formVehicleOutput.usedCarLastRegistrationDate,
        usedCarFirstReleaseDate: formVehicleOutput.usedCarFirstReleaseDate,
        brandUsedCar: formVehicleOutput.brand,
        modelUsedCar: formVehicleOutput.model,
        versionUsedCar: formVehicleOutput.version,
        vinUsedCar: formVehicleOutput.vin,
        realTradeAmount: match$5 !== 0 ? formVehicleOutput.valueUsedCar : undefined,
        estimatedTradeAmount: match$6 !== 0 ? formVehicleOutput.estimatedValueUsedCar : undefined,
        scrapyardIndicator: match$7 !== 0 ? formVehicleOutput.endOfLifeUsedCar : undefined,
        customerInvoiceNumber: formOutput.invoiceNumber,
        comment: formOutput.comment,
        bonusActionTypePERF: formPerfOutput.bonusActionTypePERF,
        bonusActionTypePERF2: formPerfOutput.bonusActionTypePERF2,
        bonusActionTypePERF3: formPerfOutput.bonusActionTypePERF3,
        planPerfAmount: Belt_Option.getWithDefault(formPerfOutput.planPerfAmount, 0.0),
        planPerfAmount2: Belt_Option.getWithDefault(formPerfOutput.planPerfAmount2, 0.0),
        planPerfAmount3: Belt_Option.getWithDefault(formPerfOutput.planPerfAmount3, 0.0),
        planPerfID: formPerfOutput.planPerfID,
        planPerfID2: formPerfOutput.planPerfID2,
        planPerfID3: formPerfOutput.planPerfID3,
        planPerfLabel1: undefined,
        planPerfLabel2: undefined,
        planPerfLabel3: undefined,
        customerDiscount: match$8 !== 0 ? formOutput.customerDiscount : undefined,
        easyPack: match$9 !== 0 ? formOutput.easyPack : undefined,
        shortCustomerName: match$10 !== 0 ? formOutput.shortCustomerName : undefined,
        customerFirstName: match$11 !== 0 ? formOutput.customerFirstName : undefined,
        parcStartDate: tmp,
        parcEndDate: tmp$1,
        orderDate: formOutput.orderDate,
        renaultInvoiceDate: formOutput.renaultInvoiceDate,
        carSaleAvailibilityDateMADA: formOutput.carSaleAvailibilityDateMADA,
        carSaleAvailibilityDateMADC: formOutput.carSaleAvailibilityDateMADC,
        modelID: formAdditionalOutput.modelID,
        versionID: formAdditionalOutput.versionID,
        phase: formAdditionalOutput.phase,
        salesmanName: formAdditionalOutput.salesmanName,
        agentCode: formAdditionalOutput.agentCode,
        newCarDeliveryDateToCustomer: undefined
      }, userManager, setResult, formSubmissionCallbacks, formPerfSubmissionCallbacks, formVehicleSubmissionCallbacks, formAdditionalSubmissionCallbacks, selectedRole, formOutput.orderType);
}

var Form;

var FormPerfs;

var FormVehicle;

var FormAdditional;

export {
  Form ,
  FormPerfs ,
  FormVehicle ,
  FormAdditional ,
  saveCorrection ,
}
/* Order_Detail_Correct_Api-DvmAdminFrontendFr Not a pure module */
