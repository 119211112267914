// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../common/components/icons/App_Icon.bs.js";
import * as App_Button$DvmAdminFrontendFr from "../../../../common/components/button/App_Button.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../common/components/App_LabeledText.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Status$DvmAdminFrontendFr from "../../../../common/types/App_Types_Status.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../context/user/User_Types_Utils.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_Styles_Status$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Status.bs.js";
import * as Order_Detail_Styles$DvmAdminFrontendFr from "../Order_Detail_Styles.bs.js";
import * as Messages_Orders_Bypass$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Bypass.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Messages_Orders_Derogate$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Derogate.bs.js";
import * as Order_Detail_Error_Comment$DvmAdminFrontendFr from "./Order_Detail_Error_Comment.bs.js";
import * as Order_Detail_Budget_Bypass_Modal$DvmAdminFrontendFr from "./bypass/Order_Detail_Budget_Bypass_Modal.bs.js";

function getAmountText(budget, archived, intl) {
  var match = budget.bonus;
  var match$1 = budget.state;
  if (match !== undefined && !(match$1 !== undefined && match$1 === /* BLOCKED */0 && Belt_Option.getWithDefault(archived, false) === true && match === 0.0)) {
    return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, match);
  } else {
    return "-";
  }
}

function getBudgetAmountMessage(budget, intl) {
  var match = budget.bonus;
  var match$1 = budget.state;
  if (match !== undefined && match$1 !== undefined) {
    return React.createElement(React.Fragment, undefined, match$1 === /* CALCULABLE */1 && match > 0.0 ? intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.calculableWithAmount) : null, (match$1 === /* CALCULABLE */1 || match$1 === /* NOBONUS */3) && match <= 0.0 ? intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.zeroBudgetText) : null);
  } else {
    return null;
  }
}

function getTableItem(label, value) {
  return React.createElement(Core.Grid, {
              children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                    label: label,
                    children: value
                  }),
              className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
              item: true
            });
}

function Order_Detail_Budget_Box(Props) {
  var color = Props.color;
  var openModal = Props.openModal;
  var order = Props.order;
  var orderId = Props.orderId;
  var reloadPage = Props.reloadPage;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match[0].selectedRole;
  var match$1 = React.useState(function () {
        return false;
      });
  var setModal = match$1[1];
  var modal = match$1[0];
  return React.createElement(React.Fragment, undefined, Belt_Option.mapWithDefault(order.budget, null, (function (budget) {
                    var state = budget.state;
                    var comment = budget.comment;
                    return React.createElement(React.Fragment, undefined, React.createElement(Core.Grid, {
                                    children: React.createElement(Core.Grid, {
                                          children: null,
                                          container: true,
                                          direction: "column",
                                          item: true
                                        }, React.createElement(Core.Grid, {
                                              children: null,
                                              container: true,
                                              item: true,
                                              wrap: "nowrap"
                                            }, React.createElement(Core.Grid, {
                                                  alignItems: "flex-start",
                                                  children: React.createElement(Core.Grid, {
                                                        alignItems: "center",
                                                        children: null,
                                                        container: true,
                                                        item: true,
                                                        spacing: 1
                                                      }, React.createElement(Core.Grid, {
                                                            children: React.createElement(Core.Box, {
                                                                  className: App_Styles_Status$DvmAdminFrontendFr.oval,
                                                                  bgcolor: App_Styles_Status$DvmAdminFrontendFr.stateColors(state !== undefined ? state : /* EMPTYSTATUS */10)
                                                                }),
                                                            item: true
                                                          }), React.createElement(Core.Grid, {
                                                            children: intl.formatMessage(App_Types_Status$DvmAdminFrontendFr.toMessage(Belt_Option.getWithDefault(budget.state, /* EMPTYSTATUS */10))),
                                                            item: true
                                                          })),
                                                  className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                                                  container: true,
                                                  item: true
                                                }), getTableItem(Messages_Common$DvmAdminFrontendFr.budget, Belt_Option.getWithDefault(order.detail.budget, "-")), getTableItem(Messages_Common$DvmAdminFrontendFr.year, Belt_Option.getWithDefault(budget.year, "-")), React.createElement(Core.Grid, {
                                                  children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                        label: Messages_Orders_Detail$DvmAdminFrontendFr.aquisitionType,
                                                        children: Belt_Option.getWithDefault(order.detail.acquisitionType, "-")
                                                      }),
                                                  className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemDoubleWide,
                                                  item: true
                                                }), getTableItem(Messages_Orders_Detail$DvmAdminFrontendFr.preinvoiceDate, Belt_Option.mapWithDefault(order.detail.preinvoiceDate, "-", (function (param) {
                                                        return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                      }))), getTableItem(Messages_Orders_Detail$DvmAdminFrontendFr.preinvoiceNumber, Belt_Option.getWithDefault(order.detail.preinvoiceNumber, "-")), getTableItem(Messages_Orders_Detail$DvmAdminFrontendFr.bapInvoiceDate, Belt_Option.mapWithDefault(order.detail.bapInvoiceDate, "-", (function (param) {
                                                        return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                      }))), getTableItem(Messages_Common$DvmAdminFrontendFr.discount, Belt_Option.mapWithDefault(budget.discount, "-", (function (param) {
                                                        return Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, param);
                                                      }))), React.createElement(Core.Grid, {
                                                  children: React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                                        label: Messages_Orders_Detail$DvmAdminFrontendFr.actionAmount,
                                                        children: React.createElement(Core.Grid, {
                                                              alignItems: "flex-start",
                                                              children: null,
                                                              container: true,
                                                              item: true,
                                                              wrap: "nowrap"
                                                            }, React.createElement(Core.Grid, {
                                                                  children: getAmountText(budget, order.archived, intl),
                                                                  item: true
                                                                }), Belt_Option.mapWithDefault(budget.state, false, (function (x) {
                                                                    if (x === /* PREINVOICED */5 || x === /* NOBONUS */3) {
                                                                      return true;
                                                                    } else {
                                                                      return x === /* PAID */4;
                                                                    }
                                                                  })) ? React.createElement(Core.Grid, {
                                                                    children: React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                          size: /* Large */3,
                                                                          type_: /* Tick */1,
                                                                          color: Theme_Colors$DvmAdminFrontendFr.accentGreen
                                                                        }),
                                                                    item: true
                                                                  }) : null)
                                                      }),
                                                  className: Order_Detail_Styles$DvmAdminFrontendFr.bonusesItemNew,
                                                  container: true,
                                                  item: true
                                                })), React.createElement(Core.Grid, {
                                              children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                    children: getBudgetAmountMessage(budget, intl),
                                                    variant: "caption"
                                                  }),
                                              item: true
                                            }), Belt_Option.mapWithDefault(budget.state, false, (function (x) {
                                                if (x === /* PREINVOICED */5 || x === /* NOBONUS */3) {
                                                  return true;
                                                } else {
                                                  return x === /* PAID */4;
                                                }
                                              })) && !Belt_Option.getWithDefault(order.archived, true) && User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) ? React.createElement(Core.Grid, {
                                                children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                      onClick: openModal,
                                                      label: intl.formatMessage(Messages_Orders_Derogate$DvmAdminFrontendFr.derogateButton),
                                                      color: "secondary",
                                                      size: "small"
                                                    }),
                                                item: true
                                              }) : null, Belt_Option.mapWithDefault(budget.bypassDetail, Belt_Option.getWithDefault(budget.state, /* EMPTYSTATUS */10) === /* BLOCKED */0 && !Belt_Option.getWithDefault(order.archived, true) && (User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) || selectedRole === /* Advisor */0) && Belt_Option.getWithDefault(order.detail.delivered, false) && Belt_Option.mapWithDefault(budget.comment, false, (function (comment) {
                                                    return Belt_Option.isSome(Belt_List.getBy(comment, (function (x) {
                                                                      if (x.errorId === /* INVALID_VERSION_GRID_DISCOUNT */42 || x.errorId === /* INVALID_MODEL_GRID_DISCOUNT */39 || x.errorId === /* INVALID_AVS_GRID_DISCOUNT */37 || x.errorId === /* INVALID_ACQUISITION_TYPE */36 || x.errorId === /* MISSING_VERSION_GRID */96) {
                                                                        return true;
                                                                      } else {
                                                                        return x.errorId === /* MISSING_MODEL_GRID */70;
                                                                      }
                                                                    })));
                                                  })) ? React.createElement(Core.Grid, {
                                                    children: React.createElement(App_Button$DvmAdminFrontendFr.make, {
                                                          onClick: (function (param) {
                                                              Curry._1(setModal, (function (param) {
                                                                      return true;
                                                                    }));
                                                            }),
                                                          label: intl.formatMessage(Messages_Orders_Bypass$DvmAdminFrontendFr.bypassButton),
                                                          color: "secondary",
                                                          size: "small"
                                                        }),
                                                    item: true
                                                  }) : null, (function (bypassDetail) {
                                                return React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                                            style: {
                                                              fontWeight: "600"
                                                            },
                                                            children: React.createElement(Core.Grid, {
                                                                  children: null,
                                                                  container: true,
                                                                  spacing: 1
                                                                }, React.createElement(Core.Grid, {
                                                                      children: intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.validation) + Belt_Option.mapWithDefault(bypassDetail.date, "", (function (param) {
                                                                              return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                            })) + (
                                                                        selectedRole !== /* Dealer */7 || !User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole) ? " - " + Belt_Option.getWithDefault(bypassDetail.approver, "-") : ""
                                                                      ),
                                                                      item: true
                                                                    }), React.createElement(Core.Grid, {
                                                                      children: React.createElement(Core.Tooltip, {
                                                                            arrow: true,
                                                                            children: React.createElement("div", undefined, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                                                      size: /* Large */3,
                                                                                      type_: /* Information */43
                                                                                    })),
                                                                            classes: {
                                                                              tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                                                                            },
                                                                            placement: "bottom",
                                                                            title: Belt_Option.getWithDefault(bypassDetail.comment, "-")
                                                                          }),
                                                                      item: true
                                                                    })),
                                                            variant: "body"
                                                          });
                                              })), comment !== undefined ? Belt_Array.map(Belt_List.toArray(comment), (function (comment) {
                                                  return React.createElement(Order_Detail_Error_Comment$DvmAdminFrontendFr.make, {
                                                              comment: comment,
                                                              actionDetail: budget,
                                                              order: order
                                                            });
                                                })) : null),
                                    className: Order_Detail_Styles$DvmAdminFrontendFr.helpersBoxNew,
                                    container: true,
                                    item: true,
                                    spacing: 1,
                                    style: {
                                      borderLeft: "4px solid " + color + ""
                                    }
                                  }), modal ? React.createElement(Order_Detail_Budget_Bypass_Modal$DvmAdminFrontendFr.make, {
                                      order: order,
                                      orderId: orderId,
                                      action: {
                                        actionType: "budget",
                                        actionId: Belt_Option.getWithDefault(Belt_Option.flatMap(order.budget, (function (info) {
                                                    return info.stringActionID;
                                                  })), ""),
                                        actionIdLabel: "",
                                        actionBudget: order.detail.budget,
                                        bonus: Belt_Option.flatMap(order.budget, (function (budget) {
                                                return budget.bonus;
                                              })),
                                        amountPercent: undefined,
                                        amountEuro: undefined,
                                        bonusValue: undefined,
                                        calcMethod: undefined
                                      },
                                      onClose: (function (param) {
                                          Curry._1(setModal, (function (param) {
                                                  return false;
                                                }));
                                        }),
                                      onOk: (function (param) {
                                          Curry._1(setModal, (function (param) {
                                                  return false;
                                                }));
                                          Curry._1(reloadPage, undefined);
                                        }),
                                      discount: budget.discount,
                                      gridDiscount: budget.gridDiscount
                                    }) : null);
                  })));
}

var StatusTypes;

var Styles;

var make = Order_Detail_Budget_Box;

export {
  StatusTypes ,
  Styles ,
  getAmountText ,
  getBudgetAmountMessage ,
  getTableItem ,
  make ,
}
/* react Not a pure module */
