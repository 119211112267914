// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Order_Search_Types$DvmAdminFrontendFr from "./Order_Search_Types.bs.js";
import * as App_Types_VN_VO_Status$DvmAdminFrontendFr from "../../../common/types/App_Types_VN_VO_Status.bs.js";

var validators_saleTypes = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Order_Search_Types$DvmAdminFrontendFr.Fields.saleTypes, "normal", param.saleTypes)
            };
    })
};

var validators_clientTypes = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Order_Search_Types$DvmAdminFrontendFr.Fields.clientTypes, "normal", param.clientTypes)
            };
    })
};

var validators_usedCarStatus = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Order_Search_Types$DvmAdminFrontendFr.Fields.usedCarStatus, "normal", param.usedCarStatus)
            };
    })
};

var validators_orderStatus = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var isDealer = param$1.isDealer;
      var orderStatus = param.orderStatus;
      var tmp;
      if (Belt_List.length(orderStatus) > 0) {
        tmp = Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Order_Search_Types$DvmAdminFrontendFr.Fields.orderStatus, "normal", orderStatus);
      } else {
        var match = Order_Search_Types$DvmAdminFrontendFr.Fields.orderStatus;
        var fr = match[1];
        var en = match[0];
        tmp = {
          en: en + "<>" + App_Types_VN_VO_Status$DvmAdminFrontendFr.toString(/* CANCELLED */2) + (
            isDealer ? "" : "%26%26" + en + "<>" + App_Types_VN_VO_Status$DvmAdminFrontendFr.toString(/* REPARCELED */4)
          ),
          fr: fr + "<>" + App_Types_VN_VO_Status$DvmAdminFrontendFr.toString(/* CANCELLED */2) + (
            isDealer ? "" : "%26%26" + fr + "<>" + App_Types_VN_VO_Status$DvmAdminFrontendFr.toString(/* REPARCELED */4)
          )
        };
      }
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_orderType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Order_Search_Types$DvmAdminFrontendFr.Fields.orderType, "normal", param.orderType)
            };
    })
};

var validators_showArchived = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.boolFieldToQueryParamBilingual(Order_Search_Types$DvmAdminFrontendFr.Fields.archived, param.showArchived)
            };
    })
};

var validators_showHistoryDealer = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (param.showHistoryDealer) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var match = Order_Search_Types$DvmAdminFrontendFr.Fields.showHistoryDealer;
      return {
              TAG: /* Ok */0,
              _0: {
                en: match[0] + "=oui",
                fr: match[1] + "=oui"
              }
            };
    })
};

var validators_budgetStatus = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var orderType = param.orderType;
      var result = Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Order_Search_Types$DvmAdminFrontendFr.Fields.budgetStatus, "normal", param.budgetStatus);
      return {
              TAG: /* Ok */0,
              _0: Belt_List.length(orderType) > 0 && Belt_Option.isSome(Belt_List.getBy(orderType, (function (x) {
                          switch (x) {
                            case "CL" :
                            case "EP" :
                            case "ES" :
                            case "PG" :
                            case "RC" :
                            case "RR" :
                            case "VD" :
                            case "VE" :
                                return true;
                            default:
                              return false;
                          }
                        }))) ? undefined : result
            };
    })
};

var validators_budgetType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var orderType = param.orderType;
      var result = Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.budgetType, param.budgetType, false);
      return {
              TAG: /* Ok */0,
              _0: Belt_List.length(orderType) > 0 && Belt_Option.isSome(Belt_List.getBy(orderType, (function (x) {
                          switch (x) {
                            case "CL" :
                            case "EP" :
                            case "ES" :
                            case "PG" :
                            case "RC" :
                            case "RR" :
                            case "VD" :
                            case "VE" :
                                return true;
                            default:
                              return false;
                          }
                        }))) ? undefined : result
            };
    })
};

var validators_newCarRegistrationID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.newCarRegistrationID, param.newCarRegistrationID, false)
            };
    })
};

var validators_vin = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.vin, param.vin, false)
            };
    })
};

var validators_orderNumberCar = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.orderNumberCar, param.orderNumberCar, false)
            };
    })
};

var validators_customerOrderNumber = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Order_Search_Types$DvmAdminFrontendFr.Fields.customerOrderNumber, param.customerOrderNumber, false)
            };
    })
};

var validators_dealerId = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Order_Search_Types$DvmAdminFrontendFr.Fields.dealerID, "normal", param.dealerId)
            };
    })
};

var validators_dr = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Order_Search_Types$DvmAdminFrontendFr.Fields.dr, "normal", param.dr)
            };
    })
};

var validators = {
  saleTypes: validators_saleTypes,
  clientTypes: validators_clientTypes,
  usedCarStatus: validators_usedCarStatus,
  orderStatus: validators_orderStatus,
  orderType: validators_orderType,
  showArchived: validators_showArchived,
  showHistoryDealer: validators_showHistoryDealer,
  budgetStatus: validators_budgetStatus,
  budgetType: validators_budgetType,
  newCarRegistrationID: validators_newCarRegistrationID,
  vin: validators_vin,
  orderNumberCar: validators_orderNumberCar,
  customerOrderNumber: validators_customerOrderNumber,
  dealerId: validators_dealerId,
  dr: validators_dr
};

function initialFieldsStatuses(_input) {
  return {
          saleTypes: /* Pristine */0,
          clientTypes: /* Pristine */0,
          usedCarStatus: /* Pristine */0,
          orderStatus: /* Pristine */0,
          orderType: /* Pristine */0,
          showArchived: /* Pristine */0,
          showHistoryDealer: /* Pristine */0,
          budgetStatus: /* Pristine */0,
          budgetType: /* Pristine */0,
          newCarRegistrationID: /* Pristine */0,
          vin: /* Pristine */0,
          orderNumberCar: /* Pristine */0,
          customerOrderNumber: /* Pristine */0,
          dealerId: /* Pristine */0,
          dr: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.saleTypes;
  var match_0 = match ? match._0 : Curry._2(validators.saleTypes.validate, input, metadata);
  var match$1 = fieldsStatuses.clientTypes;
  var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.clientTypes.validate, input, metadata);
  var match$2 = fieldsStatuses.usedCarStatus;
  var match_0$2 = match$2 ? match$2._0 : Curry._2(validators.usedCarStatus.validate, input, metadata);
  var match$3 = fieldsStatuses.orderStatus;
  var match_0$3 = match$3 ? match$3._0 : Curry._2(validators.orderStatus.validate, input, metadata);
  var match$4 = fieldsStatuses.orderType;
  var match_0$4 = match$4 ? match$4._0 : Curry._2(validators.orderType.validate, input, metadata);
  var match$5 = fieldsStatuses.showArchived;
  var match_0$5 = match$5 ? match$5._0 : Curry._2(validators.showArchived.validate, input, metadata);
  var match$6 = fieldsStatuses.showHistoryDealer;
  var match_0$6 = match$6 ? match$6._0 : Curry._2(validators.showHistoryDealer.validate, input, metadata);
  var match$7 = fieldsStatuses.budgetStatus;
  var match_0$7 = match$7 ? match$7._0 : Curry._2(validators.budgetStatus.validate, input, metadata);
  var match$8 = fieldsStatuses.budgetType;
  var match_0$8 = match$8 ? match$8._0 : Curry._2(validators.budgetType.validate, input, metadata);
  var match$9 = fieldsStatuses.newCarRegistrationID;
  var match_0$9 = match$9 ? match$9._0 : Curry._2(validators.newCarRegistrationID.validate, input, metadata);
  var match$10 = fieldsStatuses.vin;
  var match_0$10 = match$10 ? match$10._0 : Curry._2(validators.vin.validate, input, metadata);
  var match$11 = fieldsStatuses.orderNumberCar;
  var match_0$11 = match$11 ? match$11._0 : Curry._2(validators.orderNumberCar.validate, input, metadata);
  var match$12 = fieldsStatuses.customerOrderNumber;
  var match_0$12 = match$12 ? match$12._0 : Curry._2(validators.customerOrderNumber.validate, input, metadata);
  var match$13 = fieldsStatuses.dealerId;
  var match_0$13 = match$13 ? match$13._0 : Curry._2(validators.dealerId.validate, input, metadata);
  var match$14 = fieldsStatuses.dr;
  var match_0$14 = match$14 ? match$14._0 : Curry._2(validators.dr.validate, input, metadata);
  var saleTypesResult = match_0;
  var saleTypesResult$1;
  if (saleTypesResult.TAG === /* Ok */0) {
    var clientTypesResult = match_0$1;
    if (clientTypesResult.TAG === /* Ok */0) {
      var usedCarStatusResult = match_0$2;
      if (usedCarStatusResult.TAG === /* Ok */0) {
        var orderStatusResult = match_0$3;
        if (orderStatusResult.TAG === /* Ok */0) {
          var orderTypeResult = match_0$4;
          if (orderTypeResult.TAG === /* Ok */0) {
            var showArchivedResult = match_0$5;
            if (showArchivedResult.TAG === /* Ok */0) {
              var showHistoryDealerResult = match_0$6;
              if (showHistoryDealerResult.TAG === /* Ok */0) {
                var budgetStatusResult = match_0$7;
                if (budgetStatusResult.TAG === /* Ok */0) {
                  var budgetTypeResult = match_0$8;
                  if (budgetTypeResult.TAG === /* Ok */0) {
                    var newCarRegistrationIDResult = match_0$9;
                    if (newCarRegistrationIDResult.TAG === /* Ok */0) {
                      var vinResult = match_0$10;
                      if (vinResult.TAG === /* Ok */0) {
                        var orderNumberCarResult = match_0$11;
                        if (orderNumberCarResult.TAG === /* Ok */0) {
                          var customerOrderNumberResult = match_0$12;
                          if (customerOrderNumberResult.TAG === /* Ok */0) {
                            var dealerIdResult = match_0$13;
                            if (dealerIdResult.TAG === /* Ok */0) {
                              var drResult = match_0$14;
                              if (drResult.TAG === /* Ok */0) {
                                return {
                                        TAG: /* Valid */0,
                                        output: {
                                          dr: drResult._0,
                                          dealerId: dealerIdResult._0,
                                          customerOrderNumber: customerOrderNumberResult._0,
                                          orderNumberCar: orderNumberCarResult._0,
                                          vin: vinResult._0,
                                          newCarRegistrationID: newCarRegistrationIDResult._0,
                                          budgetType: budgetTypeResult._0,
                                          budgetStatus: budgetStatusResult._0,
                                          showHistoryDealer: showHistoryDealerResult._0,
                                          showArchived: showArchivedResult._0,
                                          orderType: orderTypeResult._0,
                                          orderStatus: orderStatusResult._0,
                                          usedCarStatus: usedCarStatusResult._0,
                                          clientTypes: clientTypesResult._0,
                                          saleTypes: saleTypesResult._0
                                        },
                                        fieldsStatuses: {
                                          saleTypes: /* Dirty */{
                                            _0: saleTypesResult,
                                            _1: /* Shown */0
                                          },
                                          clientTypes: /* Dirty */{
                                            _0: clientTypesResult,
                                            _1: /* Shown */0
                                          },
                                          usedCarStatus: /* Dirty */{
                                            _0: usedCarStatusResult,
                                            _1: /* Shown */0
                                          },
                                          orderStatus: /* Dirty */{
                                            _0: orderStatusResult,
                                            _1: /* Shown */0
                                          },
                                          orderType: /* Dirty */{
                                            _0: orderTypeResult,
                                            _1: /* Shown */0
                                          },
                                          showArchived: /* Dirty */{
                                            _0: showArchivedResult,
                                            _1: /* Shown */0
                                          },
                                          showHistoryDealer: /* Dirty */{
                                            _0: showHistoryDealerResult,
                                            _1: /* Shown */0
                                          },
                                          budgetStatus: /* Dirty */{
                                            _0: budgetStatusResult,
                                            _1: /* Shown */0
                                          },
                                          budgetType: /* Dirty */{
                                            _0: budgetTypeResult,
                                            _1: /* Shown */0
                                          },
                                          newCarRegistrationID: /* Dirty */{
                                            _0: newCarRegistrationIDResult,
                                            _1: /* Shown */0
                                          },
                                          vin: /* Dirty */{
                                            _0: vinResult,
                                            _1: /* Shown */0
                                          },
                                          orderNumberCar: /* Dirty */{
                                            _0: orderNumberCarResult,
                                            _1: /* Shown */0
                                          },
                                          customerOrderNumber: /* Dirty */{
                                            _0: customerOrderNumberResult,
                                            _1: /* Shown */0
                                          },
                                          dealerId: /* Dirty */{
                                            _0: dealerIdResult,
                                            _1: /* Shown */0
                                          },
                                          dr: /* Dirty */{
                                            _0: drResult,
                                            _1: /* Shown */0
                                          }
                                        },
                                        collectionsStatuses: undefined
                                      };
                              }
                              saleTypesResult$1 = saleTypesResult;
                            } else {
                              saleTypesResult$1 = saleTypesResult;
                            }
                          } else {
                            saleTypesResult$1 = saleTypesResult;
                          }
                        } else {
                          saleTypesResult$1 = saleTypesResult;
                        }
                      } else {
                        saleTypesResult$1 = saleTypesResult;
                      }
                    } else {
                      saleTypesResult$1 = saleTypesResult;
                    }
                  } else {
                    saleTypesResult$1 = saleTypesResult;
                  }
                } else {
                  saleTypesResult$1 = saleTypesResult;
                }
              } else {
                saleTypesResult$1 = saleTypesResult;
              }
            } else {
              saleTypesResult$1 = saleTypesResult;
            }
          } else {
            saleTypesResult$1 = saleTypesResult;
          }
        } else {
          saleTypesResult$1 = saleTypesResult;
        }
      } else {
        saleTypesResult$1 = saleTypesResult;
      }
    } else {
      saleTypesResult$1 = saleTypesResult;
    }
  } else {
    saleTypesResult$1 = saleTypesResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            saleTypes: /* Dirty */{
              _0: saleTypesResult$1,
              _1: /* Shown */0
            },
            clientTypes: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            usedCarStatus: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            orderStatus: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            orderType: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            showArchived: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            showHistoryDealer: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            budgetStatus: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            budgetType: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            },
            newCarRegistrationID: /* Dirty */{
              _0: match_0$9,
              _1: /* Shown */0
            },
            vin: /* Dirty */{
              _0: match_0$10,
              _1: /* Shown */0
            },
            orderNumberCar: /* Dirty */{
              _0: match_0$11,
              _1: /* Shown */0
            },
            customerOrderNumber: /* Dirty */{
              _0: match_0$12,
              _1: /* Shown */0
            },
            dealerId: /* Dirty */{
              _0: match_0$13,
              _1: /* Shown */0
            },
            dr: /* Dirty */{
              _0: match_0$14,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurSaleTypesField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.saleTypes, validators_saleTypes, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: status,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurClientTypesField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.clientTypes, validators_clientTypes, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: status,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurUsedCarStatusField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.usedCarStatus, validators_usedCarStatus, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: status,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderStatusField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.orderStatus, validators_orderStatus, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: status,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderTypeField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.orderType, validators_orderType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: status,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurShowArchivedField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.showArchived, validators_showArchived, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: status,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurShowHistoryDealerField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.showHistoryDealer, validators_showHistoryDealer, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: status,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetStatusField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.budgetStatus, validators_budgetStatus, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: status,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetTypeField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.budgetType, validators_budgetType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: status,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurNewCarRegistrationIDField */9 :
                  var result$9 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.newCarRegistrationID, validators_newCarRegistrationID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: status,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$9 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$9,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurVinField */10 :
                  var result$10 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.vin, validators_vin, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: status,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$10 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$10,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderNumberCarField */11 :
                  var result$11 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.orderNumberCar, validators_orderNumberCar, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: status,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$11 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$11,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurCustomerOrderNumberField */12 :
                  var result$12 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.customerOrderNumber, validators_customerOrderNumber, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: status,
                                  dealerId: init.dealerId,
                                  dr: init.dr
                                };
                        }));
                  if (result$12 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$12,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDealerIdField */13 :
                  var result$13 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.dealerId, validators_dealerId, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: status,
                                  dr: init.dr
                                };
                        }));
                  if (result$13 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$13,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDrField */14 :
                  var result$14 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.dr, validators_dr, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: status
                                };
                        }));
                  if (result$14 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$14,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */15 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */15,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */16,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */18);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */17);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */16 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */17 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */18 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateSaleTypesField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, state.fieldsStatuses.saleTypes, state.submissionStatus, validators_saleTypes, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: status,
                                            clientTypes: init.clientTypes,
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateClientTypesField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, state.fieldsStatuses.clientTypes, state.submissionStatus, validators_clientTypes, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: status,
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateUsedCarStatusField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, state.fieldsStatuses.usedCarStatus, state.submissionStatus, validators_usedCarStatus, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            usedCarStatus: status,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderStatusField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$3, metadata, state.fieldsStatuses.orderStatus, state.submissionStatus, validators_orderStatus, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: status,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderTypeField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, state.fieldsStatuses.orderType, state.submissionStatus, validators_orderType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: status,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateShowArchivedField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$5, metadata, state.fieldsStatuses.showArchived, state.submissionStatus, validators_showArchived, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: status,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateShowHistoryDealerField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$6, metadata, state.fieldsStatuses.showHistoryDealer, state.submissionStatus, validators_showHistoryDealer, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: status,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetStatusField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$7, metadata, state.fieldsStatuses.budgetStatus, state.submissionStatus, validators_budgetStatus, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: status,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetTypeField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$8, metadata, state.fieldsStatuses.budgetType, state.submissionStatus, validators_budgetType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: status,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateNewCarRegistrationIDField */9 :
                  var nextInput$9 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$9,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$9, metadata, state.fieldsStatuses.newCarRegistrationID, state.submissionStatus, validators_newCarRegistrationID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: status,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateVinField */10 :
                  var nextInput$10 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$10,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$10, metadata, state.fieldsStatuses.vin, state.submissionStatus, validators_vin, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: status,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderNumberCarField */11 :
                  var nextInput$11 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$11,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$11, metadata, state.fieldsStatuses.orderNumberCar, state.submissionStatus, validators_orderNumberCar, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: status,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateCustomerOrderNumberField */12 :
                  var nextInput$12 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$12,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$12, metadata, state.fieldsStatuses.customerOrderNumber, state.submissionStatus, validators_customerOrderNumber, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: status,
                                            dealerId: init.dealerId,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDealerIdField */13 :
                  var nextInput$13 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$15 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$13, metadata, nextFieldsStatuses.contents.dr, validators_dr, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: init.dealerId,
                                  dr: status
                                };
                        }));
                  if (result$15 !== undefined) {
                    nextFieldsStatuses.contents = result$15;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$13,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$13, metadata, nextFieldsStatuses.contents.dealerId, state.submissionStatus, validators_dealerId, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: status,
                                            dr: init.dr
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDrField */14 :
                  var nextInput$14 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$16 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$14, metadata, nextFieldsStatuses$1.contents.dealerId, validators_dealerId, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  usedCarStatus: init.usedCarStatus,
                                  orderStatus: init.orderStatus,
                                  orderType: init.orderType,
                                  showArchived: init.showArchived,
                                  showHistoryDealer: init.showHistoryDealer,
                                  budgetStatus: init.budgetStatus,
                                  budgetType: init.budgetType,
                                  newCarRegistrationID: init.newCarRegistrationID,
                                  vin: init.vin,
                                  orderNumberCar: init.orderNumberCar,
                                  customerOrderNumber: init.customerOrderNumber,
                                  dealerId: status,
                                  dr: init.dr
                                };
                        }));
                  if (result$16 !== undefined) {
                    nextFieldsStatuses$1.contents = result$16;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$14,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$14, metadata, nextFieldsStatuses$1.contents.dr, state.submissionStatus, validators_dr, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            usedCarStatus: init.usedCarStatus,
                                            orderStatus: init.orderStatus,
                                            orderType: init.orderType,
                                            showArchived: init.showArchived,
                                            showHistoryDealer: init.showHistoryDealer,
                                            budgetStatus: init.budgetStatus,
                                            budgetType: init.budgetType,
                                            newCarRegistrationID: init.newCarRegistrationID,
                                            vin: init.vin,
                                            orderNumberCar: init.orderNumberCar,
                                            customerOrderNumber: init.customerOrderNumber,
                                            dealerId: init.dealerId,
                                            dr: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */15 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */16 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */17 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateSaleTypes: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSaleTypesField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateClientTypes: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateClientTypesField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateUsedCarStatus: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateUsedCarStatusField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderStatus: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderStatusField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderTypeField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateShowArchived: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateShowArchivedField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateShowHistoryDealer: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateShowHistoryDealerField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudgetStatus: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetStatusField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudgetType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetTypeField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateNewCarRegistrationID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateNewCarRegistrationIDField */9,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateVin: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateVinField */10,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderNumberCar: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderNumberCarField */11,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateCustomerOrderNumber: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateCustomerOrderNumberField */12,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDealerId: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDealerIdField */13,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDr: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDrField */14,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurSaleTypes: (function (param) {
              Curry._1(dispatch, /* BlurSaleTypesField */0);
            }),
          blurClientTypes: (function (param) {
              Curry._1(dispatch, /* BlurClientTypesField */1);
            }),
          blurUsedCarStatus: (function (param) {
              Curry._1(dispatch, /* BlurUsedCarStatusField */2);
            }),
          blurOrderStatus: (function (param) {
              Curry._1(dispatch, /* BlurOrderStatusField */3);
            }),
          blurOrderType: (function (param) {
              Curry._1(dispatch, /* BlurOrderTypeField */4);
            }),
          blurShowArchived: (function (param) {
              Curry._1(dispatch, /* BlurShowArchivedField */5);
            }),
          blurShowHistoryDealer: (function (param) {
              Curry._1(dispatch, /* BlurShowHistoryDealerField */6);
            }),
          blurBudgetStatus: (function (param) {
              Curry._1(dispatch, /* BlurBudgetStatusField */7);
            }),
          blurBudgetType: (function (param) {
              Curry._1(dispatch, /* BlurBudgetTypeField */8);
            }),
          blurNewCarRegistrationID: (function (param) {
              Curry._1(dispatch, /* BlurNewCarRegistrationIDField */9);
            }),
          blurVin: (function (param) {
              Curry._1(dispatch, /* BlurVinField */10);
            }),
          blurOrderNumberCar: (function (param) {
              Curry._1(dispatch, /* BlurOrderNumberCarField */11);
            }),
          blurCustomerOrderNumber: (function (param) {
              Curry._1(dispatch, /* BlurCustomerOrderNumberField */12);
            }),
          blurDealerId: (function (param) {
              Curry._1(dispatch, /* BlurDealerIdField */13);
            }),
          blurDr: (function (param) {
              Curry._1(dispatch, /* BlurDrField */14);
            }),
          saleTypesResult: Formality.exposeFieldResult(state.fieldsStatuses.saleTypes),
          clientTypesResult: Formality.exposeFieldResult(state.fieldsStatuses.clientTypes),
          usedCarStatusResult: Formality.exposeFieldResult(state.fieldsStatuses.usedCarStatus),
          orderStatusResult: Formality.exposeFieldResult(state.fieldsStatuses.orderStatus),
          orderTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.orderType),
          showArchivedResult: Formality.exposeFieldResult(state.fieldsStatuses.showArchived),
          showHistoryDealerResult: Formality.exposeFieldResult(state.fieldsStatuses.showHistoryDealer),
          budgetStatusResult: Formality.exposeFieldResult(state.fieldsStatuses.budgetStatus),
          budgetTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.budgetType),
          newCarRegistrationIDResult: Formality.exposeFieldResult(state.fieldsStatuses.newCarRegistrationID),
          vinResult: Formality.exposeFieldResult(state.fieldsStatuses.vin),
          orderNumberCarResult: Formality.exposeFieldResult(state.fieldsStatuses.orderNumberCar),
          customerOrderNumberResult: Formality.exposeFieldResult(state.fieldsStatuses.customerOrderNumber),
          dealerIdResult: Formality.exposeFieldResult(state.fieldsStatuses.dealerId),
          drResult: Formality.exposeFieldResult(state.fieldsStatuses.dr),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              var exit = 0;
              if (match.saleTypes || match.clientTypes || match.usedCarStatus || match.orderStatus || match.orderType || match.showArchived || match.showHistoryDealer || match.budgetStatus || match.budgetType || match.newCarRegistrationID || match.vin || match.orderNumberCar || match.customerOrderNumber || match.dealerId) {
                exit = 1;
              } else {
                if (!match.dr) {
                  return false;
                }
                exit = 1;
              }
              if (exit === 1) {
                var exit$1 = 0;
                exit$1 = 2;
                if (exit$1 === 2) {
                  var exit$2 = 0;
                  exit$2 = 3;
                  if (exit$2 === 3) {
                    var exit$3 = 0;
                    exit$3 = 4;
                    if (exit$3 === 4) {
                      return true;
                    }
                    
                  }
                  
                }
                
              }
              
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */15);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */16);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */17);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */17,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */18);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  dr: /* [] */0,
  dealerId: /* [] */0,
  customerOrderNumber: "",
  orderNumberCar: "",
  vin: "",
  newCarRegistrationID: "",
  budgetType: "",
  budgetStatus: /* [] */0,
  showHistoryDealer: false,
  showArchived: false,
  orderType: /* [] */0,
  orderStatus: /* [] */0,
  usedCarStatus: /* [] */0,
  clientTypes: /* [] */0,
  saleTypes: /* [] */0
};

export {
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
