// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";
import * as App_Types_Status$DvmAdminFrontendFr from "../../../common/types/App_Types_Status.bs.js";
import * as Order_Detail_Types_Comment$DvmAdminFrontendFr from "./Order_Detail_Types_Comment.bs.js";

function commentItem_encode(v) {
  return Js_dict.fromArray([
              [
                "errorId",
                Curry._1(Order_Detail_Types_Comment$DvmAdminFrontendFr.Decco.t_encode, v.errorId)
              ],
              [
                "errorDescription",
                Decco.optionToJson(Decco.stringToJson, v.errorDescription)
              ],
              [
                "errorDescriptionLocal",
                Decco.optionToJson(Decco.stringToJson, v.errorDescriptionLocal)
              ],
              [
                "visibility",
                Decco.optionToJson(Decco.boolToJson, v.visibility)
              ]
            ]);
}

function commentItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var errorId = Curry._1(Order_Detail_Types_Comment$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "errorId"), null));
  if (errorId.TAG === /* Ok */0) {
    var errorDescription = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "errorDescription"), null));
    if (errorDescription.TAG === /* Ok */0) {
      var errorDescriptionLocal = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "errorDescriptionLocal"), null));
      if (errorDescriptionLocal.TAG === /* Ok */0) {
        var visibility = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "visibility"), null));
        if (visibility.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    errorId: errorId._0,
                    errorDescription: errorDescription._0,
                    errorDescriptionLocal: errorDescriptionLocal._0,
                    visibility: visibility._0
                  }
                };
        }
        var e = visibility._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".visibility" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = errorDescriptionLocal._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".errorDescriptionLocal" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = errorDescription._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".errorDescription" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = errorId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".errorId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function derogate_encode(v) {
  return Js_dict.fromArray([
              [
                "actionId",
                Decco.optionToJson(Decco.stringToJson, v.actionId)
              ],
              [
                "actionType",
                Decco.optionToJson(Decco.stringToJson, v.actionType)
              ],
              [
                "derogateId",
                Decco.stringToJson(v.derogateId)
              ],
              [
                "derogateLabel",
                Decco.optionToJson(Decco.stringToJson, v.derogateLabel)
              ],
              [
                "amount",
                Decco.optionToJson(Decco.floatToJson, v.amount)
              ],
              [
                "amountOriginal",
                Decco.optionToJson(Decco.floatToJson, v.amountOriginal)
              ],
              [
                "amountTtc",
                Decco.optionToJson(Decco.floatToJson, v.amountTtc)
              ],
              [
                "amountPaid",
                Decco.optionToJson(Decco.floatToJson, v.amountPaid)
              ],
              [
                "comment",
                Decco.optionToJson(Decco.stringToJson, v.comment)
              ],
              [
                "negative",
                Decco.boolToJson(v.negative)
              ],
              [
                "validation",
                Decco.optionToJson(Decco.stringToJson, v.validation)
              ],
              [
                "state",
                Decco.optionToJson(App_Types_Status$DvmAdminFrontendFr.Decco.t_encode, v.state)
              ],
              [
                "date",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.date)
              ],
              [
                "editor",
                Decco.optionToJson(Decco.stringToJson, v.editor)
              ],
              [
                "preinvoiceDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.preinvoiceDate)
              ],
              [
                "preinvoiceNumber",
                Decco.optionToJson(Decco.stringToJson, v.preinvoiceNumber)
              ],
              [
                "invoiceDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.invoiceDate)
              ],
              [
                "errors",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(commentItem_encode, param);
                      }), v.errors)
              ]
            ]);
}

function derogate_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var actionId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionId"), null));
  if (actionId.TAG === /* Ok */0) {
    var actionType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionType"), null));
    if (actionType.TAG === /* Ok */0) {
      var derogateId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "derogateId"), null));
      if (derogateId.TAG === /* Ok */0) {
        var derogateLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "derogateLabel"), null));
        if (derogateLabel.TAG === /* Ok */0) {
          var amount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "amount"), null));
          if (amount.TAG === /* Ok */0) {
            var amountOriginal = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "amountOriginal"), null));
            if (amountOriginal.TAG === /* Ok */0) {
              var amountTtc = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "amountTtc"), null));
              if (amountTtc.TAG === /* Ok */0) {
                var amountPaid = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "amountPaid"), null));
                if (amountPaid.TAG === /* Ok */0) {
                  var comment = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "comment"), null));
                  if (comment.TAG === /* Ok */0) {
                    var negative = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "negative"), null));
                    if (negative.TAG === /* Ok */0) {
                      var validation = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "validation"), null));
                      if (validation.TAG === /* Ok */0) {
                        var state = Decco.optionFromJson(App_Types_Status$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "state"), null));
                        if (state.TAG === /* Ok */0) {
                          var date = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "date"), null));
                          if (date.TAG === /* Ok */0) {
                            var editor = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "editor"), null));
                            if (editor.TAG === /* Ok */0) {
                              var preinvoiceDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "preinvoiceDate"), null));
                              if (preinvoiceDate.TAG === /* Ok */0) {
                                var preinvoiceNumber = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "preinvoiceNumber"), null));
                                if (preinvoiceNumber.TAG === /* Ok */0) {
                                  var invoiceDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "invoiceDate"), null));
                                  if (invoiceDate.TAG === /* Ok */0) {
                                    var errors = Decco.optionFromJson((function (param) {
                                            return Decco.listFromJson(commentItem_decode, param);
                                          }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "errors"), null));
                                    if (errors.TAG === /* Ok */0) {
                                      return {
                                              TAG: /* Ok */0,
                                              _0: {
                                                actionId: actionId._0,
                                                actionType: actionType._0,
                                                derogateId: derogateId._0,
                                                derogateLabel: derogateLabel._0,
                                                amount: amount._0,
                                                amountOriginal: amountOriginal._0,
                                                amountTtc: amountTtc._0,
                                                amountPaid: amountPaid._0,
                                                comment: comment._0,
                                                negative: negative._0,
                                                validation: validation._0,
                                                state: state._0,
                                                date: date._0,
                                                editor: editor._0,
                                                preinvoiceDate: preinvoiceDate._0,
                                                preinvoiceNumber: preinvoiceNumber._0,
                                                invoiceDate: invoiceDate._0,
                                                errors: errors._0
                                              }
                                            };
                                    }
                                    var e = errors._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".errors" + e.path,
                                              message: e.message,
                                              value: e.value
                                            }
                                          };
                                  }
                                  var e$1 = invoiceDate._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".invoiceDate" + e$1.path,
                                            message: e$1.message,
                                            value: e$1.value
                                          }
                                        };
                                }
                                var e$2 = preinvoiceNumber._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".preinvoiceNumber" + e$2.path,
                                          message: e$2.message,
                                          value: e$2.value
                                        }
                                      };
                              }
                              var e$3 = preinvoiceDate._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".preinvoiceDate" + e$3.path,
                                        message: e$3.message,
                                        value: e$3.value
                                      }
                                    };
                            }
                            var e$4 = editor._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".editor" + e$4.path,
                                      message: e$4.message,
                                      value: e$4.value
                                    }
                                  };
                          }
                          var e$5 = date._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".date" + e$5.path,
                                    message: e$5.message,
                                    value: e$5.value
                                  }
                                };
                        }
                        var e$6 = state._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".state" + e$6.path,
                                  message: e$6.message,
                                  value: e$6.value
                                }
                              };
                      }
                      var e$7 = validation._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".validation" + e$7.path,
                                message: e$7.message,
                                value: e$7.value
                              }
                            };
                    }
                    var e$8 = negative._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".negative" + e$8.path,
                              message: e$8.message,
                              value: e$8.value
                            }
                          };
                  }
                  var e$9 = comment._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".comment" + e$9.path,
                            message: e$9.message,
                            value: e$9.value
                          }
                        };
                }
                var e$10 = amountPaid._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".amountPaid" + e$10.path,
                          message: e$10.message,
                          value: e$10.value
                        }
                      };
              }
              var e$11 = amountTtc._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".amountTtc" + e$11.path,
                        message: e$11.message,
                        value: e$11.value
                      }
                    };
            }
            var e$12 = amountOriginal._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".amountOriginal" + e$12.path,
                      message: e$12.message,
                      value: e$12.value
                    }
                  };
          }
          var e$13 = amount._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".amount" + e$13.path,
                    message: e$13.message,
                    value: e$13.value
                  }
                };
        }
        var e$14 = derogateLabel._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".derogateLabel" + e$14.path,
                  message: e$14.message,
                  value: e$14.value
                }
              };
      }
      var e$15 = derogateId._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".derogateId" + e$15.path,
                message: e$15.message,
                value: e$15.value
              }
            };
    }
    var e$16 = actionType._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".actionType" + e$16.path,
              message: e$16.message,
              value: e$16.value
            }
          };
  }
  var e$17 = actionId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".actionId" + e$17.path,
            message: e$17.message,
            value: e$17.value
          }
        };
}

var Derogate = {
  derogate_encode: derogate_encode,
  derogate_decode: derogate_decode
};

function derogateSave_encode(v) {
  return Js_dict.fromArray([
              [
                "derogateId",
                Decco.optionToJson(Decco.stringToJson, v.derogateId)
              ],
              [
                "actionId",
                Decco.stringToJson(v.actionId)
              ],
              [
                "negative",
                Decco.boolToJson(v.negative)
              ],
              [
                "derogateLabel",
                Decco.stringToJson(v.derogateLabel)
              ],
              [
                "amount",
                Decco.floatToJson(v.amount)
              ],
              [
                "validation",
                Decco.optionToJson(Decco.stringToJson, v.validation)
              ],
              [
                "comment",
                Decco.optionToJson(Decco.stringToJson, v.comment)
              ],
              [
                "orderType",
                Decco.optionToJson(Decco.stringToJson, v.orderType)
              ]
            ]);
}

function derogateSave_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var derogateId = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "derogateId"), null));
  if (derogateId.TAG === /* Ok */0) {
    var actionId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionId"), null));
    if (actionId.TAG === /* Ok */0) {
      var negative = Decco.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "negative"), null));
      if (negative.TAG === /* Ok */0) {
        var derogateLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "derogateLabel"), null));
        if (derogateLabel.TAG === /* Ok */0) {
          var amount = Decco.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "amount"), null));
          if (amount.TAG === /* Ok */0) {
            var validation = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "validation"), null));
            if (validation.TAG === /* Ok */0) {
              var comment = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "comment"), null));
              if (comment.TAG === /* Ok */0) {
                var orderType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderType"), null));
                if (orderType.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: {
                            derogateId: derogateId._0,
                            actionId: actionId._0,
                            negative: negative._0,
                            derogateLabel: derogateLabel._0,
                            amount: amount._0,
                            validation: validation._0,
                            comment: comment._0,
                            orderType: orderType._0
                          }
                        };
                }
                var e = orderType._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".orderType" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = comment._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".comment" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = validation._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".validation" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = amount._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".amount" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = derogateLabel._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".derogateLabel" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = negative._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".negative" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = actionId._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".actionId" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = derogateId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".derogateId" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

var DeroSave = {
  derogateSave_encode: derogateSave_encode,
  derogateSave_decode: derogateSave_decode
};

function perfDetailItem_encode(v) {
  return Js_dict.fromArray([
              [
                "planPerfID",
                Decco.optionToJson(Decco.stringToJson, v.planPerfID)
              ],
              [
                "planPerfLabel",
                Decco.optionToJson(Decco.stringToJson, v.planPerfLabel)
              ],
              [
                "bonusActionTypePERF",
                Decco.optionToJson(Decco.stringToJson, v.bonusActionTypePERF)
              ],
              [
                "planPerfAmount",
                Decco.optionToJson(Decco.floatToJson, v.planPerfAmount)
              ]
            ]);
}

function perfDetailItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var planPerfID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "planPerfID"), null));
  if (planPerfID.TAG === /* Ok */0) {
    var planPerfLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "planPerfLabel"), null));
    if (planPerfLabel.TAG === /* Ok */0) {
      var bonusActionTypePERF = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "bonusActionTypePERF"), null));
      if (bonusActionTypePERF.TAG === /* Ok */0) {
        var planPerfAmount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "planPerfAmount"), null));
        if (planPerfAmount.TAG === /* Ok */0) {
          return {
                  TAG: /* Ok */0,
                  _0: {
                    planPerfID: planPerfID._0,
                    planPerfLabel: planPerfLabel._0,
                    bonusActionTypePERF: bonusActionTypePERF._0,
                    planPerfAmount: planPerfAmount._0
                  }
                };
        }
        var e = planPerfAmount._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".planPerfAmount" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = bonusActionTypePERF._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".bonusActionTypePERF" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = planPerfLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".planPerfLabel" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = planPerfID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".planPerfID" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function usedCarDetail_encode(v) {
  return Js_dict.fromArray([
              [
                "policeNumber",
                Decco.optionToJson(Decco.stringToJson, v.policeNumber)
              ],
              [
                "registrationNumber",
                Decco.optionToJson(Decco.stringToJson, v.registrationNumber)
              ],
              [
                "registrationDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.registrationDate)
              ],
              [
                "brand",
                Decco.optionToJson(Decco.stringToJson, v.brand)
              ],
              [
                "model",
                Decco.optionToJson(Decco.stringToJson, v.model)
              ],
              [
                "version",
                Decco.optionToJson(Decco.stringToJson, v.version)
              ],
              [
                "firstReleaseDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.firstReleaseDate)
              ],
              [
                "vin",
                Decco.optionToJson(Decco.stringToJson, v.vin)
              ],
              [
                "value",
                Decco.optionToJson(Decco.floatToJson, v.value)
              ],
              [
                "estimatedValue",
                Decco.optionToJson(Decco.floatToJson, v.estimatedValue)
              ],
              [
                "endOfLife",
                Decco.optionToJson(Decco.boolToJson, v.endOfLife)
              ]
            ]);
}

function usedCarDetail_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var policeNumber = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "policeNumber"), null));
  if (policeNumber.TAG === /* Ok */0) {
    var registrationNumber = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "registrationNumber"), null));
    if (registrationNumber.TAG === /* Ok */0) {
      var registrationDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "registrationDate"), null));
      if (registrationDate.TAG === /* Ok */0) {
        var brand = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "brand"), null));
        if (brand.TAG === /* Ok */0) {
          var model = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "model"), null));
          if (model.TAG === /* Ok */0) {
            var version = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "version"), null));
            if (version.TAG === /* Ok */0) {
              var firstReleaseDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "firstReleaseDate"), null));
              if (firstReleaseDate.TAG === /* Ok */0) {
                var vin = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "vin"), null));
                if (vin.TAG === /* Ok */0) {
                  var value = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "value"), null));
                  if (value.TAG === /* Ok */0) {
                    var estimatedValue = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "estimatedValue"), null));
                    if (estimatedValue.TAG === /* Ok */0) {
                      var endOfLife = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "endOfLife"), null));
                      if (endOfLife.TAG === /* Ok */0) {
                        return {
                                TAG: /* Ok */0,
                                _0: {
                                  policeNumber: policeNumber._0,
                                  registrationNumber: registrationNumber._0,
                                  registrationDate: registrationDate._0,
                                  brand: brand._0,
                                  model: model._0,
                                  version: version._0,
                                  firstReleaseDate: firstReleaseDate._0,
                                  vin: vin._0,
                                  value: value._0,
                                  estimatedValue: estimatedValue._0,
                                  endOfLife: endOfLife._0
                                }
                              };
                      }
                      var e = endOfLife._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".endOfLife" + e.path,
                                message: e.message,
                                value: e.value
                              }
                            };
                    }
                    var e$1 = estimatedValue._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".estimatedValue" + e$1.path,
                              message: e$1.message,
                              value: e$1.value
                            }
                          };
                  }
                  var e$2 = value._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".value" + e$2.path,
                            message: e$2.message,
                            value: e$2.value
                          }
                        };
                }
                var e$3 = vin._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".vin" + e$3.path,
                          message: e$3.message,
                          value: e$3.value
                        }
                      };
              }
              var e$4 = firstReleaseDate._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".firstReleaseDate" + e$4.path,
                        message: e$4.message,
                        value: e$4.value
                      }
                    };
            }
            var e$5 = version._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".version" + e$5.path,
                      message: e$5.message,
                      value: e$5.value
                    }
                  };
          }
          var e$6 = model._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".model" + e$6.path,
                    message: e$6.message,
                    value: e$6.value
                  }
                };
        }
        var e$7 = brand._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".brand" + e$7.path,
                  message: e$7.message,
                  value: e$7.value
                }
              };
      }
      var e$8 = registrationDate._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".registrationDate" + e$8.path,
                message: e$8.message,
                value: e$8.value
              }
            };
    }
    var e$9 = registrationNumber._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".registrationNumber" + e$9.path,
              message: e$9.message,
              value: e$9.value
            }
          };
  }
  var e$10 = policeNumber._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".policeNumber" + e$10.path,
            message: e$10.message,
            value: e$10.value
          }
        };
}

function bonusChangeInfo_encode(v) {
  return Js_dict.fromArray([
              [
                "date",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.date)
              ],
              [
                "approver",
                Decco.optionToJson(Decco.stringToJson, v.approver)
              ],
              [
                "comment",
                Decco.optionToJson(Decco.stringToJson, v.comment)
              ],
              [
                "refuseReason",
                Decco.optionToJson(Decco.stringToJson, v.refuseReason)
              ],
              [
                "approved",
                Decco.optionToJson(Decco.boolToJson, v.approved)
              ]
            ]);
}

function bonusChangeInfo_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var date = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "date"), null));
  if (date.TAG === /* Ok */0) {
    var approver = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "approver"), null));
    if (approver.TAG === /* Ok */0) {
      var comment = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "comment"), null));
      if (comment.TAG === /* Ok */0) {
        var refuseReason = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "refuseReason"), null));
        if (refuseReason.TAG === /* Ok */0) {
          var approved = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "approved"), null));
          if (approved.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      date: date._0,
                      approver: approver._0,
                      comment: comment._0,
                      refuseReason: refuseReason._0,
                      approved: approved._0
                    }
                  };
          }
          var e = approved._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".approved" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = refuseReason._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".refuseReason" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = comment._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".comment" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = approver._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".approver" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = date._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".date" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

export {
  commentItem_encode ,
  commentItem_decode ,
  Derogate ,
  DeroSave ,
  perfDetailItem_encode ,
  perfDetailItem_decode ,
  usedCarDetail_encode ,
  usedCarDetail_decode ,
  bonusChangeInfo_encode ,
  bonusChangeInfo_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
