// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as App_Types_VN_VO_Status$DvmAdminFrontendFr from "../../../common/types/App_Types_VN_VO_Status.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Layout_Setup$DvmAdminFrontendFr from "./Order_Detail_Layout_Setup.bs.js";

function Order_Detail_Page_VNStatus_Chip(Props) {
  var order = Props.order;
  var actualOrderType = Props.actualOrderType;
  var lastCorrectionValues = Props.lastCorrectionValues;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var missingFieldsTotal = Belt_Array.reduce(actualOrderType === /* ES */8 || actualOrderType === /* EP */7 ? Order_Detail_Layout_Setup$DvmAdminFrontendFr.orderDetailResell(order, actualOrderType, lastCorrectionValues, intl) : Order_Detail_Layout_Setup$DvmAdminFrontendFr.orderDetail(order, intl, match[0].selectedRole), 0, (function (acc, row) {
          return acc + Belt_Array.keep(row, (function (element) {
                        return Belt_Option.getWithDefault(element.error, false);
                      })).length | 0;
        }));
  return Belt_Option.mapWithDefault(order.detail.orderStatus, null, (function (orderStatus) {
                if (orderStatus > 5 || orderStatus < 1) {
                  return null;
                }
                if (orderStatus === 3) {
                  return null;
                }
                var tmp;
                if (orderStatus >= 3) {
                  tmp = null;
                } else {
                  switch (orderStatus) {
                    case /* COMPLETE */0 :
                        tmp = null;
                        break;
                    case /* INCOMPLETE */1 :
                        tmp = intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.waitingForData);
                        break;
                    case /* CANCELLED */2 :
                        tmp = intl.formatMessage(Messages_Orders_Detail$DvmAdminFrontendFr.orderIsCanceled);
                        break;
                    
                  }
                }
                var tmp$1 = {
                  arrow: true,
                  children: React.createElement("div", undefined, orderStatus === /* INCOMPLETE */1 ? React.createElement(Core.Badge, {
                              anchorOrigin: {
                                horizontal: "right",
                                vertical: "top"
                              },
                              badgeContent: missingFieldsTotal,
                              children: App_Types_VN_VO_Status$DvmAdminFrontendFr.toChip(orderStatus, intl),
                              color: "error"
                            }) : App_Types_VN_VO_Status$DvmAdminFrontendFr.toChip(orderStatus, intl)),
                  classes: {
                    tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                  },
                  placement: "right",
                  title: tmp
                };
                var tmp$2 = !(orderStatus === 2 || orderStatus === 1) ? false : undefined;
                if (tmp$2 !== undefined) {
                  tmp$1.open = tmp$2;
                }
                return React.createElement(Core.Grid, {
                            children: React.createElement(Core.Tooltip, tmp$1),
                            item: true
                          });
              }));
}

var Setup;

var make = Order_Detail_Page_VNStatus_Chip;

export {
  Setup ,
  make ,
}
/* react Not a pure module */
