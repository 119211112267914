// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../common/utils/Utils_Form.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as Flash_Search_Types$DvmAdminFrontendFr from "./Flash_Search_Types.bs.js";

function multiSelectToQueryParamBilingualContains(fieldId, values) {
  var fr = fieldId[1];
  var en = fieldId[0];
  var result = encodeURIComponent(Belt_Array.joinWith(Belt_List.toArray(values), "||", (function (value) {
              return en + "⊇" + value;
            })));
  var resultFR = encodeURIComponent(Belt_Array.joinWith(Belt_List.toArray(values), "||", (function (value) {
              return fr + "⊇" + value;
            })));
  if (result.length !== 0) {
    return {
            en: result,
            fr: resultFR
          };
  }
  
}

var validators_saleTypes = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: multiSelectToQueryParamBilingualContains(Flash_Search_Types$DvmAdminFrontendFr.Fields.saleTypes, param.saleTypes)
            };
    })
};

var validators_clientTypes = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamBilingual(Flash_Search_Types$DvmAdminFrontendFr.Fields.clientTypes, "normal", param.clientTypes)
            };
    })
};

var validators_endDate = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.endDate, undefined);
    })
};

var validators_startDate = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return Utils_Form$DvmAdminFrontendFr.validateDate(param.startDate, undefined);
    })
};

var validators_budgetType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      var budgetType = param.budgetType;
      if (!Belt_List.every(param.orderType, (function (x) {
                return x === "SO";
              }))) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var tmp;
      if (Belt_List.length(budgetType) > 0) {
        var budgetString = Belt_Array.joinWith(Belt_List.toArray(Belt_List.map(budgetType, (function (x) {
                        return "" + x + "=true";
                      }))), ";", (function (value) {
                return value;
              }));
        tmp = {
          en: budgetString,
          fr: budgetString
        };
      } else {
        tmp = undefined;
      }
      return {
              TAG: /* Ok */0,
              _0: tmp
            };
    })
};

var validators_actionType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(true, Flash_Search_Types$DvmAdminFrontendFr.Fields.actionType, param.actionType, false)
            };
    })
};

var validators_actionLabel = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(false, Flash_Search_Types$DvmAdminFrontendFr.Fields.actionLabel, param.actionLabel, false)
            };
    })
};

var validators_actionID = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      return {
              TAG: /* Ok */0,
              _0: Utils_Api$DvmAdminFrontendFr.stringFieldToQueryParamBilingual(false, Flash_Search_Types$DvmAdminFrontendFr.Fields.actionID, param.actionID, false)
            };
    })
};

var validators_orderType = {
  strategy: /* OnFirstSuccessOrFirstBlur */3,
  validate: (function (param, param$1) {
      if (User_Types_Utils$DvmAdminFrontendFr.whatCountry(param$1.selectedRole) !== /* France */0) {
        return {
                TAG: /* Ok */0,
                _0: undefined
              };
      }
      var orderType = Utils_Form$DvmAdminFrontendFr.validateListRequired(param.orderType);
      if (orderType.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: Utils_Api$DvmAdminFrontendFr.multiSelectToQueryParamContainsBilingual(Flash_Search_Types$DvmAdminFrontendFr.Fields.orderType, orderType._0)
              };
      } else {
        return {
                TAG: /* Error */1,
                _0: orderType._0
              };
      }
    })
};

var validators = {
  saleTypes: validators_saleTypes,
  clientTypes: validators_clientTypes,
  endDate: validators_endDate,
  startDate: validators_startDate,
  budgetType: validators_budgetType,
  actionType: validators_actionType,
  actionLabel: validators_actionLabel,
  actionID: validators_actionID,
  orderType: validators_orderType
};

function initialFieldsStatuses(_input) {
  return {
          saleTypes: /* Pristine */0,
          clientTypes: /* Pristine */0,
          endDate: /* Pristine */0,
          startDate: /* Pristine */0,
          budgetType: /* Pristine */0,
          actionType: /* Pristine */0,
          actionLabel: /* Pristine */0,
          actionID: /* Pristine */0,
          orderType: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.saleTypes;
  var match_0 = match ? match._0 : Curry._2(validators.saleTypes.validate, input, metadata);
  var match$1 = fieldsStatuses.clientTypes;
  var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.clientTypes.validate, input, metadata);
  var match$2 = fieldsStatuses.endDate;
  var match_0$2 = match$2 ? match$2._0 : Curry._2(validators.endDate.validate, input, metadata);
  var match$3 = fieldsStatuses.startDate;
  var match_0$3 = match$3 ? match$3._0 : Curry._2(validators.startDate.validate, input, metadata);
  var match$4 = fieldsStatuses.budgetType;
  var match_0$4 = match$4 ? match$4._0 : Curry._2(validators.budgetType.validate, input, metadata);
  var match$5 = fieldsStatuses.actionType;
  var match_0$5 = match$5 ? match$5._0 : Curry._2(validators.actionType.validate, input, metadata);
  var match$6 = fieldsStatuses.actionLabel;
  var match_0$6 = match$6 ? match$6._0 : Curry._2(validators.actionLabel.validate, input, metadata);
  var match$7 = fieldsStatuses.actionID;
  var match_0$7 = match$7 ? match$7._0 : Curry._2(validators.actionID.validate, input, metadata);
  var match$8 = fieldsStatuses.orderType;
  var match_0$8 = match$8 ? match$8._0 : Curry._2(validators.orderType.validate, input, metadata);
  var saleTypesResult = match_0;
  var saleTypesResult$1;
  if (saleTypesResult.TAG === /* Ok */0) {
    var clientTypesResult = match_0$1;
    if (clientTypesResult.TAG === /* Ok */0) {
      var endDateResult = match_0$2;
      if (endDateResult.TAG === /* Ok */0) {
        var startDateResult = match_0$3;
        if (startDateResult.TAG === /* Ok */0) {
          var budgetTypeResult = match_0$4;
          if (budgetTypeResult.TAG === /* Ok */0) {
            var actionTypeResult = match_0$5;
            if (actionTypeResult.TAG === /* Ok */0) {
              var actionLabelResult = match_0$6;
              if (actionLabelResult.TAG === /* Ok */0) {
                var actionIDResult = match_0$7;
                if (actionIDResult.TAG === /* Ok */0) {
                  var orderTypeResult = match_0$8;
                  if (orderTypeResult.TAG === /* Ok */0) {
                    return {
                            TAG: /* Valid */0,
                            output: {
                              orderType: orderTypeResult._0,
                              actionID: actionIDResult._0,
                              actionLabel: actionLabelResult._0,
                              actionType: actionTypeResult._0,
                              budgetType: budgetTypeResult._0,
                              startDate: startDateResult._0,
                              endDate: endDateResult._0,
                              clientTypes: clientTypesResult._0,
                              saleTypes: saleTypesResult._0
                            },
                            fieldsStatuses: {
                              saleTypes: /* Dirty */{
                                _0: saleTypesResult,
                                _1: /* Shown */0
                              },
                              clientTypes: /* Dirty */{
                                _0: clientTypesResult,
                                _1: /* Shown */0
                              },
                              endDate: /* Dirty */{
                                _0: endDateResult,
                                _1: /* Shown */0
                              },
                              startDate: /* Dirty */{
                                _0: startDateResult,
                                _1: /* Shown */0
                              },
                              budgetType: /* Dirty */{
                                _0: budgetTypeResult,
                                _1: /* Shown */0
                              },
                              actionType: /* Dirty */{
                                _0: actionTypeResult,
                                _1: /* Shown */0
                              },
                              actionLabel: /* Dirty */{
                                _0: actionLabelResult,
                                _1: /* Shown */0
                              },
                              actionID: /* Dirty */{
                                _0: actionIDResult,
                                _1: /* Shown */0
                              },
                              orderType: /* Dirty */{
                                _0: orderTypeResult,
                                _1: /* Shown */0
                              }
                            },
                            collectionsStatuses: undefined
                          };
                  }
                  saleTypesResult$1 = saleTypesResult;
                } else {
                  saleTypesResult$1 = saleTypesResult;
                }
              } else {
                saleTypesResult$1 = saleTypesResult;
              }
            } else {
              saleTypesResult$1 = saleTypesResult;
            }
          } else {
            saleTypesResult$1 = saleTypesResult;
          }
        } else {
          saleTypesResult$1 = saleTypesResult;
        }
      } else {
        saleTypesResult$1 = saleTypesResult;
      }
    } else {
      saleTypesResult$1 = saleTypesResult;
    }
  } else {
    saleTypesResult$1 = saleTypesResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            saleTypes: /* Dirty */{
              _0: saleTypesResult$1,
              _1: /* Shown */0
            },
            clientTypes: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            endDate: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            startDate: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            budgetType: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            actionType: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            },
            actionLabel: /* Dirty */{
              _0: match_0$6,
              _1: /* Shown */0
            },
            actionID: /* Dirty */{
              _0: match_0$7,
              _1: /* Shown */0
            },
            orderType: /* Dirty */{
              _0: match_0$8,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurSaleTypesField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.saleTypes, validators_saleTypes, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: status,
                                  clientTypes: init.clientTypes,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  budgetType: init.budgetType,
                                  actionType: init.actionType,
                                  actionLabel: init.actionLabel,
                                  actionID: init.actionID,
                                  orderType: init.orderType
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurClientTypesField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.clientTypes, validators_clientTypes, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: status,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  budgetType: init.budgetType,
                                  actionType: init.actionType,
                                  actionLabel: init.actionLabel,
                                  actionID: init.actionID,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEndDateField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.endDate, validators_endDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  endDate: status,
                                  startDate: init.startDate,
                                  budgetType: init.budgetType,
                                  actionType: init.actionType,
                                  actionLabel: init.actionLabel,
                                  actionID: init.actionID,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStartDateField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.startDate, validators_startDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  endDate: init.endDate,
                                  startDate: status,
                                  budgetType: init.budgetType,
                                  actionType: init.actionType,
                                  actionLabel: init.actionLabel,
                                  actionID: init.actionID,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurBudgetTypeField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.budgetType, validators_budgetType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  budgetType: status,
                                  actionType: init.actionType,
                                  actionLabel: init.actionLabel,
                                  actionID: init.actionID,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurActionTypeField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.actionType, validators_actionType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  budgetType: init.budgetType,
                                  actionType: status,
                                  actionLabel: init.actionLabel,
                                  actionID: init.actionID,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurActionLabelField */6 :
                  var result$6 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.actionLabel, validators_actionLabel, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  budgetType: init.budgetType,
                                  actionType: init.actionType,
                                  actionLabel: status,
                                  actionID: init.actionID,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$6 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$6,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurActionIDField */7 :
                  var result$7 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.actionID, validators_actionID, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  budgetType: init.budgetType,
                                  actionType: init.actionType,
                                  actionLabel: init.actionLabel,
                                  actionID: status,
                                  orderType: init.orderType
                                };
                        }));
                  if (result$7 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$7,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurOrderTypeField */8 :
                  var result$8 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.orderType, validators_orderType, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  saleTypes: init.saleTypes,
                                  clientTypes: init.clientTypes,
                                  endDate: init.endDate,
                                  startDate: init.startDate,
                                  budgetType: init.budgetType,
                                  actionType: init.actionType,
                                  actionLabel: init.actionLabel,
                                  actionID: init.actionID,
                                  orderType: status
                                };
                        }));
                  if (result$8 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$8,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */9 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */9,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */10,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */12);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */11);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */10 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */11 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */12 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateSaleTypesField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, state.fieldsStatuses.saleTypes, state.submissionStatus, validators_saleTypes, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: status,
                                            clientTypes: init.clientTypes,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            budgetType: init.budgetType,
                                            actionType: init.actionType,
                                            actionLabel: init.actionLabel,
                                            actionID: init.actionID,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateClientTypesField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, state.fieldsStatuses.clientTypes, state.submissionStatus, validators_clientTypes, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: status,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            budgetType: init.budgetType,
                                            actionType: init.actionType,
                                            actionLabel: init.actionLabel,
                                            actionID: init.actionID,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEndDateField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, state.fieldsStatuses.endDate, state.submissionStatus, validators_endDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            endDate: status,
                                            startDate: init.startDate,
                                            budgetType: init.budgetType,
                                            actionType: init.actionType,
                                            actionLabel: init.actionLabel,
                                            actionID: init.actionID,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStartDateField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$3, metadata, state.fieldsStatuses.startDate, state.submissionStatus, validators_startDate, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            endDate: init.endDate,
                                            startDate: status,
                                            budgetType: init.budgetType,
                                            actionType: init.actionType,
                                            actionLabel: init.actionLabel,
                                            actionID: init.actionID,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateBudgetTypeField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, state.fieldsStatuses.budgetType, state.submissionStatus, validators_budgetType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            budgetType: status,
                                            actionType: init.actionType,
                                            actionLabel: init.actionLabel,
                                            actionID: init.actionID,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateActionTypeField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$5, metadata, state.fieldsStatuses.actionType, state.submissionStatus, validators_actionType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            budgetType: init.budgetType,
                                            actionType: status,
                                            actionLabel: init.actionLabel,
                                            actionID: init.actionID,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateActionLabelField */6 :
                  var nextInput$6 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$6,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$6, metadata, state.fieldsStatuses.actionLabel, state.submissionStatus, validators_actionLabel, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            budgetType: init.budgetType,
                                            actionType: init.actionType,
                                            actionLabel: status,
                                            actionID: init.actionID,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateActionIDField */7 :
                  var nextInput$7 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$7,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$7, metadata, state.fieldsStatuses.actionID, state.submissionStatus, validators_actionID, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            budgetType: init.budgetType,
                                            actionType: init.actionType,
                                            actionLabel: init.actionLabel,
                                            actionID: status,
                                            orderType: init.orderType
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateOrderTypeField */8 :
                  var nextInput$8 = Curry._1(action._0, state.input);
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$8,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$8, metadata, state.fieldsStatuses.orderType, state.submissionStatus, validators_orderType, (function (status) {
                                    var init = state.fieldsStatuses;
                                    return {
                                            saleTypes: init.saleTypes,
                                            clientTypes: init.clientTypes,
                                            endDate: init.endDate,
                                            startDate: init.startDate,
                                            budgetType: init.budgetType,
                                            actionType: init.actionType,
                                            actionLabel: init.actionLabel,
                                            actionID: init.actionID,
                                            orderType: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */9 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */10 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */11 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateSaleTypes: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateSaleTypesField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateClientTypes: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateClientTypesField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStartDateField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateBudgetType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateBudgetTypeField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateActionType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateActionTypeField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateActionLabel: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateActionLabelField */6,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateActionID: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateActionIDField */7,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateOrderType: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateOrderTypeField */8,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurSaleTypes: (function (param) {
              Curry._1(dispatch, /* BlurSaleTypesField */0);
            }),
          blurClientTypes: (function (param) {
              Curry._1(dispatch, /* BlurClientTypesField */1);
            }),
          blurEndDate: (function (param) {
              Curry._1(dispatch, /* BlurEndDateField */2);
            }),
          blurStartDate: (function (param) {
              Curry._1(dispatch, /* BlurStartDateField */3);
            }),
          blurBudgetType: (function (param) {
              Curry._1(dispatch, /* BlurBudgetTypeField */4);
            }),
          blurActionType: (function (param) {
              Curry._1(dispatch, /* BlurActionTypeField */5);
            }),
          blurActionLabel: (function (param) {
              Curry._1(dispatch, /* BlurActionLabelField */6);
            }),
          blurActionID: (function (param) {
              Curry._1(dispatch, /* BlurActionIDField */7);
            }),
          blurOrderType: (function (param) {
              Curry._1(dispatch, /* BlurOrderTypeField */8);
            }),
          saleTypesResult: Formality.exposeFieldResult(state.fieldsStatuses.saleTypes),
          clientTypesResult: Formality.exposeFieldResult(state.fieldsStatuses.clientTypes),
          endDateResult: Formality.exposeFieldResult(state.fieldsStatuses.endDate),
          startDateResult: Formality.exposeFieldResult(state.fieldsStatuses.startDate),
          budgetTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.budgetType),
          actionTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.actionType),
          actionLabelResult: Formality.exposeFieldResult(state.fieldsStatuses.actionLabel),
          actionIDResult: Formality.exposeFieldResult(state.fieldsStatuses.actionID),
          orderTypeResult: Formality.exposeFieldResult(state.fieldsStatuses.orderType),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.saleTypes || match.clientTypes || match.endDate || match.startDate || match.budgetType || match.actionType || match.actionLabel || match.actionID || match.orderType) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */9);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */10);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */11);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */11,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */12);
            })
        };
}

var TableFilterForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var initialState$1 = {
  orderType: /* [] */0,
  actionID: "",
  actionLabel: "",
  actionType: "",
  budgetType: /* [] */0,
  startDate: undefined,
  endDate: undefined,
  clientTypes: /* [] */0,
  saleTypes: /* [] */0
};

export {
  multiSelectToQueryParamBilingualContains ,
  TableFilterForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
