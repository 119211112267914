// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../../common/utils/Utils_Date.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as Flash_Search_Types$DvmAdminFrontendFr from "./Flash_Search_Types.bs.js";

function makeDateOverlap(start, end, vehicleFilter, fieldsStart, fieldsEnd, language, alwaysShow) {
  if (!alwaysShow && Belt_Option.isNone(start) && Belt_Option.isNone(end)) {
    return "";
  }
  var startStr = encodeURIComponent(Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(start), "1900-01-01"));
  var endStr = encodeURIComponent(Belt_Option.getWithDefault(Utils_Date$DvmAdminFrontendFr.toISODateString(end), "3000-12-31"));
  var endDateEn = fieldsEnd[0];
  var startDateEn = fieldsStart[0];
  var match = vehicleFilter || language !== "fr" ? [
      startDateEn,
      endDateEn
    ] : [
      fieldsStart[1],
      fieldsEnd[1]
    ];
  var endDateString = match[1];
  var startDateString = match[0];
  return Belt_Array.joinWith([
              encodeURIComponent("" + startDateString + "=[\\," + startStr + "]&&" + endDateString + "=[" + startStr + "\\,]"),
              encodeURIComponent("" + startDateString + "=[\\," + endStr + "]&&" + endDateString + "=[" + endStr + "\\,]"),
              encodeURIComponent("" + startDateString + "=[" + startStr + "\\,]&&" + endDateString + "=[\\," + endStr + "]"),
              encodeURIComponent("" + startDateString + "=[\\," + startStr + "]&&" + endDateString + "=[" + endStr + "\\,]")
            ], encodeURIComponent("||"), (function (x) {
                return x;
              }));
}

function getQueryParams(form, formModal, formModalMoreDates, formAdditional, tableType, language, selectedRole) {
  var match = Belt_Option.mapWithDefault(form, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ], (function (x) {
          return [
                  x.actionID,
                  x.actionLabel,
                  x.actionType,
                  x.budgetType,
                  x.startDate,
                  x.endDate,
                  x.orderType,
                  x.clientTypes,
                  x.saleTypes
                ];
        }));
  var match$1 = Belt_Option.mapWithDefault(formAdditional, [
        undefined,
        undefined,
        undefined
      ], (function (x) {
          return [
                  x.brand,
                  x.modelID,
                  x.phase
                ];
        }));
  var phase = match$1[2];
  var modelID = match$1[1];
  var brand = match$1[0];
  var match$2 = Belt_Option.mapWithDefault(formModal, [
        undefined,
        undefined
      ], (function (x) {
          return [
                  x.detentionMinFrom,
                  x.detentionMaxFrom
                ];
        }));
  var vehicleFilter = Belt_Option.isSome(brand) || Belt_Option.isSome(modelID) || Belt_Option.isSome(phase);
  var firstStr = Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(Belt_List.map(Belt_List.concat(Belt_List.concat({
                            hd: match[0],
                            tl: {
                              hd: match[1],
                              tl: {
                                hd: match[2],
                                tl: {
                                  hd: match[3],
                                  tl: {
                                    hd: match$2[0],
                                    tl: {
                                      hd: match$2[1],
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }, User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* Spain */1 ? ({
                                hd: match[7],
                                tl: {
                                  hd: match[8],
                                  tl: /* [] */0
                                }
                              }) : /* [] */0), User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? ({
                            hd: tableType === /* NonFleet */1 ? match[6] : undefined,
                            tl: /* [] */0
                          }) : /* [] */0), (function (x) {
                      return Belt_Option.flatMap(x, (function (x) {
                                    return vehicleFilter || language !== "fr" ? x.en : x.fr;
                                  }));
                    })), (function (queryParam) {
                  return queryParam;
                }))), encodeURIComponent(";"), (function (x) {
          return x;
        }));
  var str = firstStr.length > 0 ? firstStr + encodeURIComponent(";") : "";
  var startEndDateOverlap = makeDateOverlap(match[4], match[5], vehicleFilter, Flash_Search_Types$DvmAdminFrontendFr.Fields.startDate, Flash_Search_Types$DvmAdminFrontendFr.Fields.endDate, language, true);
  return str + (
          startEndDateOverlap.length > 0 ? startEndDateOverlap + encodeURIComponent(";") : ""
        ) + Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(Belt_List.concat(Belt_Option.mapWithDefault(formModal, /* [] */0, (function (x) {
                                return {
                                        hd: x.registrationStartFrom,
                                        tl: {
                                          hd: x.registrationEndFrom,
                                          tl: {
                                            hd: x.deliveryStartFrom,
                                            tl: {
                                              hd: x.deliveryEndFrom,
                                              tl: {
                                                hd: x.madaStartFrom,
                                                tl: {
                                                  hd: x.madaEndFrom,
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      };
                              })), Belt_Option.mapWithDefault(formModalMoreDates, /* [] */0, (function (x) {
                                return {
                                        hd: x.madcStartFrom,
                                        tl: {
                                          hd: x.madcEndFrom,
                                          tl: {
                                            hd: x.entryParcStartFrom,
                                            tl: {
                                              hd: x.entryParcEndFrom,
                                              tl: {
                                                hd: x.exitParcStartFrom,
                                                tl: {
                                                  hd: x.exitParcEndFrom,
                                                  tl: /* [] */0
                                                }
                                              }
                                            }
                                          }
                                        }
                                      };
                              }))), (function (queryParam) {
                        return Belt_Option.map(queryParam, (function (x) {
                                      if (vehicleFilter || language !== "fr") {
                                        return x.en;
                                      } else {
                                        return x.fr;
                                      }
                                    }));
                      }))), encodeURIComponent(";"), (function (queryParam) {
                return queryParam;
              })) + Belt_Option.mapWithDefault(brand, "", (function (x) {
                return "&brandsFilter=value=" + x + "";
              })) + (
          modelID !== undefined ? (
              phase !== undefined ? "&modelPhaseFilter=" + modelID + ";" + phase + "" : "&modelPhaseFilter=" + modelID + ""
            ) : (
              phase !== undefined ? "&modelPhaseFilter=" + phase + "" : ""
            )
        );
}

export {
  makeDateOverlap ,
  getQueryParams ,
}
/* Utils_Date-DvmAdminFrontendFr Not a pure module */
