// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Table$DvmAdminFrontendFr from "../../../common/components/table/App_Table.bs.js";
import * as Utils_Api$DvmAdminFrontendFr from "../../../common/utils/Utils_Api.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../intl/Intl_Utils.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as App_Table_Head$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Head.bs.js";
import * as App_Table_Types$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Types.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Types_Status$DvmAdminFrontendFr from "../../../common/types/App_Types_Status.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as App_Styles_Status$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Status.bs.js";
import * as Order_Search_Types$DvmAdminFrontendFr from "./Order_Search_Types.bs.js";
import * as Order_Detail_Router$DvmAdminFrontendFr from "../detail/Order_Detail_Router.bs.js";
import * as App_Table_Pagination$DvmAdminFrontendFr from "../../../common/components/table/App_Table_Pagination.bs.js";
import * as App_Types_VN_VO_Status$DvmAdminFrontendFr from "../../../common/types/App_Types_VN_VO_Status.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Messages_Orders_Search$DvmAdminFrontendFr from "../../../intl/messages/orders/Messages_Orders_Search.bs.js";
import * as Order_Search_Export_Menu$DvmAdminFrontendFr from "./Order_Search_Export_Menu.bs.js";

function getEnglish(param) {
  return param[0];
}

function headersConfiguration(selectedRole) {
  return Belt_List.keepMap(Belt_List.concat(User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? ({
                      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.orderType), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.orderType),
                      tl: /* [] */0
                    }) : ({
                      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.clientTypes), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.clientType),
                      tl: {
                        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.saleTypes), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.saleType),
                        tl: /* [] */0
                      }
                    }), {
                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.customerOrderNumber), undefined, undefined, undefined, undefined, undefined, Messages_Orders_Search$DvmAdminFrontendFr.customerOrderNum),
                  tl: {
                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.orderNumberCar), undefined, undefined, undefined, undefined, undefined, Messages_Orders_Search$DvmAdminFrontendFr.carOrderNum),
                    tl: {
                      hd: User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.budgetType), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.budget) : undefined,
                      tl: {
                        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.orderStatus), undefined, undefined, "center", undefined, undefined, Messages_Orders_Search$DvmAdminFrontendFr.orderStatusLabel),
                        tl: {
                          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.usedCarStatus), undefined, undefined, "center", undefined, undefined, Messages_Orders_Search$DvmAdminFrontendFr.usedCarStatusLabel),
                          tl: {
                            hd: User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.budgetStatus), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.stateBudget) : undefined,
                            tl: {
                              hd: User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.siren), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.siren) : undefined,
                              tl: {
                                hd: User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.customerName), undefined, undefined, undefined, undefined, undefined, Messages_Orders_Detail$DvmAdminFrontendFr.customerName) : undefined,
                                tl: {
                                  hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.vin), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.vin),
                                  tl: {
                                    hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.orderDate), /* Desc */1, undefined, "right", undefined, undefined, Messages_Orders_Search$DvmAdminFrontendFr.orderDate),
                                    tl: {
                                      hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.deliveryDateBoth), undefined, undefined, "right", undefined, undefined, Messages_Orders_Search$DvmAdminFrontendFr.deliveryDate),
                                      tl: {
                                        hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.modelID), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.model),
                                        tl: {
                                          hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.versionID), undefined, undefined, undefined, undefined, undefined, Messages_Common$DvmAdminFrontendFr.version),
                                          tl: {
                                            hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.discount), undefined, undefined, "right", undefined, undefined, Messages_Common$DvmAdminFrontendFr.discount),
                                            tl: {
                                              hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.entryDateParc), undefined, undefined, "right", undefined, undefined, Messages_Orders_Detail$DvmAdminFrontendFr.entryDateParc),
                                              tl: {
                                                hd: App_Table_Head$DvmAdminFrontendFr.makeHeaderCell(getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.exitDateParc), undefined, undefined, "right", undefined, undefined, Messages_Orders_Detail$DvmAdminFrontendFr.exitDateParc),
                                                tl: /* [] */0
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }), (function (x) {
                return x;
              }));
}

function getStateElement(state, intl) {
  return React.createElement(Core.Grid, {
              alignItems: "center",
              children: null,
              container: true,
              direction: "row",
              spacing: 1,
              wrap: "nowrap"
            }, React.createElement(Core.Grid, {
                  children: React.createElement(Core.Box, {
                        className: App_Styles_Status$DvmAdminFrontendFr.oval,
                        bgcolor: App_Styles_Status$DvmAdminFrontendFr.stateColors(state !== undefined ? state : /* MISSINGSTATUS */11)
                      }),
                  item: true
                }), React.createElement(Core.Grid, {
                  children: Belt_Option.mapWithDefault(state, "-", (function (x) {
                          return intl.formatMessage(App_Types_Status$DvmAdminFrontendFr.toMessage(x));
                        })),
                  item: true
                }));
}

function Order_Search_Table(Props) {
  var tablePage = Props.tablePage;
  var fetchRequest = Props.fetchRequest;
  var fetchData = Props.fetchData;
  var paramsList = Props.paramsList;
  var showHistory = Props.showHistory;
  var confirmedOrderType = Props.confirmedOrderType;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match[0].selectedRole;
  var orderMaxExport = User_Types_Utils$DvmAdminFrontendFr.isAdmin(selectedRole) ? 130000 : 80000;
  var convertTable = function (table) {
    return App_Types_Result$DvmAdminFrontendFr.map(table, (function (param) {
                  return {
                          content: Belt_List.map(param.content, (function (row) {
                                  return App_Table_Types$DvmAdminFrontendFr.makeRow((function ($$event) {
                                                if ($$event.ctrlKey) {
                                                  return Order_Detail_Router$DvmAdminFrontendFr.openNewTab(row.orderId, undefined);
                                                } else {
                                                  return Order_Detail_Router$DvmAdminFrontendFr.routeToPage(row.orderId, undefined);
                                                }
                                              }), undefined, undefined, Belt_List.mapWithIndex(Belt_List.concat(Belt_List.keepMap(Belt_List.concat(User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? ({
                                                                  hd: Caml_option.some(Belt_Option.getWithDefault(row.orderOrderType, "")),
                                                                  tl: /* [] */0
                                                                }) : ({
                                                                  hd: Caml_option.some(Belt_Option.getWithDefault(row.orderClientType, "")),
                                                                  tl: {
                                                                    hd: Caml_option.some(Belt_Option.getWithDefault(row.orderSaleType, "")),
                                                                    tl: /* [] */0
                                                                  }
                                                                }), {
                                                              hd: Caml_option.some(row.orderCustomerOrderNumber),
                                                              tl: {
                                                                hd: Caml_option.some(Belt_Option.getWithDefault(row.orderCarOrderNumber, "")),
                                                                tl: {
                                                                  hd: User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? Caml_option.some(Belt_Option.getWithDefault(row.orderBudgetType, "")) : undefined,
                                                                  tl: {
                                                                    hd: Caml_option.some(App_Types_VN_VO_Status$DvmAdminFrontendFr.toChip(Belt_Option.getWithDefault(row.resultOrderStatus, /* NOTSET */6), intl)),
                                                                    tl: {
                                                                      hd: Caml_option.some(App_Types_VN_VO_Status$DvmAdminFrontendFr.toChip(Belt_Option.getWithDefault(row.resultUcStatus, /* NOTSET */6), intl)),
                                                                      tl: {
                                                                        hd: User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? Caml_option.some(Belt_Option.getWithDefault(row.orderOrderType, "") !== "SO" ? null : getStateElement(row.resultBudgetStatus, intl)) : undefined,
                                                                        tl: {
                                                                          hd: User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? Caml_option.some(Belt_Option.getWithDefault(row.siren, "")) : undefined,
                                                                          tl: {
                                                                            hd: User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? Caml_option.some(Belt_Option.getWithDefault(row.orderCustomerName, "")) : undefined,
                                                                            tl: {
                                                                              hd: Caml_option.some(Belt_Option.getWithDefault(row.orderVin, "")),
                                                                              tl: {
                                                                                hd: Caml_option.some(Belt_Option.mapWithDefault(row.orderOrderDate, "", (function (param) {
                                                                                            return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                          }))),
                                                                                tl: {
                                                                                  hd: Caml_option.some(Belt_Option.mapWithDefault(row.resultDeliveryDate, "", (function (param) {
                                                                                              return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                                            }))),
                                                                                  tl: {
                                                                                    hd: Caml_option.some(Belt_Option.getWithDefault(row.orderModelId, "")),
                                                                                    tl: {
                                                                                      hd: Caml_option.some(Belt_Option.getWithDefault(row.orderVersionId, "")),
                                                                                      tl: {
                                                                                        hd: Caml_option.some(Belt_Option.getWithDefault(row.orderOrderType, "") !== "SO" ? "" : Belt_Option.mapWithDefault(row.orderCompanyDiscount, "", (function (param) {
                                                                                                      return Intl_Utils$DvmAdminFrontendFr.formatPercent(intl, param);
                                                                                                    }))),
                                                                                        tl: /* [] */0
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          }
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }), (function (x) {
                                                            return x;
                                                          })), Belt_List.every(confirmedOrderType, (function (x) {
                                                            switch (x) {
                                                              case /* SO */0 :
                                                              case /* CL */1 :
                                                              case /* VD */3 :
                                                              case /* PG */6 :
                                                              case /* EP */7 :
                                                              case /* ES */8 :
                                                                  return false;
                                                              case /* VE */2 :
                                                              case /* RR */4 :
                                                              case /* RC */5 :
                                                              case /* NOTSET */9 :
                                                                  return true;
                                                              
                                                            }
                                                          })) || Belt_List.length(confirmedOrderType) === 0 ? ({
                                                          hd: Belt_Option.mapWithDefault(row.resultCalcPARCStartDate, "", (function (param) {
                                                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                })),
                                                          tl: {
                                                            hd: Belt_Option.mapWithDefault(row.resultPARCEndDate, "", (function (param) {
                                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                  })),
                                                            tl: /* [] */0
                                                          }
                                                        }) : /* [] */0), (function (index, content) {
                                                    return App_Table$DvmAdminFrontendFr.makeCell(Belt_Option.map(Belt_List.get(headersConfiguration(selectedRole), index), (function (param) {
                                                                      return param.align;
                                                                    })), undefined, undefined, content);
                                                  })));
                                })),
                          currentPage: fetchRequest.page,
                          totalElements: param.total
                        };
                }));
  };
  var handleSorting = function (headers) {
    Curry._5(fetchData, undefined, undefined, undefined, undefined, {
          page: fetchRequest.page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleChangePage = function (page) {
    Curry._5(fetchData, undefined, undefined, undefined, undefined, {
          page: page,
          rowsPerPage: fetchRequest.rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  var handleRowsPerPageChange = function (rowsPerPage) {
    Curry._5(fetchData, undefined, undefined, undefined, undefined, {
          page: 0,
          rowsPerPage: rowsPerPage,
          headers: fetchRequest.headers,
          filterQueryParams: fetchRequest.filterQueryParams,
          additionalQueryParams: fetchRequest.additionalQueryParams
        });
  };
  return React.createElement(App_Table$DvmAdminFrontendFr.make, {
              headers: Belt_List.map(fetchRequest.headers, (function (x) {
                      return {
                              idheader: x.idheader,
                              label: x.label,
                              order: x.order,
                              sort: tablePage === /* NotStarted */0 ? false : x.sort,
                              align: x.align,
                              priority: x.priority,
                              hide: Belt_List.every(confirmedOrderType, (function (x) {
                                      switch (x) {
                                        case /* SO */0 :
                                        case /* CL */1 :
                                        case /* VD */3 :
                                        case /* PG */6 :
                                        case /* EP */7 :
                                        case /* ES */8 :
                                            return false;
                                        case /* VE */2 :
                                        case /* RR */4 :
                                        case /* RC */5 :
                                        case /* NOTSET */9 :
                                            return true;
                                        
                                      }
                                    })) || Belt_List.length(confirmedOrderType) === 0 ? false : x.idheader === getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.entryDateParc) || x.idheader === getEnglish(Order_Search_Types$DvmAdminFrontendFr.Fields.exitDateParc)
                            };
                    })),
              handleSorting: handleSorting,
              tablePage: convertTable(tablePage),
              paginator: App_Table_Pagination$DvmAdminFrontendFr.makePaginator(undefined, undefined, handleChangePage, handleRowsPerPageChange, undefined),
              exportMenu: React.createElement(Core.Tooltip, {
                    arrow: true,
                    children: React.createElement("div", undefined, React.createElement(Order_Search_Export_Menu$DvmAdminFrontendFr.make, {
                              query: Utils_Api$DvmAdminFrontendFr.createQueryParams(undefined, {
                                    page: 0,
                                    rowsPerPage: orderMaxExport,
                                    headers: fetchRequest.headers,
                                    filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(paramsList, (function (queryParam) {
                                                    return Belt_Option.map(queryParam, (function (x) {
                                                                  return x.fr;
                                                                }));
                                                  }))), ";", (function (queryParam) {
                                            return queryParam;
                                          })),
                                    additionalQueryParams: fetchRequest.additionalQueryParams
                                  }),
                              disabled: App_Types_Result$DvmAdminFrontendFr.mapWithDefault(tablePage, false, (function (param) {
                                      return param.total > orderMaxExport;
                                    })),
                              showHistory: showHistory
                            })),
                    placement: "top",
                    title: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.maxLinesExport, {
                          count: intl.formatNumber(orderMaxExport)
                        })
                  })
            });
}

var make = Order_Search_Table;

export {
  getEnglish ,
  headersConfiguration ,
  getStateElement ,
  make ,
}
/* react Not a pure module */
