// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function DisableNumInputScroll(Props) {
  var handleWheel = function ($$event$1) {
    if ($$event$1.target.type === "number") {
      event.preventDefault($$event$1);
      return ;
    }
    
  };
  React.useEffect((function () {
          document.addEventListener("wheel", handleWheel, {
                passive: false
              });
          return (function (param) {
                    document.removeEventListener("wheel", handleWheel, {
                          passive: false
                        });
                  });
        }), []);
  return React.createElement(React.Fragment, undefined);
}

var make = DisableNumInputScroll;

export {
  make ,
}
/* react Not a pure module */
