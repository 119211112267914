// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Home$DvmAdminFrontendFr from "../pages/home/Home.bs.js";
import * as EnvVar$DvmAdminFrontendFr from "../bindings/global/EnvVar.bs.js";
import * as Upload_Page$DvmAdminFrontendFr from "../pages/upload/Upload_Page.bs.js";
import * as Order_Detail$DvmAdminFrontendFr from "../pages/order/detail/Order_Detail.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../context/user/User_Context.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../common/types/App_Types_Date.bs.js";
import * as Messages_Error$DvmAdminFrontendFr from "../intl/messages/Messages_Error.bs.js";
import * as Category_Search$DvmAdminFrontendFr from "../pages/category/search/Category_Search.bs.js";
import * as Flash_Edit_Page$DvmAdminFrontendFr from "../pages/flash/edit/Flash_Edit_Page.bs.js";
import * as Quota_View_Page$DvmAdminFrontendFr from "../pages/quota/search/view/Quota_View_Page.bs.js";
import * as Route_Protected$DvmAdminFrontendFr from "./Route_Protected.bs.js";
import * as Gemo_Search_Page$DvmAdminFrontendFr from "../pages/gemo/search/Gemo_Search_Page.bs.js";
import * as Margin_Grid_Page$DvmAdminFrontendFr from "../pages/grids/marginGrid/Margin_Grid_Page.bs.js";
import * as Quota_Admin_Page$DvmAdminFrontendFr from "../pages/quota/search/admin/Quota_Admin_Page.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../context/user/User_Types_Utils.bs.js";
import * as Flash_Detail_Page$DvmAdminFrontendFr from "../pages/flash/detail/page/Flash_Detail_Page.bs.js";
import * as Flash_Search_Page$DvmAdminFrontendFr from "../pages/flash/search/Flash_Search_Page.bs.js";
import * as Order_Search_Page$DvmAdminFrontendFr from "../pages/order/search/Order_Search_Page.bs.js";
import * as Quota_Detail_Page$DvmAdminFrontendFr from "../pages/quota/detail/Quota_Detail_Page.bs.js";
import * as Brands_Search_Page$DvmAdminFrontendFr from "../pages/brands/Brands_Search_Page.bs.js";
import * as Category_Edit_Page$DvmAdminFrontendFr from "../pages/category/edit/Category_Edit_Page.bs.js";
import * as Contract_Edit_Page$DvmAdminFrontendFr from "../pages/contract/editCreate/Contract_Edit_Page.bs.js";
import * as Dealer_Detail_Page$DvmAdminFrontendFr from "../pages/dealer/detail/Dealer_Detail_Page.bs.js";
import * as Dealer_Search_Page$DvmAdminFrontendFr from "../pages/dealer/search/Dealer_Search_Page.bs.js";
import * as Energy_Search_Page$DvmAdminFrontendFr from "../pages/energy/Energy_Search_Page.bs.js";
import * as Flash_Publish_Page$DvmAdminFrontendFr from "../pages/flash/actionPublish/Flash_Publish_Page.bs.js";
import * as Model_Version_Page$DvmAdminFrontendFr from "../pages/modelVersionList/Model_Version_Page.bs.js";
import * as Bonuses_Search_Page$DvmAdminFrontendFr from "../pages/bonusesList/Bonuses_Search_Page.bs.js";
import * as Company_Detail_Page$DvmAdminFrontendFr from "../pages/company/detail/Company_Detail_Page.bs.js";
import * as Company_Manage_Page$DvmAdminFrontendFr from "../pages/company/manage/Company_Manage_Page.bs.js";
import * as Company_Search_Page$DvmAdminFrontendFr from "../pages/company/search/Company_Search_Page.bs.js";
import * as Margin_Minimum_Page$DvmAdminFrontendFr from "../pages/marginMinimum/search/Margin_Minimum_Page.bs.js";
import * as Visibility_Profiles$DvmAdminFrontendFr from "../pages/visibilityProfiles/search/Visibility_Profiles.bs.js";
import * as Contract_Errors_Page$DvmAdminFrontendFr from "../pages/upload/contractErrors/Contract_Errors_Page.bs.js";
import * as Contract_Search_Page$DvmAdminFrontendFr from "../pages/contract/search/Contract_Search_Page.bs.js";
import * as Quota_View_Exclusion$DvmAdminFrontendFr from "../pages/quota/search/exclusion/Quota_View_Exclusion.bs.js";
import * as Auth_Select_Role_Page$DvmAdminFrontendFr from "../pages/auth/selectRole/Auth_Select_Role_Page.bs.js";
import * as Submarket_Search_Page$DvmAdminFrontendFr from "../pages/gemo/submarkets/Submarket_Search_Page.bs.js";
import * as ModelVersion_Grid_Page$DvmAdminFrontendFr from "../pages/grids/modelVersionGrid/ModelVersion_Grid_Page.bs.js";
import * as Quota_PARC_Search_Page$DvmAdminFrontendFr from "../pages/quotaPARC/search/Quota_PARC_Search_Page.bs.js";
import * as Action_Referential_Page$DvmAdminFrontendFr from "../pages/actionReferential/actionsSetup/search/Action_Referential_Page.bs.js";
import * as ModelVersion_Grid_Manage$DvmAdminFrontendFr from "../pages/grids/modelVersionGridManage/ModelVersion_Grid_Manage.bs.js";
import * as PCDAgreement_Search_Page$DvmAdminFrontendFr from "../pages/pcdAgreement/search/PCDAgreement_Search_Page.bs.js";
import * as FinancialCode_Search_Page$DvmAdminFrontendFr from "../pages/financialCode/search/FinancialCode_Search_Page.bs.js";
import * as Order_Detail_Correct_Page$DvmAdminFrontendFr from "../pages/order/detail/correct/Order_Detail_Correct_Page.bs.js";
import * as Contract_Manager_Edit_Page$DvmAdminFrontendFr from "../pages/contractManager/edit/Contract_Manager_Edit_Page.bs.js";
import * as Bonus_Anomalies_Search_Page$DvmAdminFrontendFr from "../pages/bonusAnomalies/Bonus_Anomalies_Search_Page.bs.js";
import * as Company_Virtual_Search_Page$DvmAdminFrontendFr from "../pages/company/virtual/Company_Virtual_Search_Page.bs.js";
import * as Contract_Manager_Search_Page$DvmAdminFrontendFr from "../pages/contractManager/search/Contract_Manager_Search_Page.bs.js";
import * as ModelVersion_Grid_Manage_Detail$DvmAdminFrontendFr from "../pages/grids/modelVersionGridManage/ModelVersion_Grid_Manage_Detail.bs.js";

function Route_Switch$PageNotFound(Props) {
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Typography, {
              children: intl.formatMessage(Messages_Error$DvmAdminFrontendFr.pageNotFound),
              variant: "h1"
            });
}

var PageNotFound = {
  make: Route_Switch$PageNotFound
};

function Route_Switch$DealerNotFound(Props) {
  var intl = ReactIntl.useIntl();
  return React.createElement(Core.Typography, {
              children: intl.formatMessage(Messages_Error$DvmAdminFrontendFr.dealerNotFound),
              variant: "h2"
            });
}

var DealerNotFound = {
  make: Route_Switch$DealerNotFound
};

function Route_Switch(Props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$1 = match[0];
  var dealerInfo = match$1.dealerInfo;
  var selectedRole = match$1.selectedRole;
  var match$2 = url.path;
  var tmp;
  var exit = 0;
  if (match$2) {
    switch (match$2.hd) {
      case "manage" :
          var match$3 = match$2.tl;
          if (match$3) {
            switch (match$3.hd) {
              case "actionReferential" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = EnvVar$DvmAdminFrontendFr.currentEnvironmentProd ? React.createElement(Route_Switch$PageNotFound, {}) : React.createElement(Action_Referential_Page$DvmAdminFrontendFr.make, {
                            operation: /* Manage */1,
                            key: "manage"
                          });
                  }
                  break;
              case "brands" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Brands_Search_Page$DvmAdminFrontendFr.make, {});
                  }
                  break;
              case "category" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Category_Search$DvmAdminFrontendFr.make, {
                          manage: true,
                          key: "manage"
                        });
                  }
                  break;
              case "categoryEdit" :
                  var match$4 = match$3.tl;
                  if (match$4) {
                    var match$5 = match$4.tl;
                    if (match$5 && !match$5.tl) {
                      tmp = React.createElement(Category_Edit_Page$DvmAdminFrontendFr.make, {
                            categoryCode: match$4.hd,
                            startDate: App_Types_Date$DvmAdminFrontendFr.fromString(match$5.hd),
                            key: "edit"
                          });
                    } else {
                      exit = 1;
                    }
                  } else {
                    tmp = React.createElement(Category_Edit_Page$DvmAdminFrontendFr.make, {
                          categoryCode: undefined,
                          startDate: undefined,
                          key: "new"
                        });
                  }
                  break;
              case "company" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Company_Manage_Page$DvmAdminFrontendFr.make, {});
                  }
                  break;
              case "companyVirtual" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Company_Virtual_Search_Page$DvmAdminFrontendFr.make, {
                          edit: true
                        });
                  }
                  break;
              case "contractEdit" :
                  var match$6 = match$3.tl;
                  if (match$6) {
                    var match$7 = match$6.tl;
                    if (match$7 && !match$7.tl) {
                      tmp = React.createElement(Contract_Edit_Page$DvmAdminFrontendFr.make, {
                            contractId: match$6.hd,
                            startDate: match$7.hd,
                            operation: /* Manage */1,
                            key: "edit"
                          });
                    } else {
                      exit = 1;
                    }
                  } else {
                    tmp = React.createElement(Contract_Edit_Page$DvmAdminFrontendFr.make, {
                          operation: /* Manage */1,
                          key: "new"
                        });
                  }
                  break;
              case "contractErrors" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Contract_Errors_Page$DvmAdminFrontendFr.make, {});
                  }
                  break;
              case "contractManager" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Contract_Manager_Search_Page$DvmAdminFrontendFr.make, {
                          operation: /* Manage */1,
                          key: "manage"
                        });
                  }
                  break;
              case "contractManagerEdit" :
                  var match$8 = match$3.tl;
                  if (match$8) {
                    if (match$8.tl) {
                      exit = 1;
                    } else {
                      tmp = React.createElement(Contract_Manager_Edit_Page$DvmAdminFrontendFr.make, {
                            managerId: match$8.hd,
                            key: "edit"
                          });
                    }
                  } else {
                    tmp = React.createElement(Contract_Manager_Edit_Page$DvmAdminFrontendFr.make, {
                          key: "new"
                        });
                  }
                  break;
              case "contracts" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Contract_Search_Page$DvmAdminFrontendFr.make, {
                          tableType: /* Contract */0,
                          operation: /* Manage */1,
                          key: "manage"
                        });
                  }
                  break;
              case "energies" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Energy_Search_Page$DvmAdminFrontendFr.make, {});
                  }
                  break;
              case "financialCode" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(FinancialCode_Search_Page$DvmAdminFrontendFr.make, {});
                  }
                  break;
              case "flash" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Flash_Search_Page$DvmAdminFrontendFr.make, {
                          operation: /* Manage */1,
                          key: "manage"
                        });
                  }
                  break;
              case "flashEdit" :
                  var match$9 = match$3.tl;
                  if (match$9) {
                    var match$10 = match$9.tl;
                    var flashType = match$9.hd;
                    if (match$10) {
                      if (match$10.tl) {
                        exit = 1;
                      } else {
                        var tmp$1;
                        switch (flashType) {
                          case "fleet" :
                              tmp$1 = /* Fleet */0;
                              break;
                          case "nonfleet" :
                              tmp$1 = /* NonFleet */1;
                              break;
                          default:
                            tmp$1 = undefined;
                        }
                        tmp = React.createElement(Flash_Edit_Page$DvmAdminFrontendFr.make, {
                              flashId: match$10.hd,
                              flashType: tmp$1,
                              key: "edit"
                            });
                      }
                    } else {
                      tmp = React.createElement(Flash_Edit_Page$DvmAdminFrontendFr.make, {
                            flashId: flashType,
                            flashType: /* Fleet */0,
                            key: "edit"
                          });
                    }
                  } else {
                    tmp = React.createElement(Flash_Edit_Page$DvmAdminFrontendFr.make, {
                          key: "new"
                        });
                  }
                  break;
              case "flashPublish" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Flash_Publish_Page$DvmAdminFrontendFr.make, {});
                  }
                  break;
              case "gemo" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Gemo_Search_Page$DvmAdminFrontendFr.make, {});
                  }
                  break;
              case "marginGrid" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Margin_Grid_Page$DvmAdminFrontendFr.make, {
                          operation: /* Manage */1,
                          key: "manage"
                        });
                  }
                  break;
              case "marginMinimum" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Margin_Minimum_Page$DvmAdminFrontendFr.make, {});
                  }
                  break;
              case "models" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Model_Version_Page$DvmAdminFrontendFr.make, {
                          allowEdit: true,
                          key: String(Date.now())
                        });
                  }
                  break;
              case "pcdAgreement" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(PCDAgreement_Search_Page$DvmAdminFrontendFr.make, {
                          operation: /* Manage */1,
                          key: "manage"
                        });
                  }
                  break;
              case "quota" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Quota_Admin_Page$DvmAdminFrontendFr.make, {});
                  }
                  break;
              case "quotaDetailCreate" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Quota_Detail_Page$DvmAdminFrontendFr.make, {
                          key: "create"
                        });
                  }
                  break;
              case "quotaDetailEdit" :
                  var match$11 = match$3.tl;
                  if (match$11) {
                    var match$12 = match$11.tl;
                    if (match$12) {
                      var match$13 = match$12.tl;
                      if (match$13) {
                        var match$14 = match$13.tl;
                        if (match$14 && !match$14.tl) {
                          tmp = React.createElement(Quota_Detail_Page$DvmAdminFrontendFr.make, {
                                quotaInfo: {
                                  dr: match$11.hd,
                                  contractID: match$12.hd,
                                  vehicleType: match$13.hd,
                                  startDate: match$14.hd
                                },
                                key: "edit"
                              });
                        } else {
                          exit = 1;
                        }
                      } else {
                        exit = 1;
                      }
                    } else {
                      exit = 1;
                    }
                  } else {
                    exit = 1;
                  }
                  break;
              case "quotaExclusions" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Quota_View_Exclusion$DvmAdminFrontendFr.make, {});
                  }
                  break;
              case "quotaPARC" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Quota_PARC_Search_Page$DvmAdminFrontendFr.make, {
                          pageType: /* Manage */1,
                          key: "manage"
                        });
                  }
                  break;
              case "submarket" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Submarket_Search_Page$DvmAdminFrontendFr.make, {});
                  }
                  break;
              case "upload" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Upload_Page$DvmAdminFrontendFr.make, {});
                  }
                  break;
              case "visibilityProfiles" :
                  if (match$3.tl) {
                    exit = 1;
                  } else {
                    tmp = React.createElement(Visibility_Profiles$DvmAdminFrontendFr.make, {});
                  }
                  break;
              default:
                exit = 1;
            }
          } else {
            exit = 1;
          }
          break;
      case "view" :
          var match$15 = match$2.tl;
          if (match$15 && match$15.hd === "marginGrid" && !match$15.tl) {
            tmp = React.createElement(Margin_Grid_Page$DvmAdminFrontendFr.make, {
                  operation: /* View */0,
                  key: "view"
                });
          } else {
            exit = 1;
          }
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(Route_Switch$PageNotFound, {});
  }
  var pathsForAdmin = React.createElement(Route_Protected$DvmAdminFrontendFr.make, {
        roles: {
          TAG: /* Access */0,
          _0: {
            hd: {
              TAG: /* Administrator */0,
              _0: /* France */0
            },
            tl: {
              hd: {
                TAG: /* Administrator */0,
                _0: /* Spain */1
              },
              tl: /* [] */0
            }
          }
        },
        children: tmp
      });
  var match$16 = url.path;
  var pathsForSubsidiary;
  if (match$16) {
    switch (match$16.hd) {
      case "manage" :
          var match$17 = match$16.tl;
          if (match$17) {
            switch (match$17.hd) {
              case "bonuses" :
                  pathsForSubsidiary = match$17.tl ? pathsForAdmin : React.createElement(Bonuses_Search_Page$DvmAdminFrontendFr.make, {
                          operation: /* Manage */0,
                          key: "manage"
                        });
                  break;
              case "modelVersionGrid" :
                  pathsForSubsidiary = match$17.tl ? pathsForAdmin : React.createElement(Route_Protected$DvmAdminFrontendFr.make, {
                          roles: {
                            TAG: /* Access */0,
                            _0: {
                              hd: {
                                TAG: /* Administrator */0,
                                _0: /* France */0
                              },
                              tl: {
                                hd: {
                                  TAG: /* Administrator */0,
                                  _0: /* Spain */1
                                },
                                tl: {
                                  hd: /* CompanyManager */2,
                                  tl: {
                                    hd: /* LldManager */5,
                                    tl: {
                                      hd: /* BigAccountManager */6,
                                      tl: {
                                        hd: /* AcountManager */4,
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          },
                          children: React.createElement(ModelVersion_Grid_Manage$DvmAdminFrontendFr.make, {})
                        });
                  break;
              case "modelVersionGridEdit" :
                  pathsForSubsidiary = match$17.tl ? pathsForAdmin : React.createElement(Route_Protected$DvmAdminFrontendFr.make, {
                          roles: {
                            TAG: /* Access */0,
                            _0: {
                              hd: {
                                TAG: /* Administrator */0,
                                _0: /* France */0
                              },
                              tl: {
                                hd: {
                                  TAG: /* Administrator */0,
                                  _0: /* Spain */1
                                },
                                tl: {
                                  hd: /* CompanyManager */2,
                                  tl: {
                                    hd: /* LldManager */5,
                                    tl: {
                                      hd: /* BigAccountManager */6,
                                      tl: {
                                        hd: /* AcountManager */4,
                                        tl: /* [] */0
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          },
                          children: React.createElement(ModelVersion_Grid_Manage_Detail$DvmAdminFrontendFr.make, {})
                        });
                  break;
              default:
                pathsForSubsidiary = pathsForAdmin;
            }
          } else {
            pathsForSubsidiary = pathsForAdmin;
          }
          break;
      case "view" :
          var match$18 = match$16.tl;
          if (match$18 && match$18.hd === "order") {
            var match$19 = match$18.tl;
            if (match$19) {
              var match$20 = match$19.tl;
              pathsForSubsidiary = match$20 && match$20.hd === "correct" && !match$20.tl ? React.createElement(Route_Protected$DvmAdminFrontendFr.make, {
                      roles: {
                        TAG: /* Access */0,
                        _0: {
                          hd: {
                            TAG: /* Administrator */0,
                            _0: /* France */0
                          },
                          tl: {
                            hd: {
                              TAG: /* Administrator */0,
                              _0: /* Spain */1
                            },
                            tl: {
                              hd: /* Advisor */0,
                              tl: /* [] */0
                            }
                          }
                        }
                      },
                      children: React.createElement(Order_Detail_Correct_Page$DvmAdminFrontendFr.make, {
                            orderId: match$19.hd,
                            action: "correct"
                          })
                    }) : pathsForAdmin;
            } else {
              pathsForSubsidiary = pathsForAdmin;
            }
          } else {
            pathsForSubsidiary = pathsForAdmin;
          }
          break;
      default:
        pathsForSubsidiary = pathsForAdmin;
    }
  } else {
    pathsForSubsidiary = pathsForAdmin;
  }
  var match$21 = url.path;
  var pathsForEveryone;
  if (match$21) {
    switch (match$21.hd) {
      case "selectRole" :
          pathsForEveryone = match$21.tl ? pathsForSubsidiary : React.createElement(Auth_Select_Role_Page$DvmAdminFrontendFr.make, {});
          break;
      case "view" :
          var match$22 = match$21.tl;
          if (match$22) {
            switch (match$22.hd) {
              case "actionReferential" :
                  pathsForEveryone = match$22.tl ? pathsForSubsidiary : React.createElement(Action_Referential_Page$DvmAdminFrontendFr.make, {
                          operation: /* View */0,
                          key: "view"
                        });
                  break;
              case "anomalies" :
                  pathsForEveryone = match$22.tl ? pathsForSubsidiary : React.createElement(Bonus_Anomalies_Search_Page$DvmAdminFrontendFr.make, {});
                  break;
              case "attachments" :
                  pathsForEveryone = match$22.tl ? pathsForSubsidiary : React.createElement(Route_Protected$DvmAdminFrontendFr.make, {
                          roles: {
                            TAG: /* Block */1,
                            _0: {
                              hd: /* MRR */8,
                              tl: /* [] */0
                            }
                          },
                          children: React.createElement(Contract_Search_Page$DvmAdminFrontendFr.make, {
                                tableType: /* Attachment */1,
                                operation: /* View */0,
                                key: "attachments"
                              })
                        });
                  break;
              case "bonuses" :
                  pathsForEveryone = match$22.tl ? pathsForSubsidiary : React.createElement(Bonuses_Search_Page$DvmAdminFrontendFr.make, {
                          operation: /* View */1,
                          key: "view"
                        });
                  break;
              case "category" :
                  pathsForEveryone = match$22.tl ? pathsForSubsidiary : React.createElement(Category_Search$DvmAdminFrontendFr.make, {
                          manage: false,
                          key: "view"
                        });
                  break;
              case "company" :
                  var match$23 = match$22.tl;
                  pathsForEveryone = match$23 ? (
                      match$23.tl ? pathsForSubsidiary : React.createElement(Route_Protected$DvmAdminFrontendFr.make, {
                              roles: {
                                TAG: /* Block */1,
                                _0: {
                                  hd: /* MRR */8,
                                  tl: /* [] */0
                                }
                              },
                              children: React.createElement(Company_Detail_Page$DvmAdminFrontendFr.make, {
                                    siret: match$23.hd
                                  })
                            })
                    ) : React.createElement(Route_Protected$DvmAdminFrontendFr.make, {
                          roles: {
                            TAG: /* Block */1,
                            _0: {
                              hd: /* MRR */8,
                              tl: /* [] */0
                            }
                          },
                          children: React.createElement(Company_Search_Page$DvmAdminFrontendFr.make, {})
                        });
                  break;
              case "companyVirtual" :
                  pathsForEveryone = match$22.tl ? pathsForSubsidiary : React.createElement(Route_Protected$DvmAdminFrontendFr.make, {
                          roles: {
                            TAG: /* Block */1,
                            _0: {
                              hd: /* MRR */8,
                              tl: /* [] */0
                            }
                          },
                          children: React.createElement(Company_Virtual_Search_Page$DvmAdminFrontendFr.make, {
                                edit: false
                              })
                        });
                  break;
              case "contract" :
                  var match$24 = match$22.tl;
                  if (match$24) {
                    var match$25 = match$24.tl;
                    pathsForEveryone = match$25 && !match$25.tl ? React.createElement(Route_Protected$DvmAdminFrontendFr.make, {
                            roles: {
                              TAG: /* Block */1,
                              _0: {
                                hd: /* MRR */8,
                                tl: /* [] */0
                              }
                            },
                            children: React.createElement(Contract_Edit_Page$DvmAdminFrontendFr.make, {
                                  contractId: match$24.hd,
                                  startDate: match$25.hd,
                                  operation: /* View */0,
                                  key: "view"
                                })
                          }) : pathsForSubsidiary;
                  } else {
                    pathsForEveryone = pathsForSubsidiary;
                  }
                  break;
              case "contractManager" :
                  pathsForEveryone = match$22.tl ? pathsForSubsidiary : React.createElement(Route_Protected$DvmAdminFrontendFr.make, {
                          roles: {
                            TAG: /* Block */1,
                            _0: {
                              hd: /* MRR */8,
                              tl: /* [] */0
                            }
                          },
                          children: React.createElement(Contract_Manager_Search_Page$DvmAdminFrontendFr.make, {
                                operation: /* View */0,
                                key: "view"
                              })
                        });
                  break;
              case "contracts" :
                  pathsForEveryone = match$22.tl ? pathsForSubsidiary : React.createElement(Route_Protected$DvmAdminFrontendFr.make, {
                          roles: {
                            TAG: /* Block */1,
                            _0: {
                              hd: /* MRR */8,
                              tl: /* [] */0
                            }
                          },
                          children: React.createElement(Contract_Search_Page$DvmAdminFrontendFr.make, {
                                tableType: /* Contract */0,
                                operation: /* View */0,
                                key: "view"
                              })
                        });
                  break;
              case "dealer" :
                  var match$26 = match$22.tl;
                  if (match$26) {
                    var match$27 = match$26.tl;
                    pathsForEveryone = match$27 && !match$27.tl ? React.createElement(Dealer_Detail_Page$DvmAdminFrontendFr.make, {
                            dealerId: match$26.hd,
                            startDate: App_Types_Date$DvmAdminFrontendFr.fromString(match$27.hd)
                          }) : pathsForSubsidiary;
                  } else {
                    pathsForEveryone = React.createElement(Dealer_Search_Page$DvmAdminFrontendFr.make, {});
                  }
                  break;
              case "flash" :
                  var match$28 = match$22.tl;
                  if (match$28) {
                    var match$29 = match$28.tl;
                    var flashType$1 = match$28.hd;
                    if (match$29) {
                      if (match$29.tl) {
                        pathsForEveryone = pathsForSubsidiary;
                      } else {
                        var flashId = match$29.hd;
                        var tmp$2 = flashType$1 === "nonfleet" ? /* NonFleet */1 : /* Fleet */0;
                        pathsForEveryone = React.createElement(Flash_Detail_Page$DvmAdminFrontendFr.make, {
                              flashId: flashId,
                              flashType: tmp$2,
                              key: "viewDetail" + flashId
                            });
                      }
                    } else {
                      pathsForEveryone = React.createElement(Flash_Detail_Page$DvmAdminFrontendFr.make, {
                            flashId: flashType$1,
                            flashType: /* Fleet */0,
                            key: "viewDetail" + flashType$1
                          });
                    }
                  } else {
                    pathsForEveryone = React.createElement(Flash_Search_Page$DvmAdminFrontendFr.make, {
                          operation: /* View */0,
                          key: "view"
                        });
                  }
                  break;
              case "modelVersionGrid" :
                  pathsForEveryone = match$22.tl ? pathsForSubsidiary : React.createElement(Route_Protected$DvmAdminFrontendFr.make, {
                          roles: {
                            TAG: /* Block */1,
                            _0: {
                              hd: /* MRR */8,
                              tl: /* [] */0
                            }
                          },
                          children: React.createElement(ModelVersion_Grid_Page$DvmAdminFrontendFr.make, {})
                        });
                  break;
              case "models" :
                  pathsForEveryone = match$22.tl ? pathsForSubsidiary : React.createElement(Model_Version_Page$DvmAdminFrontendFr.make, {
                          allowEdit: false
                        });
                  break;
              case "order" :
                  var match$30 = match$22.tl;
                  if (match$30) {
                    var match$31 = match$30.tl;
                    var orderId = match$30.hd;
                    pathsForEveryone = match$31 ? (
                        match$31.hd === "history" && !match$31.tl ? React.createElement(Order_Detail_Correct_Page$DvmAdminFrontendFr.make, {
                                orderId: orderId,
                                action: "history"
                              }) : pathsForSubsidiary
                      ) : React.createElement(Order_Detail$DvmAdminFrontendFr.make, {
                            orderId: orderId,
                            key: orderId
                          });
                  } else {
                    pathsForEveryone = React.createElement(Order_Search_Page$DvmAdminFrontendFr.make, {
                          key: "view"
                        });
                  }
                  break;
              case "pcdAgreement" :
                  pathsForEveryone = match$22.tl ? pathsForSubsidiary : React.createElement(Route_Protected$DvmAdminFrontendFr.make, {
                          roles: {
                            TAG: /* Block */1,
                            _0: {
                              hd: /* MRR */8,
                              tl: /* [] */0
                            }
                          },
                          children: React.createElement(PCDAgreement_Search_Page$DvmAdminFrontendFr.make, {
                                operation: /* View */0,
                                key: "view"
                              })
                        });
                  break;
              case "quota" :
                  pathsForEveryone = match$22.tl ? pathsForSubsidiary : React.createElement(Quota_View_Page$DvmAdminFrontendFr.make, {});
                  break;
              case "quotaPARC" :
                  pathsForEveryone = match$22.tl ? pathsForSubsidiary : React.createElement(Route_Protected$DvmAdminFrontendFr.make, {
                          roles: {
                            TAG: /* Block */1,
                            _0: {
                              hd: /* MRR */8,
                              tl: /* [] */0
                            }
                          },
                          children: React.createElement(Quota_PARC_Search_Page$DvmAdminFrontendFr.make, {
                                pageType: /* View */0,
                                key: "view"
                              })
                        });
                  break;
              default:
                pathsForEveryone = pathsForSubsidiary;
            }
          } else {
            pathsForEveryone = pathsForSubsidiary;
          }
          break;
      default:
        pathsForEveryone = pathsForSubsidiary;
    }
  } else {
    pathsForEveryone = React.createElement(Home$DvmAdminFrontendFr.make, {});
  }
  if (!(selectedRole === /* Dealer */7 || User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole))) {
    return pathsForEveryone;
  }
  if (typeof dealerInfo === "number") {
    return null;
  }
  if (dealerInfo.TAG === /* Ok */0) {
    if (Belt_Option.isSome(dealerInfo._0.dealerInfo)) {
      return pathsForEveryone;
    }
    var match$32 = url.path;
    if (match$32 && match$32.hd === "selectRole" && !match$32.tl) {
      return React.createElement(Auth_Select_Role_Page$DvmAdminFrontendFr.make, {});
    }
    return React.createElement(Route_Switch$DealerNotFound, {});
  }
  var match$33 = url.path;
  if (match$33 && match$33.hd === "selectRole" && !match$33.tl) {
    return React.createElement(Auth_Select_Role_Page$DvmAdminFrontendFr.make, {});
  }
  return React.createElement(Route_Switch$DealerNotFound, {});
}

var make = Route_Switch;

export {
  PageNotFound ,
  DealerNotFound ,
  make ,
}
/* react Not a pure module */
