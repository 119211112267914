// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as Utils_Date$DvmAdminFrontendFr from "../../common/utils/Utils_Date.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../common/types/App_Types_Result.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../context/user/User_Types_Utils.bs.js";
import * as Bonuses_Search_Api$DvmAdminFrontendFr from "./Bonuses_Search_Api.bs.js";

function orderTypesIncludeFleet(orderTypes) {
  return Belt_List.has(orderTypes, "SO", (function (a, b) {
                return a === b;
              }));
}

function orderTypesIncludeNonFleet(orderTypes) {
  return Belt_Option.isSome(Belt_List.getBy(orderTypes, (function (x) {
                    switch (x) {
                      case "CL" :
                      case "EP" :
                      case "ES" :
                      case "PG" :
                      case "RC" :
                      case "RR" :
                      case "VD" :
                      case "VE" :
                          return true;
                      default:
                        return false;
                    }
                  })));
}

function updateNonFleetActionsAndModels(actionTypesNonFleet, operation, form, alert, userManager, setActionsNonFleet, setModelsNonFleet, selectedRole) {
  if (typeof actionTypesNonFleet === "number") {
    return ;
  }
  if (actionTypesNonFleet.TAG !== /* Ok */0) {
    return ;
  }
  if (!(operation !== /* Manage */0 && !orderTypesIncludeFleet(form.input.orderType))) {
    return ;
  }
  var actionTypes = form.input.actionType.length > 0 ? form.input.actionType : Belt_Array.joinWith(Belt_Array.keep(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.map(Belt_List.toArray(actionTypesNonFleet._0), (function (x) {
                            return x.toUpperCase();
                          })))), (function (x) {
                return x.length > 0;
              })), "%5C,", (function (x) {
            return x;
          }));
  Bonuses_Search_Api$DvmAdminFrontendFr.getActions(alert, userManager, setActionsNonFleet, selectedRole, actionTypes, Utils_Date$DvmAdminFrontendFr.toYearString(form.input.budgetYear), form.input.showBonusesPlusHistory, "nonfleet", undefined);
  Bonuses_Search_Api$DvmAdminFrontendFr.fetchModelsVersionsFiltered(setModelsNonFleet, userManager, selectedRole, Utils_Date$DvmAdminFrontendFr.toYearString(form.input.budgetYear), form.input.showBonusesPlusHistory, actionTypes, "nonfleet", "model", undefined, undefined);
}

function updateNonFleetVersions(actionTypesNonFleet, operation, form, userManager, setVersionsNonFleet, selectedRole) {
  if (typeof actionTypesNonFleet === "number") {
    return ;
  }
  if (actionTypesNonFleet.TAG !== /* Ok */0) {
    return ;
  }
  if (!(operation !== /* Manage */0 && !orderTypesIncludeFleet(form.input.orderType))) {
    return ;
  }
  var actionTypes = form.input.actionType.length > 0 ? form.input.actionType : Belt_Array.joinWith(Belt_Array.keep(Belt_SetString.toArray(Belt_SetString.fromArray(Belt_Array.map(Belt_List.toArray(actionTypesNonFleet._0), (function (x) {
                            return x.toUpperCase();
                          })))), (function (x) {
                return x.length > 0;
              })), "%5C,", (function (x) {
            return x;
          }));
  Bonuses_Search_Api$DvmAdminFrontendFr.fetchModelsVersionsFiltered(setVersionsNonFleet, userManager, selectedRole, Utils_Date$DvmAdminFrontendFr.toYearString(form.input.budgetYear), form.input.showBonusesPlusHistory, actionTypes, "nonfleet", "version", form.input.model, undefined);
}

function handleActionTypesList(form, actionTypesFleet, actionTypesNonFleet, selectedRole) {
  var actionsFleet = orderTypesIncludeFleet(form.input.orderType) ? App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesFleet, /* [] */0) : /* [] */0;
  var actionsNonFleet = orderTypesIncludeNonFleet(form.input.orderType) ? App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesNonFleet, /* [] */0) : /* [] */0;
  var actions = Belt_List.keep(Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.map(Belt_List.length(form.input.orderType) > 0 ? Belt_List.concat(actionsFleet, actionsNonFleet) : (
                          User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? Belt_List.concat(App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesFleet, /* [] */0), App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesNonFleet, /* [] */0)) : App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionTypesNonFleet, /* [] */0)
                        ), (function (x) {
                          return x.toUpperCase();
                        }))))), (function (x) {
          return x.length > 0;
        }));
  if (orderTypesIncludeFleet(form.input.orderType) || Belt_List.length(form.input.orderType) === 0 && User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0) {
    return Belt_List.add(actions, "Budget");
  } else {
    return actions;
  }
}

function handleActionsDropdownList(form, actionsFleet, actionsNonFleet) {
  var actionsFleetCleaned = orderTypesIncludeFleet(form.input.orderType) ? App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionsFleet, /* [] */0) : /* [] */0;
  var actionsNonFleetCleaned = orderTypesIncludeNonFleet(form.input.orderType) ? App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionsNonFleet, /* [] */0) : /* [] */0;
  return Belt_List.map(Belt_List.length(form.input.orderType) > 0 ? Belt_List.concat(actionsFleetCleaned, actionsNonFleetCleaned) : Belt_List.concat(App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionsFleet, /* [] */0), App_Types_Result$DvmAdminFrontendFr.getWithDefault(actionsNonFleet, /* [] */0)), (function (x) {
                return {
                        label: {
                          TAG: /* String */3,
                          _0: "" + x.paramOne + " (" + Belt_Option.getWithDefault(x.paramTwo, "") + ")"
                        },
                        value: x.paramOne
                      };
              }));
}

function handleModelsVersionsList(form, listFleet, listNonFleet) {
  var listFleetCleaned = orderTypesIncludeFleet(form.input.orderType) ? App_Types_Result$DvmAdminFrontendFr.getWithDefault(listFleet, /* [] */0) : /* [] */0;
  var listNonFleetCleaned = orderTypesIncludeNonFleet(form.input.orderType) ? App_Types_Result$DvmAdminFrontendFr.getWithDefault(listNonFleet, /* [] */0) : /* [] */0;
  return Belt_SetString.toList(Belt_SetString.fromArray(Belt_List.toArray(Belt_List.length(form.input.orderType) > 0 ? Belt_List.concat(listFleetCleaned, listNonFleetCleaned) : Belt_List.concat(App_Types_Result$DvmAdminFrontendFr.getWithDefault(listFleet, /* [] */0), App_Types_Result$DvmAdminFrontendFr.getWithDefault(listNonFleet, /* [] */0)))));
}

function filteredSalesTypes(x, form) {
  return Belt_List.keep(x, (function (x) {
                var salesOne = Belt_List.has(form.input.clientTypes, "1", (function (a, b) {
                        return a === b;
                      })) ? Belt_List.has({
                        hd: "00",
                        tl: {
                          hd: "82",
                          tl: {
                            hd: "83",
                            tl: {
                              hd: "84",
                              tl: {
                                hd: "86",
                                tl: {
                                  hd: "90",
                                  tl: {
                                    hd: "91",
                                    tl: {
                                      hd: "92",
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }, x, (function (a, b) {
                          return a === b;
                        })) : false;
                var salesThree = Belt_List.has(form.input.clientTypes, "3", (function (a, b) {
                        return a === b;
                      })) ? Belt_List.has({
                        hd: "97",
                        tl: {
                          hd: "98",
                          tl: {
                            hd: "99",
                            tl: /* [] */0
                          }
                        }
                      }, x, (function (a, b) {
                          return a === b;
                        })) : false;
                if (Belt_List.length(form.input.clientTypes) > 0 && !salesOne) {
                  return salesThree;
                } else {
                  return true;
                }
              }));
}

function cleanSalesTypes(saleTypes, form) {
  return App_Types_Result$DvmAdminFrontendFr.mapWithDefault(saleTypes, /* [] */0, (function (x) {
                return Belt_List.map(Belt_List.keep(Belt_List.keep(x, (function (x) {
                                      return x.paramOne.length > 0;
                                    })), (function (x) {
                                  var salesOne = Belt_List.has(form.input.clientTypes, "1", (function (a, b) {
                                          return a === b;
                                        })) ? Belt_List.has({
                                          hd: "00",
                                          tl: {
                                            hd: "82",
                                            tl: {
                                              hd: "83",
                                              tl: {
                                                hd: "84",
                                                tl: {
                                                  hd: "86",
                                                  tl: {
                                                    hd: "90",
                                                    tl: {
                                                      hd: "91",
                                                      tl: {
                                                        hd: "92",
                                                        tl: /* [] */0
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }, x.paramOne, (function (a, b) {
                                            return a === b;
                                          })) : false;
                                  var salesThree = Belt_List.has(form.input.clientTypes, "3", (function (a, b) {
                                          return a === b;
                                        })) ? Belt_List.has({
                                          hd: "97",
                                          tl: {
                                            hd: "98",
                                            tl: {
                                              hd: "99",
                                              tl: /* [] */0
                                            }
                                          }
                                        }, x.paramOne, (function (a, b) {
                                            return a === b;
                                          })) : false;
                                  if (Belt_List.length(form.input.clientTypes) > 0 && !salesOne) {
                                    return salesThree;
                                  } else {
                                    return true;
                                  }
                                })), (function (x) {
                              return {
                                      label: {
                                        TAG: /* String */3,
                                        _0: x.paramOne + Belt_Option.mapWithDefault(x.paramTwo, "", (function (label) {
                                                return " " + label + "";
                                              }))
                                      },
                                      value: x.paramOne
                                    };
                            }));
              }));
}

var Form;

var Api;

var Types;

export {
  Form ,
  Api ,
  Types ,
  orderTypesIncludeFleet ,
  orderTypesIncludeNonFleet ,
  updateNonFleetActionsAndModels ,
  updateNonFleetVersions ,
  handleActionTypesList ,
  handleActionsDropdownList ,
  handleModelsVersionsList ,
  filteredSalesTypes ,
  cleanSalesTypes ,
}
/* Utils_Date-DvmAdminFrontendFr Not a pure module */
