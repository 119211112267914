// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Belt_Int from "rescript/lib/es6/belt_Int.js";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Container$Mui from "rescript-material-ui/src/Container.bs.js";
import * as Core from "@material-ui/core";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../intl/Intl_Utils.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../theme/Theme_Colors.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../../context/user/User_Context.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../intl/messages/Messages_Common.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../../context/user/User_Types_Utils.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../common/styles/App_Styles_Common.bs.js";
import * as App_SelectFormInput$DvmAdminFrontendFr from "../../../../common/components/input/App_SelectFormInput.bs.js";
import * as Messages_Orders_Correction$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Correction.bs.js";
import * as Order_Detail_Correct_Perfs_History_Content$DvmAdminFrontendFr from "./views/perfs/Order_Detail_Correct_Perfs_History_Content.bs.js";
import * as Order_Detail_Correct_Vehicle_History_Content$DvmAdminFrontendFr from "./views/vehicle/Order_Detail_Correct_Vehicle_History_Content.bs.js";
import * as Order_Detail_Correct_Order_History_Content_Fleet$DvmAdminFrontendFr from "./views/order/Order_Detail_Correct_Order_History_Content_Fleet.bs.js";
import * as Order_Detail_Correct_Order_History_Content_NonFleet$DvmAdminFrontendFr from "./views/order/Order_Detail_Correct_Order_History_Content_NonFleet.bs.js";

function Order_Detail_Correct_History(Props) {
  var corrections = Props.corrections;
  var order = Props.order;
  var form = Props.form;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match[0].selectedRole;
  var match$1 = React.useState(function () {
        return "0";
      });
  var setSelectedIndex = match$1[1];
  var selectedIndex = match$1[0];
  var sortedCorrections = Belt_List.sort(Belt_List.keep(corrections, (function (x) {
              return Belt_Option.getWithDefault(x.editor, "") !== "INITIAL";
            })), (function (a, b) {
          return ReDate.compareDesc(Belt_Option.getWithDefault(a.timeStamp, new Date(0.0)), Belt_Option.getWithDefault(b.timeStamp, new Date(0.0)));
        }));
  var initialCorrection = Belt_List.getBy(corrections, (function (x) {
          return Belt_Option.getWithDefault(x.editor, "") === "INITIAL";
        }));
  var correction = Belt_List.get(sortedCorrections, Belt_Option.getWithDefault(Belt_Int.fromString(selectedIndex), 0));
  var initialCorrectionShow = Belt_Option.mapWithDefault(initialCorrection, false, (function (x) {
          if (Belt_Option.getWithDefault(x.usedCarPoliceNumber, "").length > 0 || Belt_Option.getWithDefault(x.usedCarRegistrationID, "").length > 0 || Belt_Option.mapWithDefault(x.usedCarLastRegistrationDate, false, App_Types_Date$DvmAdminFrontendFr.isValid) || Belt_Option.mapWithDefault(x.usedCarFirstReleaseDate, false, App_Types_Date$DvmAdminFrontendFr.isValid) || Belt_Option.getWithDefault(x.brandUsedCar, "").length > 0 || Belt_Option.getWithDefault(x.modelUsedCar, "").length > 0 || Belt_Option.getWithDefault(x.versionUsedCar, "").length > 0) {
            return true;
          } else {
            return Belt_Option.getWithDefault(x.vinUsedCar, "").length > 0;
          }
        }));
  var correctionShow = Belt_Option.mapWithDefault(correction, false, (function (x) {
          if (Belt_Option.getWithDefault(x.usedCarPoliceNumber, "").length > 0 || Belt_Option.getWithDefault(x.usedCarRegistrationID, "").length > 0 || Belt_Option.mapWithDefault(x.usedCarLastRegistrationDate, false, App_Types_Date$DvmAdminFrontendFr.isValid) || Belt_Option.mapWithDefault(x.usedCarFirstReleaseDate, false, App_Types_Date$DvmAdminFrontendFr.isValid) || Belt_Option.getWithDefault(x.brandUsedCar, "").length > 0 || Belt_Option.getWithDefault(x.modelUsedCar, "").length > 0 || Belt_Option.getWithDefault(x.versionUsedCar, "").length > 0) {
            return true;
          } else {
            return Belt_Option.getWithDefault(x.vinUsedCar, "").length > 0;
          }
        }));
  var showVehicle = initialCorrectionShow || correctionShow;
  var tmp;
  if (initialCorrection !== undefined && correction !== undefined) {
    var match$2 = form.input.orderType;
    var match$3 = form.input.orderType;
    tmp = React.createElement(Core.Grid, {
          children: null,
          container: true,
          direction: "column",
          spacing: 5
        }, React.createElement(Core.Grid, {
              children: match$2 !== 0 ? (
                  match$2 >= 9 ? null : React.createElement(React.Fragment, undefined, React.createElement(Order_Detail_Correct_Order_History_Content_NonFleet$DvmAdminFrontendFr.make, {
                              correction: initialCorrection,
                              color: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                              title: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.initialValues),
                              order: order
                            }), React.createElement(Order_Detail_Correct_Order_History_Content_NonFleet$DvmAdminFrontendFr.make, {
                              correction: correction,
                              color: Theme_Colors$DvmAdminFrontendFr.secondaryDarkGreenDark,
                              title: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.correctionsDateTitle, {
                                    date: Belt_Option.mapWithDefault(correction.timeStamp, "", (function (param) {
                                            return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                          }))
                                  }),
                              order: order
                            }))
                ) : React.createElement(React.Fragment, undefined, React.createElement(Order_Detail_Correct_Order_History_Content_Fleet$DvmAdminFrontendFr.make, {
                          correction: initialCorrection,
                          color: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                          title: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.initialValues)
                        }), React.createElement(Order_Detail_Correct_Order_History_Content_Fleet$DvmAdminFrontendFr.make, {
                          correction: correction,
                          color: Theme_Colors$DvmAdminFrontendFr.secondaryDarkGreenDark,
                          title: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.correctionsDateTitle, {
                                date: Belt_Option.mapWithDefault(correction.timeStamp, "", (function (param) {
                                        return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                      }))
                              })
                        })),
              container: true,
              item: true,
              spacing: 2
            }), match$3 === 8 || match$3 === 7 ? null : React.createElement(Core.Grid, {
                children: null,
                container: true,
                item: true,
                spacing: 2
              }, React.createElement(Order_Detail_Correct_Perfs_History_Content$DvmAdminFrontendFr.make, {
                    correction: initialCorrection,
                    color: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                    title: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.initialValuesPerf)
                  }), React.createElement(Order_Detail_Correct_Perfs_History_Content$DvmAdminFrontendFr.make, {
                    correction: correction,
                    color: Theme_Colors$DvmAdminFrontendFr.secondaryDarkGreenDark,
                    title: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.correctionsDateTitle, {
                          date: Belt_Option.mapWithDefault(correction.timeStamp, "", (function (param) {
                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                }))
                        })
                  })), showVehicle ? React.createElement(Core.Grid, {
                children: null,
                container: true,
                item: true,
                spacing: 2
              }, React.createElement(Order_Detail_Correct_Vehicle_History_Content$DvmAdminFrontendFr.make, {
                    correction: initialCorrection,
                    color: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                    title: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.initialValues),
                    form: form
                  }), React.createElement(Order_Detail_Correct_Vehicle_History_Content$DvmAdminFrontendFr.make, {
                    correction: correction,
                    color: Theme_Colors$DvmAdminFrontendFr.secondaryDarkGreenDark,
                    title: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.correctionCarDateValues, {
                          date: Belt_Option.mapWithDefault(correction.timeStamp, "", (function (param) {
                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                }))
                        }),
                    form: form
                  })) : null);
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Core.Container, {
                  children: React.createElement(Core.Grid, {
                        children: null,
                        container: true,
                        direction: "column",
                        spacing: 5
                      }, React.createElement(Core.Grid, {
                            children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                  color: "primary",
                                  style: {
                                    fontWeight: "600"
                                  },
                                  children: "" + intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.titleCorrectionHistory) + " " + Belt_Option.getWithDefault(order.orderNumberCustomer, "-") + "",
                                  variant: "h3"
                                }),
                            item: true
                          }), React.createElement(Core.Container, {
                            children: null,
                            maxWidth: Container$Mui.MaxWidth.sm
                          }, React.createElement(Core.Grid, {
                                children: null,
                                container: true,
                                direction: "column",
                                spacing: 2
                              }, React.createElement(Core.Grid, {
                                    children: React.createElement(App_SelectFormInput$DvmAdminFrontendFr.make, {
                                          label: Messages_Orders_Correction$DvmAdminFrontendFr.correctionsSelector,
                                          options: Belt_List.mapWithIndex(sortedCorrections, (function (index, x) {
                                                  return {
                                                          label: {
                                                            TAG: /* String */3,
                                                            _0: Belt_Option.mapWithDefault(x.timeStamp, "", (function (param) {
                                                                    return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                                  })) + (
                                                              selectedRole !== /* Dealer */7 || !User_Types_Utils$DvmAdminFrontendFr.isAdminDealer(selectedRole) ? " par " + Belt_Option.getWithDefault(x.editor, "") : ""
                                                            )
                                                          },
                                                          value: String(index)
                                                        };
                                                })),
                                          value: {
                                            TAG: /* Single */0,
                                            _0: {
                                              value: selectedIndex,
                                              onChange: (function (value) {
                                                  Curry._1(setSelectedIndex, (function (param) {
                                                          return value;
                                                        }));
                                                })
                                            }
                                          },
                                          id: "corrections",
                                          className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                                        }),
                                    item: true
                                  }), React.createElement(Core.Grid, {
                                    children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                          color: "primary",
                                          style: {
                                            fontWeight: "600"
                                          },
                                          children: "" + intl.formatMessage(Messages_Common$DvmAdminFrontendFr.comment) + ": " + Belt_Option.mapWithDefault(correction, "", (function (x) {
                                                  return Belt_Option.getWithDefault(x.comment, "");
                                                })) + "",
                                          variant: "bodyBig"
                                        }),
                                    item: true
                                  })), tmp)),
                  maxWidth: Container$Mui.MaxWidth.md
                }));
}

var Form;

var Types;

var InputStyles;

var make = Order_Detail_Correct_History;

export {
  Form ,
  Types ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
