// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ReactAlert from "react-alert";
import * as Core from "@material-ui/core";
import * as App_Api$DvmAdminFrontendFr from "../../../common/App_Api.bs.js";
import * as App_Checkbox$DvmAdminFrontendFr from "../../../common/components/App_Checkbox.bs.js";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../intl/messages/Messages_Common.bs.js";
import * as App_Types_Result$DvmAdminFrontendFr from "../../../common/types/App_Types_Result.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as Dealer_Search_Api$DvmAdminFrontendFr from "./Dealer_Search_Api.bs.js";
import * as App_OverlaySpinner$DvmAdminFrontendFr from "../../../common/components/App_OverlaySpinner.bs.js";
import * as Dealer_Search_Form$DvmAdminFrontendFr from "./Dealer_Search_Form.bs.js";
import * as Messages_Dealer_List$DvmAdminFrontendFr from "../../../intl/messages/dealer/Messages_Dealer_List.bs.js";
import * as App_TextFieldFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_TextFieldFormInput.bs.js";
import * as App_AutocompleteFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_AutocompleteFormInput.bs.js";
import * as App_DoubleSelectFormInput$DvmAdminFrontendFr from "../../../common/components/input/App_DoubleSelectFormInput.bs.js";
import * as Messages_Bonuses_Anomalies$DvmAdminFrontendFr from "../../../intl/messages/bonuses/Messages_Bonuses_Anomalies.bs.js";

function Dealer_Search_Filter(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var setShowHistory = Props.setShowHistory;
  var intl = ReactIntl.useIntl();
  var match = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match[1];
  var alert = ReactAlert.useAlert();
  var match$1 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var match$2 = match$1[0];
  var userManager = match$2.userManager;
  var selectedRole = match$2.selectedRole;
  var match$3 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setDrAndDealers = match$3[1];
  var drAndDealers = match$3[0];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setLocations = match$4[1];
  var match$5 = React.useState(function () {
        return /* NotStarted */0;
      });
  var setManagers = match$5[1];
  var managers = match$5[0];
  var form = Dealer_Search_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(match[0].dealersFilter, Dealer_Search_Form$DvmAdminFrontendFr.initialState), (function (param, submissionCallbacks) {
          Curry._2(fetchData, submissionCallbacks, {
                page: 0,
                rowsPerPage: fetchRequest.rowsPerPage,
                headers: fetchRequest.headers,
                filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap({
                              hd: param.dr,
                              tl: {
                                hd: param.dealerId,
                                tl: {
                                  hd: param.dealerName,
                                  tl: {
                                    hd: param.keyAccountManager,
                                    tl: {
                                      hd: param.city,
                                      tl: /* [] */0
                                    }
                                  }
                                }
                              }
                            }, (function (queryParam) {
                                return queryParam;
                              }))), ";", (function (queryParam) {
                        return queryParam;
                      })),
                additionalQueryParams: fetchRequest.additionalQueryParams
              });
        }));
  React.useEffect((function () {
          Dealer_Search_Api$DvmAdminFrontendFr.queryForLocalitySelect(alert, userManager, setLocations, selectedRole);
          Dealer_Search_Api$DvmAdminFrontendFr.queryForManagerSelect(alert, userManager, setManagers, selectedRole);
          Curry._1(form.submit, undefined);
        }), []);
  React.useEffect((function () {
          App_Api$DvmAdminFrontendFr.queryForDRAndDealers(alert, userManager, setDrAndDealers, form.input.showHistory, selectedRole);
          Curry._1(setShowHistory, (function (param) {
                  return form.input.showHistory;
                }));
        }), [form.input.showHistory]);
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  Curry._1(dispatch, {
                        TAG: /* StoreDealersFilter */7,
                        _0: form.input
                      });
                  Curry._1(form.submit, undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                      show: App_Types_Result$DvmAdminFrontendFr.isPending(drAndDealers),
                      children: React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            direction: "column",
                            item: true,
                            style: {
                              width: "fit-content"
                            }
                          }, React.createElement(Core.Grid, {
                                children: React.createElement(App_DoubleSelectFormInput$DvmAdminFrontendFr.make, {
                                      labelParent: Messages_Common$DvmAdminFrontendFr.territory,
                                      labelChild: Messages_Common$DvmAdminFrontendFr.dealerID,
                                      valueParent: {
                                        TAG: /* Multiple */1,
                                        _0: {
                                          value: Belt_List.keep(form.input.dr, (function (x) {
                                                  return Belt_List.some(App_Types_Result$DvmAdminFrontendFr.getWithDefault(drAndDealers, /* [] */0), (function (dr) {
                                                                return dr.parent === x;
                                                              }));
                                                })),
                                          onChange: Curry._1(form.updateDr, (function (input, dr) {
                                                  return {
                                                          dr: dr,
                                                          dealerId: input.dealerId,
                                                          dealerName: input.dealerName,
                                                          keyAccountManager: input.keyAccountManager,
                                                          city: input.city,
                                                          showHistory: input.showHistory
                                                        };
                                                }))
                                        }
                                      },
                                      valueChild: {
                                        TAG: /* Multiple */1,
                                        _0: {
                                          value: Belt_List.keep(form.input.dealerId, (function (x) {
                                                  return Belt_List.some(Belt_List.flatten(Belt_List.map(App_Types_Result$DvmAdminFrontendFr.getWithDefault(drAndDealers, /* [] */0), (function (x) {
                                                                        return x.child;
                                                                      }))), (function (dr) {
                                                                return dr === x;
                                                              }));
                                                })),
                                          onChange: Curry._1(form.updateDealerId, (function (input, dealerId) {
                                                  return {
                                                          dr: input.dr,
                                                          dealerId: dealerId,
                                                          dealerName: input.dealerName,
                                                          keyAccountManager: input.keyAccountManager,
                                                          city: input.city,
                                                          showHistory: input.showHistory
                                                        };
                                                }))
                                        }
                                      },
                                      options: App_Types_Result$DvmAdminFrontendFr.getWithDefault(drAndDealers, /* [] */0),
                                      classNameParent: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInput,
                                      classNameChild: App_Styles_Common$DvmAdminFrontendFr.Inputs.chipInputLong,
                                      limitTagsParent: 2,
                                      limitTagsChild: 2,
                                      selectParentIfOnlyOne: true,
                                      selectChildIfOnlyOne: true
                                    }),
                                item: true
                              }), React.createElement(Core.Grid, {
                                children: React.createElement(Core.FormControlLabel, {
                                      classes: {
                                        label: App_Styles_Common$DvmAdminFrontendFr.Inputs.filterCheckboxLabel
                                      },
                                      control: React.createElement(App_Checkbox$DvmAdminFrontendFr.make, {
                                            checked: form.input.showHistory,
                                            onClick: (function ($$event) {
                                                Curry._2(form.updateShowHistory, (function (input, showHistory) {
                                                        return {
                                                                dr: input.dr,
                                                                dealerId: input.dealerId,
                                                                dealerName: input.dealerName,
                                                                keyAccountManager: input.keyAccountManager,
                                                                city: input.city,
                                                                showHistory: showHistory
                                                              };
                                                      }), $$event.target.checked);
                                              })
                                          }),
                                      label: intl.formatMessage(Messages_Common$DvmAdminFrontendFr.showDealerHistory)
                                    }),
                                item: true
                              }))
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_TextFieldFormInput$DvmAdminFrontendFr.make, {
                            onChange: Curry._1(form.updateDealerName, (function (input, dealerName) {
                                    return {
                                            dr: input.dr,
                                            dealerId: input.dealerId,
                                            dealerName: dealerName,
                                            keyAccountManager: input.keyAccountManager,
                                            city: input.city,
                                            showHistory: input.showHistory
                                          };
                                  })),
                            value: form.input.dealerName,
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.standardInput,
                            label: Messages_Dealer_List$DvmAdminFrontendFr.dealerName
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_OverlaySpinner$DvmAdminFrontendFr.make, {
                            show: App_Types_Result$DvmAdminFrontendFr.isPending(managers),
                            children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                                  label: Messages_Bonuses_Anomalies$DvmAdminFrontendFr.dealerKeyAccountManager,
                                  value: {
                                    TAG: /* Single */0,
                                    _0: {
                                      value: form.input.keyAccountManager,
                                      onChange: Curry._1(form.updateKeyAccountManager, (function (input, keyAccountManager) {
                                              return {
                                                      dr: input.dr,
                                                      dealerId: input.dealerId,
                                                      dealerName: input.dealerName,
                                                      keyAccountManager: keyAccountManager,
                                                      city: input.city,
                                                      showHistory: input.showHistory
                                                    };
                                            }))
                                    }
                                  },
                                  options: {
                                    TAG: /* Labeled */1,
                                    _0: Belt_List.map(Belt_List.sort(Belt_List.keep(App_Types_Result$DvmAdminFrontendFr.getWithDefault(managers, /* [] */0), (function (x) {
                                                    return Belt_Option.getWithDefault(x.paramTwo, "").length > 0;
                                                  })), (function (a, b) {
                                                return Caml.string_compare(Belt_Option.getWithDefault(a.paramTwo, ""), Belt_Option.getWithDefault(b.paramTwo, ""));
                                              })), (function (x) {
                                            return {
                                                    label: {
                                                      TAG: /* String */3,
                                                      _0: Belt_Option.getWithDefault(x.paramTwo, "") + (
                                                        Belt_Option.getWithDefault(x.paramOne, "").length > 0 ? " (" + Belt_Option.getWithDefault(x.paramOne, "") + ")" : ""
                                                      )
                                                    },
                                                    value: Belt_Option.getWithDefault(x.paramOne, "")
                                                  };
                                          }))
                                  },
                                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                                })
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_AutocompleteFormInput$DvmAdminFrontendFr.make, {
                            label: Messages_Dealer_List$DvmAdminFrontendFr.localityName,
                            value: {
                              TAG: /* Single */0,
                              _0: {
                                value: form.input.city,
                                onChange: Curry._1(form.updateCity, (function (input, city) {
                                        return {
                                                dr: input.dr,
                                                dealerId: input.dealerId,
                                                dealerName: input.dealerName,
                                                keyAccountManager: input.keyAccountManager,
                                                city: city,
                                                showHistory: input.showHistory
                                              };
                                      }))
                              }
                            },
                            options: {
                              TAG: /* Unlabeled */0,
                              _0: Belt_List.sort(match$4[0], Caml.string_compare)
                            },
                            className: App_Styles_Common$DvmAdminFrontendFr.Inputs.longInput
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Form;

var Api;

var InputStyles;

var make = Dealer_Search_Filter;

export {
  Form ,
  Api ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
