// Generated by ReScript, PLEASE EDIT WITH CARE


var dealerCompanyName = {
  id: "a49abc2e-0ec5-4b81-9f6a-add0b197f05b",
  defaultMessage: "Raison sociale de l’affaire "
};

var customerOrderNum = {
  id: "9fde8d3a-8870-4542-93ca-7f4a769b788f",
  defaultMessage: "N° commande"
};

var carOrderNum = {
  id: "28793671-05d3-4196-8a25-14d5a2381f93",
  defaultMessage: "N° ordre"
};

var orderDetail = {
  id: "64244051-7897-44be-b81b-9b2a00f3af3b",
  defaultMessage: "Détail de la commande"
};

var lastModification = {
  id: "879ece34-e524-492d-b539-20cc7c45f9d4",
  defaultMessage: "Dernière modification le"
};

var customerName = {
  id: "e3f60672-fb51-443f-9393-cd4c2ccb7a59",
  defaultMessage: "Nom client"
};

var aquisitionType = {
  id: "c5512be8-5c30-4bca-b891-107d6da7d025",
  defaultMessage: "Type acquisition"
};

var orderDate = {
  id: "7eaae43a-f5c8-43ef-b674-d5f65d869b36",
  defaultMessage: "Date commande"
};

var orderDateV2 = {
  id: "bdc1e96b-66a1-442e-bf15-6d41f7abf198",
  defaultMessage: "Date de commande"
};

var registrationDate = {
  id: "ad2d6872-be18-4f96-ae6f-1773c90b7ab0",
  defaultMessage: "Date immat"
};

var registrationDateV2 = {
  id: "e82410f6-21df-4d4e-aeb8-96d7003d8292",
  defaultMessage: "Date immatriculation"
};

var desiredDelivery = {
  id: "a0e3fbc3-f2f6-4055-8d52-6706eb032465",
  defaultMessage: "Livraison souhaitée"
};

var desiredDeliveryV2 = {
  id: "4ec6d46a-c67a-4390-b599-ba259ea8aa3e",
  defaultMessage: "Date livraison souhaitée"
};

var deliveryDate = {
  id: "b97240dd-c8cd-4e8f-ad5f-9370ce7988eb",
  defaultMessage: "Date livraison"
};

var invoiceDate = {
  id: "d8008f46-db9c-431f-8fc3-f958bd493846",
  defaultMessage: "Facture Renault"
};

var invoiceDateV2 = {
  id: "825aafda-170c-44df-9131-821fde574a3c",
  defaultMessage: "Date facture Renault"
};

var priceRefVN = {
  id: "7a8bf84a-77b9-4dd7-b1ac-c339e016cfc6",
  defaultMessage: "Prix ref VN"
};

var priceRefVNV2 = {
  id: "ba407ce2-a502-4d14-a85d-e6dca9fa2802",
  defaultMessage: "PVC HT (Prix réf)"
};

var priceCaHt = {
  id: "8778a000-3de2-4b30-bbee-43bb4cd3531a",
  defaultMessage: "Tarif PVC HT (Prix réf)"
};

var tarifNumber = {
  id: "56b750fd-a633-4fd8-bc0e-f98fd1937451",
  defaultMessage: "N° de tarif"
};

var madcDate = {
  id: "20336c15-8dd4-4812-a352-fc1dc2cd0688",
  defaultMessage: "Date MADC"
};

var madaDate = {
  id: "42a07c06-0881-4ede-82c5-e829a6975d83",
  defaultMessage: "Date MADA"
};

var invoiceClient = {
  id: "067e3f47-1bb6-44dc-8a67-776a1509db88",
  defaultMessage: "Facture client"
};

var invoiceClientV2 = {
  id: "d05229e6-edaf-4ac4-9eee-48c0d5df5412",
  defaultMessage: "Date facture client"
};

var invoiceNumber = {
  id: "e636a992-b4c1-4041-8790-dbb3bcbcd09b",
  defaultMessage: "N° facture client"
};

var orderBonuses = {
  id: "91ef832c-8fa5-471f-bfab-1b3aed881d64",
  defaultMessage: "Aides de la commande"
};

var orderBonusesSubtitle = {
  id: "37265e72-c57f-46a0-9f68-a73b1abfc6cd",
  defaultMessage: "Gérer les aides de cette commande"
};

var addUnexistActionButton = {
  id: "a1dda4a8-0026-4863-b118-67b7d67b7a47",
  defaultMessage: "Déroger une action inexistante sur cette commande"
};

var actionAmount = {
  id: "df4802e1-27e7-475c-ace0-f370e049e646",
  defaultMessage: "Montant aide HT"
};

var reprPoliceNumber = {
  id: "b6fd2a3d-857e-4df8-a65b-0892430932a1",
  defaultMessage: "N° livre de police"
};

var reprImmat = {
  id: "17d093a3-f26d-4160-8faf-64ebcdf43a89",
  defaultMessage: "Immatriculation"
};

var reprDateImmat = {
  id: "37da6839-560c-4077-a08d-032c4eeb375b",
  defaultMessage: "Date de dernière immat"
};

var reprDatePremiere = {
  id: "a506e88d-d8bc-4823-84ae-64d436318e30",
  defaultMessage: "Date première circulation"
};

var showMore = {
  id: "305127e1-2b52-463d-951b-cb374de59638",
  defaultMessage: "Montrer plus"
};

var showLess = {
  id: "7980e9ac-2522-4432-81cc-f4576b558716",
  defaultMessage: "Montrer moins"
};

var modifySuccess = {
  id: "f9cdb0f7-eda0-4d6a-9309-6cff792f5d0d",
  defaultMessage: "Numéro de police mis à jour avec succès"
};

var policeNumberEditTitle = {
  id: "33492420-2ec4-4a67-96d2-bf2cc0769c52",
  defaultMessage: "Saisie du numéro de livre de police"
};

var enterPoliceNumber = {
  id: "0cd6ebe4-c658-497f-8074-60e8a71b0832",
  defaultMessage: "Saisir le no de police"
};

var finalCustomer = {
  id: "24e8907e-b72e-4523-a43c-c2155b76a11b",
  defaultMessage: "SIREN C. Final LLD"
};

var finalCustomerName = {
  id: "049e37c3-0e23-479c-a5ff-f2bf7672cc66",
  defaultMessage: "Nom C. Final LLD"
};

var correct = {
  id: "2adfc1a2-141f-463d-87d3-f5e9fb9b3be9",
  defaultMessage: "Corriger"
};

var registrationNumber = {
  id: "f9177b9e-55f0-4e0d-9040-d10a11651697",
  defaultMessage: "Immatriculation"
};

var historyLink = {
  id: "06707aed-e80b-4466-a69a-4d3a4641dec1",
  defaultMessage: "Voir l’historique des corrections"
};

var lastModifShort = {
  id: "b7bbba26-8b6f-449a-8c32-713b915d18bc",
  defaultMessage: "Dernière modif"
};

var lastModifShortV2 = {
  id: "99dcd338-7e78-4494-91d5-5ff2818a0261",
  defaultMessage: "Date dernière modif"
};

var preinvoiceDate = {
  id: "108b508c-8ae9-4b4b-9c24-6880dc622183",
  defaultMessage: "Date Prefac"
};

var preinvoiceNumber = {
  id: "50e829ef-288e-4cec-a991-456c12f19a8c",
  defaultMessage: "N° Prefac"
};

var bapInvoiceDate = {
  id: "1a777a24-aab0-485b-86be-6cf3c39007b2",
  defaultMessage: "Date BAP"
};

var zeroBudgetText = {
  id: "0e26454b-17d1-41d8-ad3e-07d70b95cb3f",
  defaultMessage: "Pas d’aide complémentaire"
};

var agentName = {
  id: "e4012251-7a21-4bda-87ef-f9aeb7fcfb31",
  defaultMessage: "Nom d'agent"
};

var calculableWithAmount = {
  id: "2b7baf03-ff73-4bbb-90f5-7ed8d778489c",
  defaultMessage: "En cours de traitement"
};

var cancellationDate = {
  id: "0d369ee6-36cb-4dcc-a4bc-449d1b75bb75",
  defaultMessage: "Date d’annulation"
};

var contractIDOrder = {
  id: "1406024e-5615-47f7-96b8-72a335b7ddc1",
  defaultMessage: "Code protocole"
};

var contractIDOrderV2 = {
  id: "8d912ac6-d672-43ab-a2b2-ed584a3abb6c",
  defaultMessage: "Protocole"
};

var oldVehicleInfo = {
  id: "7d3692ab-b98b-4070-a551-09e567e3efed",
  defaultMessage: "Information du VO"
};

var validation = {
  id: "1f98ed05-ffac-4487-97fd-ff3d31c2966e",
  defaultMessage: "Validation: "
};

var refusal = {
  id: "ec8c10c4-6a7c-467f-94db-f4bfcb4ddf65",
  defaultMessage: "Refus: "
};

var validationWithoutInfo = {
  id: "28fa950b-bf43-4ced-b8fe-53ad79ee4557",
  defaultMessage: "Validée"
};

var refusalWithoutInfo = {
  id: "320c8c77-4868-4e61-b5cf-eb7227e33dcd",
  defaultMessage: "Refusée"
};

var missingFieldVOSingular = {
  id: "2f7d53a7-03f3-497a-bdcd-5b19445ff2b3",
  defaultMessage: "Le champ en rouge doit être renseigné"
};

var missingFieldsVOPlural = {
  id: "a45c95f5-3aa0-4f70-8c34-58799292ddd3",
  defaultMessage: "Les champs en rouge doivent être renseignés"
};

var dealerName = {
  id: "522f5bd8-891b-4b7e-bbe5-543dca6f005f",
  defaultMessage: "Nom d’affaire"
};

var codeProtFinal = {
  id: "9be593b6-9295-4dff-97d5-8a6646d4b181",
  defaultMessage: "Code PROT"
};

var valueVO = {
  id: "485c68f2-7884-4a10-8ce3-43cabe63adb9",
  defaultMessage: "Valeur de reprise VO"
};

var estimatedValueVO = {
  id: "d34f5a97-46ae-432f-b248-f3f12dfb5a5c",
  defaultMessage: "Valeur estimée de la reprise"
};

var endOfLifeVO = {
  id: "8a647d44-c218-434e-94fa-fac289ea41a6",
  defaultMessage: "Véhicule hors d’usage (O/N)"
};

var clientFirstName = {
  id: "3f1db6d4-bca6-4310-91d6-3dd0af96f790",
  defaultMessage: "Prénom client"
};

var dateInputParc = {
  id: "f9e3189f-058c-448c-9502-984d5eadad49",
  defaultMessage: "Date entrée PARC"
};

var dateOutputParc = {
  id: "4301bdbf-6c48-49dd-a7de-79d2ca8657d7",
  defaultMessage: "Date sortie PARC"
};

var renaultInvoiceNumber = {
  id: "233b27bc-c08c-4ecf-be2d-1a0afcf7547c",
  defaultMessage: "N° facture Renault"
};

var pvcTtc = {
  id: "a6415753-87fc-4019-a4ed-26c398fb22ea",
  defaultMessage: "PVC TTC"
};

var priceCaTTC = {
  id: "bd9625a2-c2f2-4318-8379-693d1432ad2f",
  defaultMessage: "Tarif PVC TTC"
};

var pvrHt = {
  id: "d3cbbc2f-1175-4eac-a83d-f906070fa049",
  defaultMessage: "PVR HT"
};

var actionAmountPaid = {
  id: "ff7a60ef-9f54-417c-aba2-426821fabe6d",
  defaultMessage: "Montant payé HT"
};

var actionAmountWithVAT = {
  id: "6dc85caa-43b9-4381-ae9d-860ab895490e",
  defaultMessage: "Montant aide TTC"
};

var orderIsCanceled = {
  id: "f23d785f-5c90-4ad1-a391-383539c6e609",
  defaultMessage: "La commande est annulée"
};

var waitingForData = {
  id: "b481e32a-b3a8-4ab7-a6c6-3874f4ac83a7",
  defaultMessage: "En attente de données complémentaires"
};

var waitingForDataVINMissingNotDelivered = {
  id: "03832805-e469-4db6-8012-c49b261aa803",
  defaultMessage: "En attente de l'affectation du véhicule"
};

var waitingForDataVINNotMissingAndNotDelivered = {
  id: "b4832433-4814-41f9-a69e-a2f14c731f07",
  defaultMessage: "En attente de livraison"
};

var pending = {
  id: "03f8a4ad-d0fe-42b7-9f4f-a9f56a9486f5",
  defaultMessage: "En cours"
};

var confirmEditModalTitle = {
  id: "dcfd22d7-7649-4f0a-8647-b26773b13069",
  defaultMessage: "Êtes-vous sûr du montant saisi ?"
};

var confirmEditVOModalTitle = {
  id: "c149e914-92c1-4022-a786-c05d04146bc4",
  defaultMessage: "Etes-vous sûrs de votre saisie ?"
};

var policeNumberNotPresent = {
  id: "26ffcc47-8465-4053-bedf-f8b487a7c8f4",
  defaultMessage: "Numéro du livre de police non renseigné"
};

var waitingForManufacturerFacturation = {
  id: "b75aa7b4-7c56-4ed5-b877-8cb583eb9259",
  defaultMessage: "En attente facturation constructeur"
};

var enterOneEuroCustomerDiscount = {
  id: "227c9670-89ba-450c-b25d-52ae43e7b5a0",
  defaultMessage: "En cas d'absence de remise, veuillez saisir un montant égal à 1€"
};

var entryDateParc = {
  id: "d67d4dcb-c664-4aa4-981f-b25da9c610c2",
  defaultMessage: "Date d'entrée au parc"
};

var exitDateParc = {
  id: "a0600c02-a8c9-41cd-a51a-74f28c0df9d6",
  defaultMessage: "Date de sortie de parc"
};

var deleteAllVOModal = {
  id: "98edb9fe-15e6-48bf-93c6-4c1719602c9a",
  defaultMessage: "Êtes-vous sûr de vouloir supprimer les informations VO ?"
};

var confirmEditExitParc = {
  id: "53b2e002-4596-497c-8a5e-da62068baeea",
  defaultMessage: "Êtes-vous sûr de vouloir sortir le véhicule du PARC?"
};

var editVO = {
  id: "eaafabe0-00ef-4154-a19d-08ca1ff0e502",
  defaultMessage: "Mise à jour VO"
};

var addDealerRequest = {
  id: "b12e50c8-d0b4-405b-b1db-46e7cf184ccb",
  defaultMessage: "Demander une aide supplémentaire sur la commande"
};

var dealerEditParcExitWarning = {
  id: "5cf52a89-d102-440d-9401-6c7723865fbc",
  defaultMessage: "*attention, veuillez noter que toute sortie sera définitive"
};

var orderResellingCreateButton = {
  id: "4ca9d6a7-08a6-4d91-9fb7-fc89c0e0feeb",
  defaultMessage: "Saisir une revente"
};

var orderResellingRedirectButton = {
  id: "0ea9467b-86ed-4ea0-a60f-bed68a869b10",
  defaultMessage: "Redirection vers les informations de commande du véhicule revendu"
};

var deleteResellingOrder = {
  id: "a1c4c66e-cd22-4c91-a12e-2d6245ea59d7",
  defaultMessage: "Êtes-vous sûr de vouloir supprimer supprimer la commande ?"
};

var editOrder = {
  id: "dc18e478-c8f4-4fbe-aa0a-300231ece558",
  defaultMessage: "Update order"
};

export {
  dealerCompanyName ,
  customerOrderNum ,
  carOrderNum ,
  orderDetail ,
  lastModification ,
  customerName ,
  aquisitionType ,
  orderDate ,
  orderDateV2 ,
  registrationDate ,
  registrationDateV2 ,
  desiredDelivery ,
  desiredDeliveryV2 ,
  deliveryDate ,
  invoiceDate ,
  invoiceDateV2 ,
  priceRefVN ,
  priceRefVNV2 ,
  priceCaHt ,
  tarifNumber ,
  madcDate ,
  madaDate ,
  invoiceClient ,
  invoiceClientV2 ,
  invoiceNumber ,
  orderBonuses ,
  orderBonusesSubtitle ,
  addUnexistActionButton ,
  actionAmount ,
  reprPoliceNumber ,
  reprImmat ,
  reprDateImmat ,
  reprDatePremiere ,
  showMore ,
  showLess ,
  modifySuccess ,
  policeNumberEditTitle ,
  enterPoliceNumber ,
  finalCustomer ,
  finalCustomerName ,
  correct ,
  registrationNumber ,
  historyLink ,
  lastModifShort ,
  lastModifShortV2 ,
  preinvoiceDate ,
  preinvoiceNumber ,
  bapInvoiceDate ,
  zeroBudgetText ,
  agentName ,
  calculableWithAmount ,
  cancellationDate ,
  contractIDOrder ,
  contractIDOrderV2 ,
  oldVehicleInfo ,
  validation ,
  refusal ,
  validationWithoutInfo ,
  refusalWithoutInfo ,
  missingFieldVOSingular ,
  missingFieldsVOPlural ,
  dealerName ,
  codeProtFinal ,
  valueVO ,
  estimatedValueVO ,
  endOfLifeVO ,
  clientFirstName ,
  dateInputParc ,
  dateOutputParc ,
  renaultInvoiceNumber ,
  pvcTtc ,
  priceCaTTC ,
  pvrHt ,
  actionAmountPaid ,
  actionAmountWithVAT ,
  orderIsCanceled ,
  waitingForData ,
  waitingForDataVINMissingNotDelivered ,
  waitingForDataVINNotMissingAndNotDelivered ,
  pending ,
  confirmEditModalTitle ,
  confirmEditVOModalTitle ,
  policeNumberNotPresent ,
  waitingForManufacturerFacturation ,
  enterOneEuroCustomerDiscount ,
  entryDateParc ,
  exitDateParc ,
  deleteAllVOModal ,
  confirmEditExitParc ,
  editVO ,
  addDealerRequest ,
  dealerEditParcExitWarning ,
  orderResellingCreateButton ,
  orderResellingRedirectButton ,
  deleteResellingOrder ,
  editOrder ,
}
/* No side effect */
