// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core from "@material-ui/core";
import * as App_Icon$DvmAdminFrontendFr from "../../../../../../common/components/icons/App_Icon.bs.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../../intl/Intl_Utils.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../../theme/Theme_Colors.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../../../../common/styles/App_Styles_Common.bs.js";
import * as Messages_Orders_Correction$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Correction.bs.js";
import * as Order_Detail_Correct_Order_New_Fleet$DvmAdminFrontendFr from "./Order_Detail_Correct_Order_New_Fleet.bs.js";
import * as Order_Detail_Correct_Order_New_NonFleet$DvmAdminFrontendFr from "./Order_Detail_Correct_Order_New_NonFleet.bs.js";
import * as Order_Detail_Correct_Order_Content_Fleet$DvmAdminFrontendFr from "./Order_Detail_Correct_Order_Content_Fleet.bs.js";
import * as Order_Detail_Correct_Order_Content_NonFleet$DvmAdminFrontendFr from "./Order_Detail_Correct_Order_Content_NonFleet.bs.js";

function Order_Detail_Correct_Order_Separator(Props) {
  var form = Props.form;
  var formAdditional = Props.formAdditional;
  var order = Props.order;
  var newestUndeployedCorrection = Props.newestUndeployedCorrection;
  var intl = ReactIntl.useIntl();
  var tooltipSpacingRef = React.useRef(null);
  var match = React.useState(function () {
        
      });
  var setTooltipHeight = match[1];
  var tooltipHeight = match[0];
  React.useEffect(function () {
        Curry._1(setTooltipHeight, (function (param) {
                return Belt_Option.mapWithDefault(Caml_option.nullable_to_opt(tooltipSpacingRef.current), undefined, (function (x) {
                              var domrect = x.getBoundingClientRect();
                              return String(domrect.height) + "px";
                            }));
              }));
      });
  var match$1 = form.input.orderType;
  if (match$1 !== 0) {
    if (match$1 >= 9) {
      return null;
    } else {
      return React.createElement(React.Fragment, undefined, React.createElement(Order_Detail_Correct_Order_Content_NonFleet$DvmAdminFrontendFr.make, {
                      order: order,
                      color: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                      title: React.createElement(Core.Grid, {
                            children: null,
                            container: true,
                            spacing: 1
                          }, React.createElement(Core.Grid, {
                                children: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.initialValues),
                                item: true
                              }), Belt_Option.mapWithDefault(newestUndeployedCorrection, null, (function (correction) {
                                  return React.createElement(Core.Grid, {
                                              children: React.createElement(Core.Tooltip, {
                                                    arrow: true,
                                                    children: React.createElement("div", undefined, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                              size: /* Large */3,
                                                              type_: /* Information */43
                                                            })),
                                                    classes: {
                                                      tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                                                    },
                                                    placement: "bottom",
                                                    title: "" + intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.lastUndeployedMod) + " " + Belt_Option.mapWithDefault(correction.timeStamp, "", (function (param) {
                                                            return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                          })) + ""
                                                  }),
                                              item: true
                                            });
                                }))),
                      newestUndeployedCorrection: newestUndeployedCorrection,
                      tooltipHeight: tooltipHeight,
                      form: form
                    }), React.createElement(Order_Detail_Correct_Order_New_NonFleet$DvmAdminFrontendFr.make, {
                      form: form,
                      formAdditional: formAdditional,
                      order: order,
                      tooltipSpacingRef: tooltipSpacingRef
                    }));
    }
  } else {
    return React.createElement(React.Fragment, undefined, React.createElement(Order_Detail_Correct_Order_Content_Fleet$DvmAdminFrontendFr.make, {
                    order: order,
                    color: Theme_Colors$DvmAdminFrontendFr.primaryBrown,
                    title: React.createElement(Core.Grid, {
                          children: null,
                          container: true,
                          spacing: 1
                        }, React.createElement(Core.Grid, {
                              children: intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.initialValues),
                              item: true
                            }), Belt_Option.mapWithDefault(newestUndeployedCorrection, null, (function (correction) {
                                return React.createElement(Core.Grid, {
                                            children: React.createElement(Core.Tooltip, {
                                                  arrow: true,
                                                  children: React.createElement("div", undefined, React.createElement(App_Icon$DvmAdminFrontendFr.make, {
                                                            size: /* Large */3,
                                                            type_: /* Information */43
                                                          })),
                                                  classes: {
                                                    tooltip: App_Styles_Common$DvmAdminFrontendFr.tooltip
                                                  },
                                                  placement: "bottom",
                                                  title: "" + intl.formatMessage(Messages_Orders_Correction$DvmAdminFrontendFr.lastUndeployedMod) + " " + Belt_Option.mapWithDefault(correction.timeStamp, "", (function (param) {
                                                          return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                                        })) + ""
                                                }),
                                            item: true
                                          });
                              }))),
                    newestUndeployedCorrection: newestUndeployedCorrection,
                    tooltipHeight: tooltipHeight
                  }), React.createElement(Order_Detail_Correct_Order_New_Fleet$DvmAdminFrontendFr.make, {
                    form: form,
                    formAdditional: formAdditional,
                    order: order,
                    tooltipSpacingRef: tooltipSpacingRef
                  }));
  }
}

var Form;

var FormAdditional;

var InputStyles;

var make = Order_Detail_Correct_Order_Separator;

export {
  Form ,
  FormAdditional ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
