// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Notistack from "notistack";
import * as ReactAlert from "react-alert";
import DateFns from "@date-io/date-fns";
import * as Core from "@material-ui/core";
import Fr from "date-fns/locale/fr";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.bs.js";
import * as Pickers from "@material-ui/pickers";
import * as App$DvmAdminFrontendFr from "./App.bs.js";
import * as Styles from "@material-ui/core/styles";
import * as Constants$DvmAdminFrontendFr from "../common/Constants.bs.js";
import * as Theme_Dvm$DvmAdminFrontendFr from "../theme/Theme_Dvm.bs.js";
import * as App_Styles$DvmAdminFrontendFr from "../common/App_Styles.bs.js";
import * as AlertTemplate$DvmAdminFrontendFr from "../alert/AlertTemplate.bs.js";
import * as Intl_Provider$DvmAdminFrontendFr from "../intl/Intl_Provider.bs.js";
import * as Context_Wrapper$DvmAdminFrontendFr from "../context/Context_Wrapper.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../intl/messages/Messages_Common.bs.js";
import * as AlertTemplateNew$DvmAdminFrontendFr from "../alert/AlertTemplateNew.bs.js";
import * as Auth_Logout_Page$DvmAdminFrontendFr from "../pages/auth/logout/Auth_Logout_Page.bs.js";
import * as DisableNumInputScroll$DvmAdminFrontendFr from "./DisableNumInputScroll.bs.js";

function Root(Props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var jss = React.useMemo((function () {
          return App_Styles$DvmAdminFrontendFr.createJss(undefined);
        }), []);
  var match = url.path;
  var tmp;
  var exit = 0;
  if (match && match.hd === "logout" && !match.tl) {
    tmp = React.createElement(Auth_Logout_Page$DvmAdminFrontendFr.make, {});
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement(App$DvmAdminFrontendFr.make, {});
  }
  return React.createElement(Context_Wrapper$DvmAdminFrontendFr.make, {
              children: React.createElement(Intl_Provider$DvmAdminFrontendFr.make, {
                    children: React.createElement(Pickers.MuiPickersUtilsProvider, {
                          utils: DateFns,
                          children: React.createElement(Styles.ThemeProvider, {
                                children: React.createElement(Styles.StylesProvider, {
                                      jss: jss,
                                      children: null
                                    }, React.createElement(Core.CssBaseline, {}), React.createElement(DisableNumInputScroll$DvmAdminFrontendFr.make, {}), React.createElement(Notistack.SnackbarProvider, {
                                          Components: {
                                            success: AlertTemplateNew$DvmAdminFrontendFr.make,
                                            info: AlertTemplateNew$DvmAdminFrontendFr.make,
                                            error: AlertTemplateNew$DvmAdminFrontendFr.make
                                          },
                                          anchorOrigin: {
                                            horizontal: "right",
                                            vertical: "top"
                                          },
                                          hideIconVariant: true,
                                          autoHideDuration: Constants$DvmAdminFrontendFr.defaultAlertTimeout,
                                          preventDuplicate: true,
                                          children: React.createElement(ReactAlert.Provider, {
                                                position: "top right",
                                                timeout: Constants$DvmAdminFrontendFr.defaultAlertTimeout,
                                                transition: "scale",
                                                containerStyle: {
                                                  marginTop: "64px",
                                                  zIndex: "2000"
                                                },
                                                children: tmp,
                                                template: (function (templateProps) {
                                                    return AlertTemplate$DvmAdminFrontendFr.renderTemplate(Messages_Common$DvmAdminFrontendFr.alertInfo, Messages_Common$DvmAdminFrontendFr.alertSuccess, Messages_Common$DvmAdminFrontendFr.alertError, templateProps);
                                                  })
                                              })
                                        })),
                                theme: Theme_Dvm$DvmAdminFrontendFr.theme
                              }),
                          locale: Fr
                        })
                  })
            });
}

var make = Root;

export {
  make ,
}
/* react Not a pure module */
