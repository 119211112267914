// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_List from "rescript/lib/es6/belt_List.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as User_Context$DvmAdminFrontendFr from "../../../context/user/User_Context.bs.js";
import * as Context_Router$DvmAdminFrontendFr from "../../../context/router/Context_Router.bs.js";
import * as User_Types_Utils$DvmAdminFrontendFr from "../../../context/user/User_Types_Utils.bs.js";
import * as App_Filter_Button$DvmAdminFrontendFr from "../../../common/components/button/App_Filter_Button.bs.js";
import * as App_Styles_Common$DvmAdminFrontendFr from "../../../common/styles/App_Styles_Common.bs.js";
import * as Order_Search_Form$DvmAdminFrontendFr from "./Order_Search_Form.bs.js";
import * as Order_Search_Modal_Form$DvmAdminFrontendFr from "./modal/filterPages/Order_Search_Modal_Form.bs.js";
import * as Order_Search_Modal_VO_Form$DvmAdminFrontendFr from "./modal/filterPages/Order_Search_Modal_VO_Form.bs.js";
import * as Order_Search_Modal_Wrapper$DvmAdminFrontendFr from "./Order_Search_Modal_Wrapper.bs.js";
import * as Order_Search_Filters_Inputs$DvmAdminFrontendFr from "./Order_Search_Filters_Inputs.bs.js";
import * as Order_Detail_Types_Order_Type$DvmAdminFrontendFr from "../detail/Order_Detail_Types_Order_Type.bs.js";
import * as Order_Search_Modal_Additional_Form$DvmAdminFrontendFr from "./modal/filterPages/Order_Search_Modal_Additional_Form.bs.js";

function Order_Search_Filters(Props) {
  var fetchData = Props.fetchData;
  var fetchRequest = Props.fetchRequest;
  var setParamsList = Props.setParamsList;
  var setShowHistory = Props.setShowHistory;
  var setConfirmedOrderType = Props.setConfirmedOrderType;
  var match = Curry._1(Context_Router$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var dispatch = match[1];
  var match$1 = match[0];
  var ordersModalVOFilter = match$1.ordersModalVOFilter;
  var ordersModalAdditionalFilter = match$1.ordersModalAdditionalFilter;
  var ordersModalFilter = match$1.ordersModalFilter;
  var ordersFilter = match$1.ordersFilter;
  var match$2 = Curry._1(User_Context$DvmAdminFrontendFr.Context.useAppContext, undefined);
  var selectedRole = match$2[0].selectedRole;
  var match$3 = React.useState(function () {
        return false;
      });
  var setModal = match$3[1];
  var match$4 = React.useState(function () {
        return /* [] */0;
      });
  var setFormList = match$4[1];
  var formList = match$4[0];
  var match$5 = React.useState(function () {
        return /* [] */0;
      });
  var setFormModalList = match$5[1];
  var formModalList = match$5[0];
  var match$6 = React.useState(function () {
        return /* [] */0;
      });
  var setFormModalAdditionalList = match$6[1];
  var formModalAdditionalList = match$6[0];
  var match$7 = React.useState(function () {
        return /* [] */0;
      });
  var setFormModalVOList = match$7[1];
  var formModalVOList = match$7[0];
  var match$8 = React.useState(function () {
        
      });
  var setSubmissionCallbacks = match$8[1];
  var submissionCallbacks = match$8[0];
  var match$9 = React.useState(function () {
        
      });
  var setSubmissionCallbacksModal = match$9[1];
  var submissionCallbacksModal = match$9[0];
  var match$10 = React.useState(function () {
        
      });
  var setSubmissionCallbacksModalAdditional = match$10[1];
  var submissionCallbacksModalAdditional = match$10[0];
  var match$11 = React.useState(function () {
        
      });
  var setSubmissionCallbacksModalVO = match$11[1];
  var submissionCallbacksModalVO = match$11[0];
  React.useEffect((function () {
          if (submissionCallbacks !== undefined && submissionCallbacksModal !== undefined && submissionCallbacksModalAdditional !== undefined && submissionCallbacksModalVO !== undefined) {
            Curry._1(setParamsList, (function (param) {
                    return Belt_List.concatMany([
                                formList,
                                formModalList,
                                formModalAdditionalList,
                                formModalVOList
                              ]);
                  }));
            Curry._5(fetchData, submissionCallbacks, submissionCallbacksModal, submissionCallbacksModalAdditional, submissionCallbacksModalVO, {
                  page: 0,
                  rowsPerPage: fetchRequest.rowsPerPage,
                  headers: fetchRequest.headers,
                  filterQueryParams: Belt_Array.joinWith(Belt_List.toArray(Belt_List.keepMap(Belt_List.concatMany([
                                    formList,
                                    formModalList,
                                    formModalAdditionalList,
                                    formModalVOList
                                  ]), (function (queryParam) {
                                  return Belt_Option.map(queryParam, (function (x) {
                                                return x.en;
                                              }));
                                }))), ";", (function (queryParam) {
                          return queryParam;
                        })),
                  additionalQueryParams: fetchRequest.additionalQueryParams
                });
          }
          
        }), [formModalVOList]);
  var form = Order_Search_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(ordersFilter, Order_Search_Form$DvmAdminFrontendFr.initialState), {
        isDealer: selectedRole === /* Dealer */7
      }, (function (output, submissionCallbacks) {
          var paramsList = Belt_List.concat(Belt_List.concat({
                    hd: output.dr,
                    tl: {
                      hd: output.dealerId,
                      tl: {
                        hd: output.customerOrderNumber,
                        tl: {
                          hd: output.orderNumberCar,
                          tl: {
                            hd: output.vin,
                            tl: {
                              hd: output.newCarRegistrationID,
                              tl: {
                                hd: output.budgetType,
                                tl: {
                                  hd: output.budgetStatus,
                                  tl: {
                                    hd: output.showArchived,
                                    tl: {
                                      hd: output.orderStatus,
                                      tl: {
                                        hd: output.usedCarStatus,
                                        tl: {
                                          hd: output.showHistoryDealer,
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }, User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* Spain */1 ? ({
                        hd: output.clientTypes,
                        tl: {
                          hd: output.saleTypes,
                          tl: /* [] */0
                        }
                      }) : /* [] */0), User_Types_Utils$DvmAdminFrontendFr.whatCountry(selectedRole) === /* France */0 ? ({
                    hd: output.orderType,
                    tl: /* [] */0
                  }) : /* [] */0);
          Curry._1(setSubmissionCallbacks, (function (param) {
                  return submissionCallbacks;
                }));
          Curry._1(setFormList, (function (param) {
                  return paramsList;
                }));
        }));
  var formModal = Order_Search_Modal_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(ordersModalFilter, Order_Search_Modal_Form$DvmAdminFrontendFr.initialState), {
        orderType: form.input.orderType
      }, (function (output, submissionCallbacks) {
          var paramsList_0 = output.orderDateStart;
          var paramsList_1 = {
            hd: output.registrationDateStart,
            tl: {
              hd: output.customerInvoiceStart,
              tl: {
                hd: output.deliveryDateStart,
                tl: {
                  hd: output.renaultInvoiceDateStart,
                  tl: {
                    hd: output.delivered,
                    tl: {
                      hd: output.customerName,
                      tl: {
                        hd: output.salesmanName,
                        tl: {
                          hd: output.agentCode,
                          tl: {
                            hd: output.entryDateParcStart,
                            tl: {
                              hd: output.exitDateParcStart,
                              tl: {
                                hd: output.parcAutomatic,
                                tl: /* [] */0
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          };
          var paramsList = {
            hd: paramsList_0,
            tl: paramsList_1
          };
          Curry._1(setSubmissionCallbacksModal, (function (param) {
                  return submissionCallbacks;
                }));
          Curry._1(setFormModalList, (function (param) {
                  return paramsList;
                }));
        }));
  var formAdditional = Order_Search_Modal_Additional_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(ordersModalAdditionalFilter, Order_Search_Modal_Additional_Form$DvmAdminFrontendFr.initialState), (function (output, submissionCallbacks) {
          var paramsList_0 = output.dealerName;
          var paramsList_1 = {
            hd: output.dealerGroup,
            tl: {
              hd: output.keyAccountManagerID,
              tl: {
                hd: output.sectorManagerID,
                tl: {
                  hd: output.sirenClientFinalLLD,
                  tl: {
                    hd: output.sirenClientFinalLLDName,
                    tl: {
                      hd: output.loueurID,
                      tl: {
                        hd: output.loueurName,
                        tl: {
                          hd: output.contractID,
                          tl: {
                            hd: output.contractName,
                            tl: {
                              hd: output.mainContractSIREN,
                              tl: {
                                hd: output.siren,
                                tl: {
                                  hd: output.modelID,
                                  tl: {
                                    hd: output.versionID,
                                    tl: {
                                      hd: output.categoryLabel,
                                      tl: {
                                        hd: output.brand,
                                        tl: {
                                          hd: output.phase,
                                          tl: {
                                            hd: output.vehicleType,
                                            tl: {
                                              hd: output.engineType,
                                              tl: {
                                                hd: output.codeAPE,
                                                tl: {
                                                  hd: output.customerName,
                                                  tl: {
                                                    hd: output.firstName,
                                                    tl: /* [] */0
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          };
          var paramsList = {
            hd: paramsList_0,
            tl: paramsList_1
          };
          Curry._1(setSubmissionCallbacksModalAdditional, (function (param) {
                  return submissionCallbacks;
                }));
          Curry._1(setFormModalAdditionalList, (function (param) {
                  return paramsList;
                }));
        }));
  var formModalVO = Order_Search_Modal_VO_Form$DvmAdminFrontendFr.TableFilterForm.useForm(Belt_Option.getWithDefault(ordersModalVOFilter, Order_Search_Modal_VO_Form$DvmAdminFrontendFr.initialState), (function (output, submissionCallbacks) {
          var paramsList_0 = output.brandUsedCar;
          var paramsList_1 = {
            hd: output.brandEmpty,
            tl: {
              hd: output.modelUsedCar,
              tl: {
                hd: output.modelEmpty,
                tl: {
                  hd: output.vinUsedCar,
                  tl: {
                    hd: output.vinEmpty,
                    tl: {
                      hd: output.usedCarPoliceNumber,
                      tl: {
                        hd: output.policeNumberEmpty,
                        tl: {
                          hd: output.usedCarLastRegistrationDateStart,
                          tl: {
                            hd: output.registrationDateEmpty,
                            tl: {
                              hd: output.usedCarFirstReleaseDateStart,
                              tl: {
                                hd: output.releaseDateEmpty,
                                tl: {
                                  hd: output.usedCarRegistrationID,
                                  tl: {
                                    hd: output.registrationIDEmpty,
                                    tl: /* [] */0
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          };
          var paramsList = {
            hd: paramsList_0,
            tl: paramsList_1
          };
          Curry._1(setSubmissionCallbacksModalVO, (function (param) {
                  return submissionCallbacks;
                }));
          Curry._1(setFormModalVOList, (function (param) {
                  return paramsList;
                }));
        }));
  var submit = function (param) {
    Curry._1(setModal, (function (param) {
            return false;
          }));
    Curry._1(dispatch, {
          TAG: /* StoreOrdersFilter */3,
          _0: form.input
        });
    Curry._1(dispatch, {
          TAG: /* StoreOrdersModalFilter */4,
          _0: formModal.input
        });
    Curry._1(dispatch, {
          TAG: /* StoreOrdersModalAdditionalFilter */5,
          _0: formAdditional.input
        });
    Curry._1(dispatch, {
          TAG: /* StoreOrdersModalVOFilter */6,
          _0: formModalVO.input
        });
    Curry._1(form.submit, undefined);
    Curry._1(formModal.submit, undefined);
    Curry._1(formAdditional.submit, undefined);
    Curry._1(formModalVO.submit, undefined);
    Curry._1(setConfirmedOrderType, (function (param) {
            return Belt_List.map(form.input.orderType, (function (x) {
                          return Belt_Option.getWithDefault(Order_Detail_Types_Order_Type$DvmAdminFrontendFr.fromString(x), /* NOTSET */9);
                        }));
          }));
  };
  React.useEffect((function () {
          if (Belt_Option.isSome(ordersFilter) || Belt_Option.isSome(ordersModalFilter) || Belt_Option.isSome(ordersModalAdditionalFilter) || Belt_Option.isSome(ordersModalVOFilter)) {
            submit(undefined);
          }
          
        }), []);
  return React.createElement("form", {
              onSubmit: (function ($$event) {
                  $$event.preventDefault();
                  submit(undefined);
                })
            }, React.createElement(Core.Grid, {
                  children: null,
                  className: App_Styles_Common$DvmAdminFrontendFr.Inputs.searchFiltersBox,
                  container: true
                }, React.createElement(Order_Search_Filters_Inputs$DvmAdminFrontendFr.make, {
                      setShowHistory: setShowHistory,
                      form: form
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(Order_Search_Modal_Wrapper$DvmAdminFrontendFr.make, {
                            form: form,
                            formModal: formModal,
                            formAdditional: formAdditional,
                            formModalVO: formModalVO,
                            modal: match$3[0],
                            setModal: setModal,
                            submit: submit
                          }),
                      item: true
                    }), React.createElement(Core.Grid, {
                      children: React.createElement(App_Filter_Button$DvmAdminFrontendFr.make, {
                            label: "OK",
                            type_: "submit"
                          }),
                      item: true
                    })));
}

var Form;

var FormModal;

var FormModalAdditional;

var FormModalVO;

var Api;

var InputStyles;

var make = Order_Search_Filters;

export {
  Form ,
  FormModal ,
  FormModalAdditional ,
  FormModalVO ,
  Api ,
  InputStyles ,
  make ,
}
/* react Not a pure module */
