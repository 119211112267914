// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Intl_Utils$DvmAdminFrontendFr from "../../../../../../intl/Intl_Utils.bs.js";
import * as Theme_Colors$DvmAdminFrontendFr from "../../../../../../theme/Theme_Colors.bs.js";
import * as App_LabeledText$DvmAdminFrontendFr from "../../../../../../common/components/App_LabeledText.bs.js";
import * as Messages_Common$DvmAdminFrontendFr from "../../../../../../intl/messages/Messages_Common.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";
import * as Order_Detail_Correct_Box$DvmAdminFrontendFr from "../Order_Detail_Correct_Box.bs.js";

function Order_Detail_Correct_Vehicle_History_Content(Props) {
  var correction = Props.correction;
  var color = Props.color;
  var title = Props.title;
  var form = Props.form;
  var intl = ReactIntl.useIntl();
  var match = form.input.orderType;
  return React.createElement(Order_Detail_Correct_Box$DvmAdminFrontendFr.make, {
              content: Belt_Array.concat([
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Orders_Detail$DvmAdminFrontendFr.reprPoliceNumber,
                          children: Belt_Option.getWithDefault(correction.usedCarPoliceNumber, "-")
                        }),
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Orders_Detail$DvmAdminFrontendFr.registrationDate,
                          children: Belt_Option.mapWithDefault(correction.usedCarLastRegistrationDate, "-", (function (param) {
                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                }))
                        }),
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Common$DvmAdminFrontendFr.registrationVO,
                          children: Belt_Option.getWithDefault(correction.usedCarRegistrationID, "-")
                        }),
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Orders_Detail$DvmAdminFrontendFr.reprDatePremiere,
                          children: Belt_Option.mapWithDefault(correction.usedCarFirstReleaseDate, "-", (function (param) {
                                  return Intl_Utils$DvmAdminFrontendFr.formatDate(intl, param);
                                }))
                        }),
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Common$DvmAdminFrontendFr.brandVO,
                          children: Belt_Option.getWithDefault(correction.brandUsedCar, "-")
                        }),
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Common$DvmAdminFrontendFr.modelVO,
                          children: Belt_Option.getWithDefault(correction.modelUsedCar, "-")
                        }),
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Common$DvmAdminFrontendFr.versionVO,
                          children: Belt_Option.getWithDefault(correction.versionUsedCar, "-")
                        }),
                    React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                          label: Messages_Common$DvmAdminFrontendFr.vinVO,
                          children: Belt_Option.getWithDefault(correction.vinUsedCar, "-")
                        })
                  ], match !== 8 ? (
                      match !== 0 ? [
                          React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                label: Messages_Orders_Detail$DvmAdminFrontendFr.valueVO,
                                children: Belt_Option.mapWithDefault(correction.realTradeAmount, "-", (function (eta) {
                                        return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                      }))
                              }),
                          React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                label: Messages_Orders_Detail$DvmAdminFrontendFr.estimatedValueVO,
                                children: Belt_Option.mapWithDefault(correction.estimatedTradeAmount, "-", (function (eta) {
                                        return Intl_Utils$DvmAdminFrontendFr.formatCurrency(intl, undefined, eta);
                                      }))
                              }),
                          React.createElement(App_LabeledText$DvmAdminFrontendFr.make, {
                                label: Messages_Orders_Detail$DvmAdminFrontendFr.endOfLifeVO,
                                children: Belt_Option.mapWithDefault(correction.scrapyardIndicator, "-", (function (x) {
                                        return intl.formatMessage(x ? Messages_Common$DvmAdminFrontendFr.yes : Messages_Common$DvmAdminFrontendFr.no);
                                      }))
                              })
                        ] : []
                    ) : []),
              bgcolor: Theme_Colors$DvmAdminFrontendFr.grayLight1,
              color: color,
              title: title
            });
}

var make = Order_Detail_Correct_Vehicle_History_Content;

export {
  make ,
}
/* react Not a pure module */
