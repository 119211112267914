// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as ReDate from "@mobily/rescript-date/src/ReDate.bs.js";
import * as Formality from "re-formality/src/Formality.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Formality__ReactUpdate from "re-formality/src/Formality__ReactUpdate.bs.js";
import * as Utils_Form$DvmAdminFrontendFr from "../../../common/utils/Utils_Form.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";
import * as Messages_Error_Inputs$DvmAdminFrontendFr from "../../../intl/messages/Messages_Error_Inputs.bs.js";

function validateDate(smallerDate, largerDate, thisDate, requiredSmaller, requiredLarger, needsSameYearOpt, param) {
  var needsSameYear = needsSameYearOpt !== undefined ? needsSameYearOpt : false;
  var match = thisDate === "smaller" ? [
      smallerDate,
      largerDate,
      requiredSmaller,
      requiredLarger
    ] : [
      largerDate,
      smallerDate,
      requiredLarger,
      requiredSmaller
    ];
  var requiredThat = match[3];
  var that = match[1];
  return Belt_Option.mapWithDefault(match[0], match[2] ? ({
                  TAG: /* Error */1,
                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.fieldRequired
                }) : ({
                  TAG: /* Ok */0,
                  _0: undefined
                }), (function ($$this) {
                if (App_Types_Date$DvmAdminFrontendFr.isValid($$this)) {
                  return Belt_Option.mapWithDefault(that, requiredThat ? ({
                                  TAG: /* Error */1,
                                  _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                }) : ({
                                  TAG: /* Ok */0,
                                  _0: Caml_option.some($$this)
                                }), (function (that) {
                                if (App_Types_Date$DvmAdminFrontendFr.isValid(that)) {
                                  if (ReDate.getYear($$this) === ReDate.getYear(that) || !needsSameYear) {
                                    if (thisDate === "smaller") {
                                      if ($$this.valueOf() < that.valueOf()) {
                                        return {
                                                TAG: /* Ok */0,
                                                _0: Caml_option.some($$this)
                                              };
                                      } else {
                                        return {
                                                TAG: /* Error */1,
                                                _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooLarge
                                              };
                                      }
                                    } else if ($$this.valueOf() > that.valueOf()) {
                                      return {
                                              TAG: /* Ok */0,
                                              _0: Caml_option.some($$this)
                                            };
                                    } else {
                                      return {
                                              TAG: /* Error */1,
                                              _0: Messages_Error_Inputs$DvmAdminFrontendFr.dateTooSmall
                                            };
                                    }
                                  } else {
                                    return {
                                            TAG: /* Error */1,
                                            _0: Messages_Error_Inputs$DvmAdminFrontendFr.datesNotSameYear
                                          };
                                  }
                                } else {
                                  return {
                                          TAG: /* Error */1,
                                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.otherDateNotValid
                                        };
                                }
                              }));
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: Messages_Error_Inputs$DvmAdminFrontendFr.invalidField
                        };
                }
              }));
}

var validators_registrationEndDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var registrationEndDate = param.registrationEndDate;
      var registrationStartDate = param.registrationStartDate;
      if ((param$1.orderType - 4 >>> 0) > 1) {
        return validateDate(registrationStartDate, registrationEndDate, "larger", false, false, undefined, undefined);
      } else {
        return Utils_Form$DvmAdminFrontendFr.validateRequiredDateCompare(registrationStartDate, registrationEndDate, "larger", true, undefined, undefined);
      }
    })
};

var validators_registrationStartDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var registrationEndDate = param.registrationEndDate;
      var registrationStartDate = param.registrationStartDate;
      if ((param$1.orderType - 4 >>> 0) > 1) {
        return validateDate(registrationStartDate, registrationEndDate, "smaller", false, false, undefined, undefined);
      } else {
        return Utils_Form$DvmAdminFrontendFr.validateRequiredDateCompare(registrationStartDate, registrationEndDate, "smaller", true, undefined, undefined);
      }
    })
};

var validators_deliveryEndDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return validateDate(param.deliveryStartDate, param.deliveryEndDate, "larger", false, false, undefined, undefined);
    })
};

var validators_deliveryStartDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      return validateDate(param.deliveryStartDate, param.deliveryEndDate, "smaller", false, false, undefined, undefined);
    })
};

var validators_endDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var endDate = param.endDate;
      var startDate = param.startDate;
      var exit = 0;
      switch (param$1.orderType) {
        case /* RR */4 :
        case /* RC */5 :
            exit = 2;
            break;
        case /* SO */0 :
        case /* CL */1 :
        case /* VD */3 :
        case /* ES */6 :
        case /* EP */7 :
            exit = 1;
            break;
        case /* VE */2 :
        case /* NOTSET */8 :
            return {
                    TAG: /* Ok */0,
                    _0: undefined
                  };
        
      }
      switch (exit) {
        case 1 :
            return Utils_Form$DvmAdminFrontendFr.validateRequiredDateCompare(startDate, endDate, "larger", true, undefined, undefined);
        case 2 :
            return validateDate(startDate, endDate, "larger", false, false, undefined, undefined);
        
      }
    })
};

var validators_startDate = {
  strategy: /* OnFirstChange */1,
  validate: (function (param, param$1) {
      var endDate = param.endDate;
      var startDate = param.startDate;
      var exit = 0;
      switch (param$1.orderType) {
        case /* RR */4 :
        case /* RC */5 :
            exit = 2;
            break;
        case /* SO */0 :
        case /* CL */1 :
        case /* VD */3 :
        case /* ES */6 :
        case /* EP */7 :
            exit = 1;
            break;
        case /* VE */2 :
        case /* NOTSET */8 :
            return {
                    TAG: /* Ok */0,
                    _0: undefined
                  };
        
      }
      switch (exit) {
        case 1 :
            return Utils_Form$DvmAdminFrontendFr.validateRequiredDateCompare(startDate, endDate, "smaller", true, undefined, undefined);
        case 2 :
            return validateDate(startDate, endDate, "smaller", false, false, undefined, undefined);
        
      }
    })
};

var validators = {
  registrationEndDate: validators_registrationEndDate,
  registrationStartDate: validators_registrationStartDate,
  deliveryEndDate: validators_deliveryEndDate,
  deliveryStartDate: validators_deliveryStartDate,
  endDate: validators_endDate,
  startDate: validators_startDate
};

function initialFieldsStatuses(_input) {
  return {
          registrationEndDate: /* Pristine */0,
          registrationStartDate: /* Pristine */0,
          deliveryEndDate: /* Pristine */0,
          deliveryStartDate: /* Pristine */0,
          endDate: /* Pristine */0,
          startDate: /* Pristine */0
        };
}

function initialState(input) {
  return {
          input: input,
          fieldsStatuses: initialFieldsStatuses(input),
          collectionsStatuses: undefined,
          formStatus: /* Editing */0,
          submissionStatus: /* NeverSubmitted */0
        };
}

function validateForm(input, validators, fieldsStatuses, metadata) {
  var match = fieldsStatuses.registrationEndDate;
  var match_0 = match ? match._0 : Curry._2(validators.registrationEndDate.validate, input, metadata);
  var match$1 = fieldsStatuses.registrationStartDate;
  var match_0$1 = match$1 ? match$1._0 : Curry._2(validators.registrationStartDate.validate, input, metadata);
  var match$2 = fieldsStatuses.deliveryEndDate;
  var match_0$2 = match$2 ? match$2._0 : Curry._2(validators.deliveryEndDate.validate, input, metadata);
  var match$3 = fieldsStatuses.deliveryStartDate;
  var match_0$3 = match$3 ? match$3._0 : Curry._2(validators.deliveryStartDate.validate, input, metadata);
  var match$4 = fieldsStatuses.endDate;
  var match_0$4 = match$4 ? match$4._0 : Curry._2(validators.endDate.validate, input, metadata);
  var match$5 = fieldsStatuses.startDate;
  var match_0$5 = match$5 ? match$5._0 : Curry._2(validators.startDate.validate, input, metadata);
  var registrationEndDateResult = match_0;
  var registrationEndDateResult$1;
  if (registrationEndDateResult.TAG === /* Ok */0) {
    var registrationStartDateResult = match_0$1;
    if (registrationStartDateResult.TAG === /* Ok */0) {
      var deliveryEndDateResult = match_0$2;
      if (deliveryEndDateResult.TAG === /* Ok */0) {
        var deliveryStartDateResult = match_0$3;
        if (deliveryStartDateResult.TAG === /* Ok */0) {
          var endDateResult = match_0$4;
          if (endDateResult.TAG === /* Ok */0) {
            var startDateResult = match_0$5;
            if (startDateResult.TAG === /* Ok */0) {
              return {
                      TAG: /* Valid */0,
                      output: {
                        startDate: startDateResult._0,
                        endDate: endDateResult._0,
                        deliveryStartDate: deliveryStartDateResult._0,
                        deliveryEndDate: deliveryEndDateResult._0,
                        registrationStartDate: registrationStartDateResult._0,
                        registrationEndDate: registrationEndDateResult._0
                      },
                      fieldsStatuses: {
                        registrationEndDate: /* Dirty */{
                          _0: registrationEndDateResult,
                          _1: /* Shown */0
                        },
                        registrationStartDate: /* Dirty */{
                          _0: registrationStartDateResult,
                          _1: /* Shown */0
                        },
                        deliveryEndDate: /* Dirty */{
                          _0: deliveryEndDateResult,
                          _1: /* Shown */0
                        },
                        deliveryStartDate: /* Dirty */{
                          _0: deliveryStartDateResult,
                          _1: /* Shown */0
                        },
                        endDate: /* Dirty */{
                          _0: endDateResult,
                          _1: /* Shown */0
                        },
                        startDate: /* Dirty */{
                          _0: startDateResult,
                          _1: /* Shown */0
                        }
                      },
                      collectionsStatuses: undefined
                    };
            }
            registrationEndDateResult$1 = registrationEndDateResult;
          } else {
            registrationEndDateResult$1 = registrationEndDateResult;
          }
        } else {
          registrationEndDateResult$1 = registrationEndDateResult;
        }
      } else {
        registrationEndDateResult$1 = registrationEndDateResult;
      }
    } else {
      registrationEndDateResult$1 = registrationEndDateResult;
    }
  } else {
    registrationEndDateResult$1 = registrationEndDateResult;
  }
  return {
          TAG: /* Invalid */1,
          fieldsStatuses: {
            registrationEndDate: /* Dirty */{
              _0: registrationEndDateResult$1,
              _1: /* Shown */0
            },
            registrationStartDate: /* Dirty */{
              _0: match_0$1,
              _1: /* Shown */0
            },
            deliveryEndDate: /* Dirty */{
              _0: match_0$2,
              _1: /* Shown */0
            },
            deliveryStartDate: /* Dirty */{
              _0: match_0$3,
              _1: /* Shown */0
            },
            endDate: /* Dirty */{
              _0: match_0$4,
              _1: /* Shown */0
            },
            startDate: /* Dirty */{
              _0: match_0$5,
              _1: /* Shown */0
            }
          },
          collectionsStatuses: undefined
        };
}

function useForm(initialInput, metadata, onSubmit) {
  var memoizedInitialState = React.useMemo((function () {
          return initialState(initialInput);
        }), [initialInput]);
  var match = Formality__ReactUpdate.useReducer(memoizedInitialState, (function (state, action) {
          if (typeof action === "number") {
            switch (action) {
              case /* BlurRegistrationEndDateField */0 :
                  var result = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.registrationEndDate, validators_registrationEndDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationEndDate: status,
                                  registrationStartDate: init.registrationStartDate,
                                  deliveryEndDate: init.deliveryEndDate,
                                  deliveryStartDate: init.deliveryStartDate,
                                  endDate: init.endDate,
                                  startDate: init.startDate
                                };
                        }));
                  if (result !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurRegistrationStartDateField */1 :
                  var result$1 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.registrationStartDate, validators_registrationStartDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationEndDate: init.registrationEndDate,
                                  registrationStartDate: status,
                                  deliveryEndDate: init.deliveryEndDate,
                                  deliveryStartDate: init.deliveryStartDate,
                                  endDate: init.endDate,
                                  startDate: init.startDate
                                };
                        }));
                  if (result$1 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$1,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDeliveryEndDateField */2 :
                  var result$2 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.deliveryEndDate, validators_deliveryEndDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationEndDate: init.registrationEndDate,
                                  registrationStartDate: init.registrationStartDate,
                                  deliveryEndDate: status,
                                  deliveryStartDate: init.deliveryStartDate,
                                  endDate: init.endDate,
                                  startDate: init.startDate
                                };
                        }));
                  if (result$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$2,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurDeliveryStartDateField */3 :
                  var result$3 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.deliveryStartDate, validators_deliveryStartDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationEndDate: init.registrationEndDate,
                                  registrationStartDate: init.registrationStartDate,
                                  deliveryEndDate: init.deliveryEndDate,
                                  deliveryStartDate: status,
                                  endDate: init.endDate,
                                  startDate: init.startDate
                                };
                        }));
                  if (result$3 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$3,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurEndDateField */4 :
                  var result$4 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.endDate, validators_endDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationEndDate: init.registrationEndDate,
                                  registrationStartDate: init.registrationStartDate,
                                  deliveryEndDate: init.deliveryEndDate,
                                  deliveryStartDate: init.deliveryStartDate,
                                  endDate: status,
                                  startDate: init.startDate
                                };
                        }));
                  if (result$4 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$4,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* BlurStartDateField */5 :
                  var result$5 = Formality.validateFieldOnBlurWithValidatorAndMetadata(state.input, metadata, state.fieldsStatuses.startDate, validators_startDate, (function (status) {
                          var init = state.fieldsStatuses;
                          return {
                                  registrationEndDate: init.registrationEndDate,
                                  registrationStartDate: init.registrationStartDate,
                                  deliveryEndDate: init.deliveryEndDate,
                                  deliveryStartDate: init.deliveryStartDate,
                                  endDate: init.endDate,
                                  startDate: status
                                };
                        }));
                  if (result$5 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: result$5,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: state.formStatus,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              case /* Submit */6 :
                  var match = state.formStatus;
                  if (typeof match !== "number" && match.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  var match$1 = validateForm(state.input, validators, state.fieldsStatuses, metadata);
                  if (match$1.TAG !== /* Valid */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: match$1.fieldsStatuses,
                              collectionsStatuses: match$1.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: /* AttemptedToSubmit */1
                            }
                          };
                  }
                  var output = match$1.output;
                  var error = state.formStatus;
                  var tmp;
                  tmp = typeof error === "number" || error.TAG !== /* SubmissionFailed */1 ? undefined : error._0;
                  return {
                          TAG: /* UpdateWithSideEffects */1,
                          _0: {
                            input: state.input,
                            fieldsStatuses: match$1.fieldsStatuses,
                            collectionsStatuses: match$1.collectionsStatuses,
                            formStatus: {
                              TAG: /* Submitting */0,
                              _0: tmp
                            },
                            submissionStatus: /* AttemptedToSubmit */1
                          },
                          _1: (function (param) {
                              var dispatch = param.dispatch;
                              Curry._2(onSubmit, output, {
                                    notifyOnSuccess: (function (input) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmittedStatus */6,
                                              _0: input
                                            });
                                      }),
                                    notifyOnFailure: (function (error) {
                                        Curry._1(dispatch, {
                                              TAG: /* SetSubmissionFailedStatus */7,
                                              _0: error
                                            });
                                      }),
                                    reset: (function (param) {
                                        Curry._1(dispatch, /* Reset */9);
                                      }),
                                    dismissSubmissionResult: (function (param) {
                                        Curry._1(dispatch, /* DismissSubmissionResult */8);
                                      })
                                  });
                            })
                        };
                  break;
              case /* DismissSubmissionError */7 :
                  var match$2 = state.formStatus;
                  if (typeof match$2 === "number" || match$2.TAG !== /* SubmissionFailed */1) {
                    return /* NoUpdate */0;
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Editing */0,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* DismissSubmissionResult */8 :
                  var match$3 = state.formStatus;
                  if (typeof match$3 === "number") {
                    if (match$3 === /* Editing */0) {
                      return /* NoUpdate */0;
                    }
                    
                  } else if (match$3.TAG === /* Submitting */0) {
                    return /* NoUpdate */0;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: /* Editing */0,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* Reset */9 :
                  return {
                          TAG: /* Update */0,
                          _0: initialState(initialInput)
                        };
              
            }
          } else {
            switch (action.TAG | 0) {
              case /* UpdateRegistrationEndDateField */0 :
                  var nextInput = Curry._1(action._0, state.input);
                  var nextFieldsStatuses = {
                    contents: state.fieldsStatuses
                  };
                  var result$6 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput, metadata, nextFieldsStatuses.contents.registrationStartDate, validators_registrationStartDate, (function (status) {
                          var init = nextFieldsStatuses.contents;
                          return {
                                  registrationEndDate: init.registrationEndDate,
                                  registrationStartDate: status,
                                  deliveryEndDate: init.deliveryEndDate,
                                  deliveryStartDate: init.deliveryStartDate,
                                  endDate: init.endDate,
                                  startDate: init.startDate
                                };
                        }));
                  if (result$6 !== undefined) {
                    nextFieldsStatuses.contents = result$6;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput, metadata, nextFieldsStatuses.contents.registrationEndDate, state.submissionStatus, validators_registrationEndDate, (function (status) {
                                    var init = nextFieldsStatuses.contents;
                                    return {
                                            registrationEndDate: status,
                                            registrationStartDate: init.registrationStartDate,
                                            deliveryEndDate: init.deliveryEndDate,
                                            deliveryStartDate: init.deliveryStartDate,
                                            endDate: init.endDate,
                                            startDate: init.startDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateRegistrationStartDateField */1 :
                  var nextInput$1 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$1 = {
                    contents: state.fieldsStatuses
                  };
                  var result$7 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$1, metadata, nextFieldsStatuses$1.contents.registrationEndDate, validators_registrationEndDate, (function (status) {
                          var init = nextFieldsStatuses$1.contents;
                          return {
                                  registrationEndDate: status,
                                  registrationStartDate: init.registrationStartDate,
                                  deliveryEndDate: init.deliveryEndDate,
                                  deliveryStartDate: init.deliveryStartDate,
                                  endDate: init.endDate,
                                  startDate: init.startDate
                                };
                        }));
                  if (result$7 !== undefined) {
                    nextFieldsStatuses$1.contents = result$7;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$1,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$1, metadata, nextFieldsStatuses$1.contents.registrationStartDate, state.submissionStatus, validators_registrationStartDate, (function (status) {
                                    var init = nextFieldsStatuses$1.contents;
                                    return {
                                            registrationEndDate: init.registrationEndDate,
                                            registrationStartDate: status,
                                            deliveryEndDate: init.deliveryEndDate,
                                            deliveryStartDate: init.deliveryStartDate,
                                            endDate: init.endDate,
                                            startDate: init.startDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDeliveryEndDateField */2 :
                  var nextInput$2 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$2 = {
                    contents: state.fieldsStatuses
                  };
                  var result$8 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$2, metadata, nextFieldsStatuses$2.contents.deliveryStartDate, validators_deliveryStartDate, (function (status) {
                          var init = nextFieldsStatuses$2.contents;
                          return {
                                  registrationEndDate: init.registrationEndDate,
                                  registrationStartDate: init.registrationStartDate,
                                  deliveryEndDate: init.deliveryEndDate,
                                  deliveryStartDate: status,
                                  endDate: init.endDate,
                                  startDate: init.startDate
                                };
                        }));
                  if (result$8 !== undefined) {
                    nextFieldsStatuses$2.contents = result$8;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$2,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$2, metadata, nextFieldsStatuses$2.contents.deliveryEndDate, state.submissionStatus, validators_deliveryEndDate, (function (status) {
                                    var init = nextFieldsStatuses$2.contents;
                                    return {
                                            registrationEndDate: init.registrationEndDate,
                                            registrationStartDate: init.registrationStartDate,
                                            deliveryEndDate: status,
                                            deliveryStartDate: init.deliveryStartDate,
                                            endDate: init.endDate,
                                            startDate: init.startDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateDeliveryStartDateField */3 :
                  var nextInput$3 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$3 = {
                    contents: state.fieldsStatuses
                  };
                  var result$9 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$3, metadata, nextFieldsStatuses$3.contents.deliveryEndDate, validators_deliveryEndDate, (function (status) {
                          var init = nextFieldsStatuses$3.contents;
                          return {
                                  registrationEndDate: init.registrationEndDate,
                                  registrationStartDate: init.registrationStartDate,
                                  deliveryEndDate: status,
                                  deliveryStartDate: init.deliveryStartDate,
                                  endDate: init.endDate,
                                  startDate: init.startDate
                                };
                        }));
                  if (result$9 !== undefined) {
                    nextFieldsStatuses$3.contents = result$9;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$3,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$3, metadata, nextFieldsStatuses$3.contents.deliveryStartDate, state.submissionStatus, validators_deliveryStartDate, (function (status) {
                                    var init = nextFieldsStatuses$3.contents;
                                    return {
                                            registrationEndDate: init.registrationEndDate,
                                            registrationStartDate: init.registrationStartDate,
                                            deliveryEndDate: init.deliveryEndDate,
                                            deliveryStartDate: status,
                                            endDate: init.endDate,
                                            startDate: init.startDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateEndDateField */4 :
                  var nextInput$4 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$4 = {
                    contents: state.fieldsStatuses
                  };
                  var result$10 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$4, metadata, nextFieldsStatuses$4.contents.startDate, validators_startDate, (function (status) {
                          var init = nextFieldsStatuses$4.contents;
                          return {
                                  registrationEndDate: init.registrationEndDate,
                                  registrationStartDate: init.registrationStartDate,
                                  deliveryEndDate: init.deliveryEndDate,
                                  deliveryStartDate: init.deliveryStartDate,
                                  endDate: init.endDate,
                                  startDate: status
                                };
                        }));
                  if (result$10 !== undefined) {
                    nextFieldsStatuses$4.contents = result$10;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$4,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$4, metadata, nextFieldsStatuses$4.contents.endDate, state.submissionStatus, validators_endDate, (function (status) {
                                    var init = nextFieldsStatuses$4.contents;
                                    return {
                                            registrationEndDate: init.registrationEndDate,
                                            registrationStartDate: init.registrationStartDate,
                                            deliveryEndDate: init.deliveryEndDate,
                                            deliveryStartDate: init.deliveryStartDate,
                                            endDate: status,
                                            startDate: init.startDate
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* UpdateStartDateField */5 :
                  var nextInput$5 = Curry._1(action._0, state.input);
                  var nextFieldsStatuses$5 = {
                    contents: state.fieldsStatuses
                  };
                  var result$11 = Formality.validateDependentFieldOnChangeWithMetadata(nextInput$5, metadata, nextFieldsStatuses$5.contents.endDate, validators_endDate, (function (status) {
                          var init = nextFieldsStatuses$5.contents;
                          return {
                                  registrationEndDate: init.registrationEndDate,
                                  registrationStartDate: init.registrationStartDate,
                                  deliveryEndDate: init.deliveryEndDate,
                                  deliveryStartDate: init.deliveryStartDate,
                                  endDate: status,
                                  startDate: init.startDate
                                };
                        }));
                  if (result$11 !== undefined) {
                    nextFieldsStatuses$5.contents = result$11;
                  }
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: nextInput$5,
                            fieldsStatuses: Formality.validateFieldOnChangeWithValidatorAndMetadata(nextInput$5, metadata, nextFieldsStatuses$5.contents.startDate, state.submissionStatus, validators_startDate, (function (status) {
                                    var init = nextFieldsStatuses$5.contents;
                                    return {
                                            registrationEndDate: init.registrationEndDate,
                                            registrationStartDate: init.registrationStartDate,
                                            deliveryEndDate: init.deliveryEndDate,
                                            deliveryStartDate: init.deliveryStartDate,
                                            endDate: init.endDate,
                                            startDate: status
                                          };
                                  })),
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: state.formStatus,
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* SetSubmittedStatus */6 :
                  var input = action._0;
                  if (input !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: input,
                              fieldsStatuses: initialFieldsStatuses(input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: initialFieldsStatuses(state.input),
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: /* Submitted */1,
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
              case /* SetSubmissionFailedStatus */7 :
                  return {
                          TAG: /* Update */0,
                          _0: {
                            input: state.input,
                            fieldsStatuses: state.fieldsStatuses,
                            collectionsStatuses: state.collectionsStatuses,
                            formStatus: {
                              TAG: /* SubmissionFailed */1,
                              _0: action._0
                            },
                            submissionStatus: state.submissionStatus
                          }
                        };
              case /* MapSubmissionError */8 :
                  var map = action._0;
                  var error$1 = state.formStatus;
                  if (typeof error$1 === "number") {
                    return /* NoUpdate */0;
                  }
                  if (error$1.TAG !== /* Submitting */0) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* SubmissionFailed */1,
                                _0: Curry._1(map, error$1._0)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  }
                  var error$2 = error$1._0;
                  if (error$2 !== undefined) {
                    return {
                            TAG: /* Update */0,
                            _0: {
                              input: state.input,
                              fieldsStatuses: state.fieldsStatuses,
                              collectionsStatuses: state.collectionsStatuses,
                              formStatus: {
                                TAG: /* Submitting */0,
                                _0: Curry._1(map, error$2)
                              },
                              submissionStatus: state.submissionStatus
                            }
                          };
                  } else {
                    return /* NoUpdate */0;
                  }
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var match$1 = state.formStatus;
  var tmp;
  tmp = typeof match$1 === "number" || match$1.TAG !== /* Submitting */0 ? false : true;
  return {
          updateRegistrationEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationEndDateField */0,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateRegistrationStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateRegistrationStartDateField */1,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDeliveryEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDeliveryEndDateField */2,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateDeliveryStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateDeliveryStartDateField */3,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateEndDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateEndDateField */4,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          updateStartDate: (function (nextInputFn, nextValue) {
              Curry._1(dispatch, {
                    TAG: /* UpdateStartDateField */5,
                    _0: (function (__x) {
                        return Curry._2(nextInputFn, __x, nextValue);
                      })
                  });
            }),
          blurRegistrationEndDate: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationEndDateField */0);
            }),
          blurRegistrationStartDate: (function (param) {
              Curry._1(dispatch, /* BlurRegistrationStartDateField */1);
            }),
          blurDeliveryEndDate: (function (param) {
              Curry._1(dispatch, /* BlurDeliveryEndDateField */2);
            }),
          blurDeliveryStartDate: (function (param) {
              Curry._1(dispatch, /* BlurDeliveryStartDateField */3);
            }),
          blurEndDate: (function (param) {
              Curry._1(dispatch, /* BlurEndDateField */4);
            }),
          blurStartDate: (function (param) {
              Curry._1(dispatch, /* BlurStartDateField */5);
            }),
          registrationEndDateResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationEndDate),
          registrationStartDateResult: Formality.exposeFieldResult(state.fieldsStatuses.registrationStartDate),
          deliveryEndDateResult: Formality.exposeFieldResult(state.fieldsStatuses.deliveryEndDate),
          deliveryStartDateResult: Formality.exposeFieldResult(state.fieldsStatuses.deliveryStartDate),
          endDateResult: Formality.exposeFieldResult(state.fieldsStatuses.endDate),
          startDateResult: Formality.exposeFieldResult(state.fieldsStatuses.startDate),
          input: state.input,
          status: state.formStatus,
          dirty: (function (param) {
              var match = state.fieldsStatuses;
              if (match.registrationEndDate || match.registrationStartDate || match.deliveryEndDate || match.deliveryStartDate || match.endDate || match.startDate) {
                return true;
              } else {
                return false;
              }
            }),
          valid: (function (param) {
              var match = validateForm(state.input, validators, state.fieldsStatuses, metadata);
              if (match.TAG === /* Valid */0) {
                return true;
              } else {
                return false;
              }
            }),
          submitting: tmp,
          submit: (function (param) {
              Curry._1(dispatch, /* Submit */6);
            }),
          dismissSubmissionError: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionError */7);
            }),
          dismissSubmissionResult: (function (param) {
              Curry._1(dispatch, /* DismissSubmissionResult */8);
            }),
          mapSubmissionError: (function (map) {
              Curry._1(dispatch, {
                    TAG: /* MapSubmissionError */8,
                    _0: map
                  });
            }),
          reset: (function (param) {
              Curry._1(dispatch, /* Reset */9);
            })
        };
}

var FlashForm = {
  validators: validators,
  initialFieldsStatuses: initialFieldsStatuses,
  initialCollectionsStatuses: undefined,
  initialState: initialState,
  validateForm: validateForm,
  useForm: useForm
};

var Types;

var initialState$1 = {
  startDate: undefined,
  endDate: undefined,
  deliveryStartDate: undefined,
  deliveryEndDate: undefined,
  registrationStartDate: undefined,
  registrationEndDate: undefined
};

export {
  Types ,
  validateDate ,
  FlashForm ,
  initialState$1 as initialState,
}
/* react Not a pure module */
