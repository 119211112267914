// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Core from "@material-ui/core";
import * as App_Typography$DvmAdminFrontendFr from "../../../../common/App_Typography.bs.js";
import * as Messages_Orders_Detail$DvmAdminFrontendFr from "../../../../intl/messages/orders/Messages_Orders_Detail.bs.js";

function solveWithVO(bonus, order) {
  var orderStatus = Belt_Option.getWithDefault(order.detail.orderStatus, /* NOTSET */6);
  var usedCarStatus = Belt_Option.getWithDefault(order.detail.usedCarStatus, /* NOTSET */6);
  var orderType = Belt_Option.getWithDefault(order.orderType, /* NOTSET */9);
  var vinMissing = Belt_Option.mapWithDefault(order.detail.vin, true, (function (x) {
          return x.length === 0;
        }));
  var renaultInvoiceDateMissing = Belt_Option.isNone(order.detail.renaultInvoiceDate);
  var withUsedCar = orderType > 8 || orderType < 1 ? Belt_Option.getWithDefault(bonus.actionType, "") === "REPR" : Belt_Option.getWithDefault(bonus.withUsedCar, false);
  var match = order.detail.delivered;
  var match$1 = bonus.state;
  if (match !== undefined) {
    if (match$1 !== undefined) {
      if (withUsedCar) {
        if (orderStatus === /* COMPLETE */0 && usedCarStatus === /* COMPLETE */0 && match$1 === /* CALCULABLE */1) {
          if (match) {
            return [];
          } else {
            return [Messages_Orders_Detail$DvmAdminFrontendFr.pending];
          }
        } else if (orderStatus === /* COMPLETE */0 && usedCarStatus === /* INCOMPLETE */1 && match$1 === /* PENDING */12) {
          if (orderType === /* SO */0) {
            return [Messages_Orders_Detail$DvmAdminFrontendFr.policeNumberNotPresent];
          } else {
            return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForData];
          }
        } else if (orderStatus === /* INCOMPLETE */1 && usedCarStatus === /* COMPLETE */0 && match$1 === /* PENDING */12) {
          if (orderType > 8 || orderType < 1) {
            if (match) {
              return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForData];
            } else if (vinMissing) {
              return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForDataVINMissingNotDelivered];
            } else if (renaultInvoiceDateMissing) {
              return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForManufacturerFacturation];
            } else {
              return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForData];
            }
          } else if (match) {
            return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForData];
          } else if (vinMissing) {
            return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForDataVINMissingNotDelivered];
          } else {
            return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForData];
          }
        } else if (orderStatus === /* INCOMPLETE */1 && usedCarStatus === /* INCOMPLETE */1 && match$1 === /* PENDING */12) {
          if (orderType > 8 || orderType < 1) {
            if (match) {
              return [
                      Messages_Orders_Detail$DvmAdminFrontendFr.waitingForData,
                      Messages_Orders_Detail$DvmAdminFrontendFr.policeNumberNotPresent
                    ];
            } else if (vinMissing) {
              return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForDataVINMissingNotDelivered];
            } else if (renaultInvoiceDateMissing) {
              return [
                      Messages_Orders_Detail$DvmAdminFrontendFr.waitingForManufacturerFacturation,
                      Messages_Orders_Detail$DvmAdminFrontendFr.policeNumberNotPresent
                    ];
            } else {
              return [
                      Messages_Orders_Detail$DvmAdminFrontendFr.waitingForData,
                      Messages_Orders_Detail$DvmAdminFrontendFr.policeNumberNotPresent
                    ];
            }
          } else {
            return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForData];
          }
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else {
      return [];
    }
  } else {
    return [];
  }
}

function solveWithoutVO(bonus, order) {
  var orderStatus = Belt_Option.getWithDefault(order.detail.orderStatus, /* NOTSET */6);
  var orderType = Belt_Option.getWithDefault(order.orderType, /* NOTSET */9);
  var vinMissing = Belt_Option.mapWithDefault(order.detail.vin, true, (function (x) {
          return x.length === 0;
        }));
  var withoutUsedCar;
  if (orderType > 8 || orderType < 1) {
    var actionType = Belt_Option.getWithDefault(bonus.actionType, "");
    withoutUsedCar = actionType === "STOC" || actionType === "PERF";
  } else {
    withoutUsedCar = !Belt_Option.getWithDefault(bonus.withUsedCar, false);
  }
  var renaultInvoiceDateMissing = Belt_Option.isNone(order.detail.renaultInvoiceDate);
  var match = order.detail.delivered;
  var match$1 = bonus.state;
  if (match !== undefined) {
    if (withoutUsedCar) {
      if (match$1 !== undefined) {
        if (orderStatus !== 1) {
          if (orderStatus !== 0) {
            return [];
          } else if (match) {
            return [];
          } else if (match$1 === /* CALCULABLE */1) {
            return [Messages_Orders_Detail$DvmAdminFrontendFr.pending];
          } else {
            return [];
          }
        } else if (match$1 === /* PENDING */12) {
          if (orderType > 8 || orderType < 1) {
            if (match) {
              return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForData];
            } else if (vinMissing) {
              return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForDataVINMissingNotDelivered];
            } else if (renaultInvoiceDateMissing) {
              return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForManufacturerFacturation];
            } else {
              return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForData];
            }
          } else if (match) {
            return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForData];
          } else if (vinMissing) {
            return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForDataVINMissingNotDelivered];
          } else {
            return [Messages_Orders_Detail$DvmAdminFrontendFr.waitingForData];
          }
        } else {
          return [];
        }
      } else {
        return [];
      }
    } else {
      return [];
    }
  } else {
    return [];
  }
}

function Order_Detail_Bonus_Box_Custom_Message(Props) {
  var bonus = Props.bonus;
  var order = Props.order;
  var intl = ReactIntl.useIntl();
  var solvedMessages = Belt_Array.concat(solveWithoutVO(bonus, order), solveWithVO(bonus, order));
  return Belt_Array.map(solvedMessages, (function (message) {
                return React.createElement(Core.Grid, {
                            children: React.createElement(App_Typography$DvmAdminFrontendFr.make, {
                                  children: intl.formatMessage(message),
                                  variant: "caption"
                                }),
                            item: true
                          });
              }));
}

var Msg;

var StatusTypes;

var Styles;

var make = Order_Detail_Bonus_Box_Custom_Message;

export {
  Msg ,
  StatusTypes ,
  Styles ,
  solveWithVO ,
  solveWithoutVO ,
  make ,
}
/* react Not a pure module */
