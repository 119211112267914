// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr from "./Order_Detail_Reselling_Edits_Form.bs.js";

function resellOrderEditable(order) {
  return Belt_Array.keep(Belt_Array.keepMap(Belt_Option.getWithDefault(order.actions, []), (function (x) {
                    return x.state;
                  })), (function (x) {
                if (x === /* PAID */4 || x === /* PREINVOICED */5) {
                  return true;
                } else {
                  return x === /* NOBONUS */3;
                }
              })).length === 0;
}

function getInitialInput(lastCorrectionValues, order) {
  var match = Belt_Option.mapWithDefault(lastCorrectionValues, [
        order.orderType,
        order.detail.siret,
        order.detail.shortCustomerName,
        order.detail.clientFirstName,
        order.detail.finalCustomerLLD,
        order.detail.registrationDate,
        order.detail.customerInvoiceDate,
        order.detail.customerInvoiceNumber,
        order.detail.discount,
        order.detail.orderDate,
        order.detail.deliveryDate
      ], (function (lastCorr) {
          return [
                  lastCorr.orderType,
                  lastCorr.siret,
                  lastCorr.shortCustomerName,
                  lastCorr.customerFirstName,
                  lastCorr.sirenFinalCustomerLLD,
                  lastCorr.newCarRegistrationDate,
                  lastCorr.customerInvoiceDate,
                  lastCorr.customerInvoiceNumber,
                  lastCorr.customerDiscount,
                  lastCorr.orderDate,
                  lastCorr.newCarDeliveryDateToCustomer
                ];
        }));
  return {
          orderType: Belt_Option.getWithDefault(match[0], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.orderType),
          siret: Belt_Option.getWithDefault(match[1], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.siret),
          finalCustomer: Belt_Option.getWithDefault(match[4], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.finalCustomer),
          shortCustomerName: Belt_Option.getWithDefault(match[2], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.shortCustomerName),
          customerFirstName: Belt_Option.getWithDefault(match[3], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.customerFirstName),
          orderDate: match[9],
          registrationDate: match[5],
          deliveryDate: match[10],
          invoiceNumber: Belt_Option.getWithDefault(match[7], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.invoiceNumber),
          invoiceClient: match[6],
          customerDiscount: Belt_Option.mapWithDefault(match[8], Order_Detail_Reselling_Edits_Form$DvmAdminFrontendFr.initialState.customerDiscount, (function (x) {
                  return String(x);
                }))
        };
}

function postWithLatestCorrection(lastCorrectionValues, $$final, orderId) {
  return {
          orderID: orderId,
          orderType: $$final.orderType,
          timeStamp: undefined,
          editor: undefined,
          siret: $$final.siret,
          sirenFinalCustomerLLD: $$final.finalCustomer,
          companyDiscountPercentage: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.companyDiscountPercentage;
                })),
          acquisitionType: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.acquisitionType;
                })),
          budgetType: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.budgetType;
                })),
          newCarRegistrationID: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.newCarRegistrationID;
                })),
          newCarRegistrationDate: $$final.registrationDate,
          customerInvoiceDate: $$final.invoiceClient,
          usedCarPoliceNumber: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.usedCarPoliceNumber;
                })),
          usedCarRegistrationID: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.usedCarRegistrationID;
                })),
          usedCarLastRegistrationDate: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.usedCarLastRegistrationDate;
                })),
          usedCarFirstReleaseDate: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.usedCarFirstReleaseDate;
                })),
          brandUsedCar: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.brandUsedCar;
                })),
          modelUsedCar: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.modelUsedCar;
                })),
          versionUsedCar: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.versionUsedCar;
                })),
          vinUsedCar: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.vinUsedCar;
                })),
          realTradeAmount: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.realTradeAmount;
                })),
          estimatedTradeAmount: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.estimatedTradeAmount;
                })),
          scrapyardIndicator: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.scrapyardIndicator;
                })),
          customerInvoiceNumber: $$final.invoiceNumber,
          comment: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.comment;
                })),
          bonusActionTypePERF: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.bonusActionTypePERF;
                })),
          bonusActionTypePERF2: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.bonusActionTypePERF2;
                })),
          bonusActionTypePERF3: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.bonusActionTypePERF3;
                })),
          planPerfAmount: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.planPerfAmount;
                })),
          planPerfAmount2: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.planPerfAmount2;
                })),
          planPerfAmount3: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.planPerfAmount3;
                })),
          planPerfID: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.planPerfID;
                })),
          planPerfID2: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.planPerfID2;
                })),
          planPerfID3: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.planPerfID3;
                })),
          planPerfLabel1: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.planPerfLabel1;
                })),
          planPerfLabel2: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.planPerfLabel2;
                })),
          planPerfLabel3: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.planPerfLabel3;
                })),
          customerDiscount: $$final.customerDiscount,
          easyPack: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.easyPack;
                })),
          shortCustomerName: $$final.shortCustomerName,
          customerFirstName: $$final.customerFirstName,
          parcStartDate: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.parcStartDate;
                })),
          parcEndDate: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.parcEndDate;
                })),
          orderDate: $$final.orderDate,
          renaultInvoiceDate: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.renaultInvoiceDate;
                })),
          carSaleAvailibilityDateMADA: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.carSaleAvailibilityDateMADA;
                })),
          carSaleAvailibilityDateMADC: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.carSaleAvailibilityDateMADC;
                })),
          modelID: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.modelID;
                })),
          versionID: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.versionID;
                })),
          phase: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.phase;
                })),
          salesmanName: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.salesmanName;
                })),
          agentCode: Belt_Option.flatMap(lastCorrectionValues, (function (x) {
                  return x.agentCode;
                })),
          newCarDeliveryDateToCustomer: $$final.deliveryDate
        };
}

var Form;

export {
  Form ,
  resellOrderEditable ,
  getInitialInput ,
  postWithLatestCorrection ,
}
/* Order_Detail_Reselling_Edits_Form-DvmAdminFrontendFr Not a pure module */
