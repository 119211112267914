// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../../common/types/App_Types_Date.bs.js";
import * as Order_Detail_Types_Order_Type$DvmAdminFrontendFr from "../Order_Detail_Types_Order_Type.bs.js";

function orderCorrection_encode(v) {
  return Js_dict.fromArray([
              [
                "orderID",
                Decco.stringToJson(v.orderID)
              ],
              [
                "orderType",
                Decco.optionToJson(Order_Detail_Types_Order_Type$DvmAdminFrontendFr.Decco.t_encode, v.orderType)
              ],
              [
                "timeStamp",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.timeStamp)
              ],
              [
                "editor",
                Decco.optionToJson(Decco.stringToJson, v.editor)
              ],
              [
                "siret",
                Decco.optionToJson(Decco.stringToJson, v.siret)
              ],
              [
                "sirenFinalCustomerLLD",
                Decco.optionToJson(Decco.stringToJson, v.sirenFinalCustomerLLD)
              ],
              [
                "companyDiscountPercentage",
                Decco.optionToJson(Decco.floatToJson, v.companyDiscountPercentage)
              ],
              [
                "acquisitionType",
                Decco.optionToJson(Decco.stringToJson, v.acquisitionType)
              ],
              [
                "budgetType",
                Decco.optionToJson(Decco.stringToJson, v.budgetType)
              ],
              [
                "newCarRegistrationID",
                Decco.optionToJson(Decco.stringToJson, v.newCarRegistrationID)
              ],
              [
                "newCarRegistrationDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.newCarRegistrationDate)
              ],
              [
                "customerInvoiceDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.customerInvoiceDate)
              ],
              [
                "usedCarPoliceNumber",
                Decco.optionToJson(Decco.stringToJson, v.usedCarPoliceNumber)
              ],
              [
                "usedCarRegistrationID",
                Decco.optionToJson(Decco.stringToJson, v.usedCarRegistrationID)
              ],
              [
                "usedCarLastRegistrationDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.usedCarLastRegistrationDate)
              ],
              [
                "usedCarFirstReleaseDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.usedCarFirstReleaseDate)
              ],
              [
                "brandUsedCar",
                Decco.optionToJson(Decco.stringToJson, v.brandUsedCar)
              ],
              [
                "modelUsedCar",
                Decco.optionToJson(Decco.stringToJson, v.modelUsedCar)
              ],
              [
                "versionUsedCar",
                Decco.optionToJson(Decco.stringToJson, v.versionUsedCar)
              ],
              [
                "vinUsedCar",
                Decco.optionToJson(Decco.stringToJson, v.vinUsedCar)
              ],
              [
                "realTradeAmount",
                Decco.optionToJson(Decco.floatToJson, v.realTradeAmount)
              ],
              [
                "estimatedTradeAmount",
                Decco.optionToJson(Decco.floatToJson, v.estimatedTradeAmount)
              ],
              [
                "scrapyardIndicator",
                Decco.optionToJson(Decco.boolToJson, v.scrapyardIndicator)
              ],
              [
                "customerInvoiceNumber",
                Decco.optionToJson(Decco.stringToJson, v.customerInvoiceNumber)
              ],
              [
                "comment",
                Decco.optionToJson(Decco.stringToJson, v.comment)
              ],
              [
                "bonusActionTypePERF",
                Decco.optionToJson(Decco.stringToJson, v.bonusActionTypePERF)
              ],
              [
                "bonusActionTypePERF2",
                Decco.optionToJson(Decco.stringToJson, v.bonusActionTypePERF2)
              ],
              [
                "bonusActionTypePERF3",
                Decco.optionToJson(Decco.stringToJson, v.bonusActionTypePERF3)
              ],
              [
                "planPerfAmount",
                Decco.optionToJson(Decco.floatToJson, v.planPerfAmount)
              ],
              [
                "planPerfAmount2",
                Decco.optionToJson(Decco.floatToJson, v.planPerfAmount2)
              ],
              [
                "planPerfAmount3",
                Decco.optionToJson(Decco.floatToJson, v.planPerfAmount3)
              ],
              [
                "planPerfID",
                Decco.optionToJson(Decco.stringToJson, v.planPerfID)
              ],
              [
                "planPerfID2",
                Decco.optionToJson(Decco.stringToJson, v.planPerfID2)
              ],
              [
                "planPerfID3",
                Decco.optionToJson(Decco.stringToJson, v.planPerfID3)
              ],
              [
                "planPerfLabel1",
                Decco.optionToJson(Decco.stringToJson, v.planPerfLabel1)
              ],
              [
                "planPerfLabel2",
                Decco.optionToJson(Decco.stringToJson, v.planPerfLabel2)
              ],
              [
                "planPerfLabel3",
                Decco.optionToJson(Decco.stringToJson, v.planPerfLabel3)
              ],
              [
                "customerDiscount",
                Decco.optionToJson(Decco.floatToJson, v.customerDiscount)
              ],
              [
                "easyPack",
                Decco.optionToJson(Decco.stringToJson, v.easyPack)
              ],
              [
                "shortCustomerName",
                Decco.optionToJson(Decco.stringToJson, v.shortCustomerName)
              ],
              [
                "customerFirstName",
                Decco.optionToJson(Decco.stringToJson, v.customerFirstName)
              ],
              [
                "parcStartDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.parcStartDate)
              ],
              [
                "parcEndDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.parcEndDate)
              ],
              [
                "orderDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.orderDate)
              ],
              [
                "renaultInvoiceDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.renaultInvoiceDate)
              ],
              [
                "carSaleAvailibilityDateMADA",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.carSaleAvailibilityDateMADA)
              ],
              [
                "carSaleAvailibilityDateMADC",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.carSaleAvailibilityDateMADC)
              ],
              [
                "modelID",
                Decco.optionToJson(Decco.stringToJson, v.modelID)
              ],
              [
                "versionID",
                Decco.optionToJson(Decco.stringToJson, v.versionID)
              ],
              [
                "phase",
                Decco.optionToJson(Decco.stringToJson, v.phase)
              ],
              [
                "salesmanName",
                Decco.optionToJson(Decco.stringToJson, v.salesmanName)
              ],
              [
                "agentCode",
                Decco.optionToJson(Decco.stringToJson, v.agentCode)
              ],
              [
                "newCarDeliveryDateToCustomer",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.newCarDeliveryDateToCustomer)
              ]
            ]);
}

function orderCorrection_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var orderID = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderID"), null));
  if (orderID.TAG === /* Ok */0) {
    var orderType = Decco.optionFromJson(Order_Detail_Types_Order_Type$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderType"), null));
    if (orderType.TAG === /* Ok */0) {
      var timeStamp = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "timeStamp"), null));
      if (timeStamp.TAG === /* Ok */0) {
        var editor = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "editor"), null));
        if (editor.TAG === /* Ok */0) {
          var siret = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "siret"), null));
          if (siret.TAG === /* Ok */0) {
            var sirenFinalCustomerLLD = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "sirenFinalCustomerLLD"), null));
            if (sirenFinalCustomerLLD.TAG === /* Ok */0) {
              var companyDiscountPercentage = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "companyDiscountPercentage"), null));
              if (companyDiscountPercentage.TAG === /* Ok */0) {
                var acquisitionType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "acquisitionType"), null));
                if (acquisitionType.TAG === /* Ok */0) {
                  var budgetType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetType"), null));
                  if (budgetType.TAG === /* Ok */0) {
                    var newCarRegistrationID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "newCarRegistrationID"), null));
                    if (newCarRegistrationID.TAG === /* Ok */0) {
                      var newCarRegistrationDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "newCarRegistrationDate"), null));
                      if (newCarRegistrationDate.TAG === /* Ok */0) {
                        var customerInvoiceDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customerInvoiceDate"), null));
                        if (customerInvoiceDate.TAG === /* Ok */0) {
                          var usedCarPoliceNumber = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "usedCarPoliceNumber"), null));
                          if (usedCarPoliceNumber.TAG === /* Ok */0) {
                            var usedCarRegistrationID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "usedCarRegistrationID"), null));
                            if (usedCarRegistrationID.TAG === /* Ok */0) {
                              var usedCarLastRegistrationDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "usedCarLastRegistrationDate"), null));
                              if (usedCarLastRegistrationDate.TAG === /* Ok */0) {
                                var usedCarFirstReleaseDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "usedCarFirstReleaseDate"), null));
                                if (usedCarFirstReleaseDate.TAG === /* Ok */0) {
                                  var brandUsedCar = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "brandUsedCar"), null));
                                  if (brandUsedCar.TAG === /* Ok */0) {
                                    var modelUsedCar = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelUsedCar"), null));
                                    if (modelUsedCar.TAG === /* Ok */0) {
                                      var versionUsedCar = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionUsedCar"), null));
                                      if (versionUsedCar.TAG === /* Ok */0) {
                                        var vinUsedCar = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "vinUsedCar"), null));
                                        if (vinUsedCar.TAG === /* Ok */0) {
                                          var realTradeAmount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "realTradeAmount"), null));
                                          if (realTradeAmount.TAG === /* Ok */0) {
                                            var estimatedTradeAmount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "estimatedTradeAmount"), null));
                                            if (estimatedTradeAmount.TAG === /* Ok */0) {
                                              var scrapyardIndicator = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "scrapyardIndicator"), null));
                                              if (scrapyardIndicator.TAG === /* Ok */0) {
                                                var customerInvoiceNumber = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customerInvoiceNumber"), null));
                                                if (customerInvoiceNumber.TAG === /* Ok */0) {
                                                  var comment = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "comment"), null));
                                                  if (comment.TAG === /* Ok */0) {
                                                    var bonusActionTypePERF = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "bonusActionTypePERF"), null));
                                                    if (bonusActionTypePERF.TAG === /* Ok */0) {
                                                      var bonusActionTypePERF2 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "bonusActionTypePERF2"), null));
                                                      if (bonusActionTypePERF2.TAG === /* Ok */0) {
                                                        var bonusActionTypePERF3 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "bonusActionTypePERF3"), null));
                                                        if (bonusActionTypePERF3.TAG === /* Ok */0) {
                                                          var planPerfAmount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "planPerfAmount"), null));
                                                          if (planPerfAmount.TAG === /* Ok */0) {
                                                            var planPerfAmount2 = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "planPerfAmount2"), null));
                                                            if (planPerfAmount2.TAG === /* Ok */0) {
                                                              var planPerfAmount3 = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "planPerfAmount3"), null));
                                                              if (planPerfAmount3.TAG === /* Ok */0) {
                                                                var planPerfID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "planPerfID"), null));
                                                                if (planPerfID.TAG === /* Ok */0) {
                                                                  var planPerfID2 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "planPerfID2"), null));
                                                                  if (planPerfID2.TAG === /* Ok */0) {
                                                                    var planPerfID3 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "planPerfID3"), null));
                                                                    if (planPerfID3.TAG === /* Ok */0) {
                                                                      var planPerfLabel1 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "planPerfLabel1"), null));
                                                                      if (planPerfLabel1.TAG === /* Ok */0) {
                                                                        var planPerfLabel2 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "planPerfLabel2"), null));
                                                                        if (planPerfLabel2.TAG === /* Ok */0) {
                                                                          var planPerfLabel3 = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "planPerfLabel3"), null));
                                                                          if (planPerfLabel3.TAG === /* Ok */0) {
                                                                            var customerDiscount = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customerDiscount"), null));
                                                                            if (customerDiscount.TAG === /* Ok */0) {
                                                                              var easyPack = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "easyPack"), null));
                                                                              if (easyPack.TAG === /* Ok */0) {
                                                                                var shortCustomerName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "shortCustomerName"), null));
                                                                                if (shortCustomerName.TAG === /* Ok */0) {
                                                                                  var customerFirstName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "customerFirstName"), null));
                                                                                  if (customerFirstName.TAG === /* Ok */0) {
                                                                                    var parcStartDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "parcStartDate"), null));
                                                                                    if (parcStartDate.TAG === /* Ok */0) {
                                                                                      var parcEndDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "parcEndDate"), null));
                                                                                      if (parcEndDate.TAG === /* Ok */0) {
                                                                                        var orderDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderDate"), null));
                                                                                        if (orderDate.TAG === /* Ok */0) {
                                                                                          var renaultInvoiceDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "renaultInvoiceDate"), null));
                                                                                          if (renaultInvoiceDate.TAG === /* Ok */0) {
                                                                                            var carSaleAvailibilityDateMADA = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "carSaleAvailibilityDateMADA"), null));
                                                                                            if (carSaleAvailibilityDateMADA.TAG === /* Ok */0) {
                                                                                              var carSaleAvailibilityDateMADC = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "carSaleAvailibilityDateMADC"), null));
                                                                                              if (carSaleAvailibilityDateMADC.TAG === /* Ok */0) {
                                                                                                var modelID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "modelID"), null));
                                                                                                if (modelID.TAG === /* Ok */0) {
                                                                                                  var versionID = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "versionID"), null));
                                                                                                  if (versionID.TAG === /* Ok */0) {
                                                                                                    var phase = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "phase"), null));
                                                                                                    if (phase.TAG === /* Ok */0) {
                                                                                                      var salesmanName = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "salesmanName"), null));
                                                                                                      if (salesmanName.TAG === /* Ok */0) {
                                                                                                        var agentCode = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "agentCode"), null));
                                                                                                        if (agentCode.TAG === /* Ok */0) {
                                                                                                          var newCarDeliveryDateToCustomer = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "newCarDeliveryDateToCustomer"), null));
                                                                                                          if (newCarDeliveryDateToCustomer.TAG === /* Ok */0) {
                                                                                                            return {
                                                                                                                    TAG: /* Ok */0,
                                                                                                                    _0: {
                                                                                                                      orderID: orderID._0,
                                                                                                                      orderType: orderType._0,
                                                                                                                      timeStamp: timeStamp._0,
                                                                                                                      editor: editor._0,
                                                                                                                      siret: siret._0,
                                                                                                                      sirenFinalCustomerLLD: sirenFinalCustomerLLD._0,
                                                                                                                      companyDiscountPercentage: companyDiscountPercentage._0,
                                                                                                                      acquisitionType: acquisitionType._0,
                                                                                                                      budgetType: budgetType._0,
                                                                                                                      newCarRegistrationID: newCarRegistrationID._0,
                                                                                                                      newCarRegistrationDate: newCarRegistrationDate._0,
                                                                                                                      customerInvoiceDate: customerInvoiceDate._0,
                                                                                                                      usedCarPoliceNumber: usedCarPoliceNumber._0,
                                                                                                                      usedCarRegistrationID: usedCarRegistrationID._0,
                                                                                                                      usedCarLastRegistrationDate: usedCarLastRegistrationDate._0,
                                                                                                                      usedCarFirstReleaseDate: usedCarFirstReleaseDate._0,
                                                                                                                      brandUsedCar: brandUsedCar._0,
                                                                                                                      modelUsedCar: modelUsedCar._0,
                                                                                                                      versionUsedCar: versionUsedCar._0,
                                                                                                                      vinUsedCar: vinUsedCar._0,
                                                                                                                      realTradeAmount: realTradeAmount._0,
                                                                                                                      estimatedTradeAmount: estimatedTradeAmount._0,
                                                                                                                      scrapyardIndicator: scrapyardIndicator._0,
                                                                                                                      customerInvoiceNumber: customerInvoiceNumber._0,
                                                                                                                      comment: comment._0,
                                                                                                                      bonusActionTypePERF: bonusActionTypePERF._0,
                                                                                                                      bonusActionTypePERF2: bonusActionTypePERF2._0,
                                                                                                                      bonusActionTypePERF3: bonusActionTypePERF3._0,
                                                                                                                      planPerfAmount: planPerfAmount._0,
                                                                                                                      planPerfAmount2: planPerfAmount2._0,
                                                                                                                      planPerfAmount3: planPerfAmount3._0,
                                                                                                                      planPerfID: planPerfID._0,
                                                                                                                      planPerfID2: planPerfID2._0,
                                                                                                                      planPerfID3: planPerfID3._0,
                                                                                                                      planPerfLabel1: planPerfLabel1._0,
                                                                                                                      planPerfLabel2: planPerfLabel2._0,
                                                                                                                      planPerfLabel3: planPerfLabel3._0,
                                                                                                                      customerDiscount: customerDiscount._0,
                                                                                                                      easyPack: easyPack._0,
                                                                                                                      shortCustomerName: shortCustomerName._0,
                                                                                                                      customerFirstName: customerFirstName._0,
                                                                                                                      parcStartDate: parcStartDate._0,
                                                                                                                      parcEndDate: parcEndDate._0,
                                                                                                                      orderDate: orderDate._0,
                                                                                                                      renaultInvoiceDate: renaultInvoiceDate._0,
                                                                                                                      carSaleAvailibilityDateMADA: carSaleAvailibilityDateMADA._0,
                                                                                                                      carSaleAvailibilityDateMADC: carSaleAvailibilityDateMADC._0,
                                                                                                                      modelID: modelID._0,
                                                                                                                      versionID: versionID._0,
                                                                                                                      phase: phase._0,
                                                                                                                      salesmanName: salesmanName._0,
                                                                                                                      agentCode: agentCode._0,
                                                                                                                      newCarDeliveryDateToCustomer: newCarDeliveryDateToCustomer._0
                                                                                                                    }
                                                                                                                  };
                                                                                                          }
                                                                                                          var e = newCarDeliveryDateToCustomer._0;
                                                                                                          return {
                                                                                                                  TAG: /* Error */1,
                                                                                                                  _0: {
                                                                                                                    path: ".newCarDeliveryDateToCustomer" + e.path,
                                                                                                                    message: e.message,
                                                                                                                    value: e.value
                                                                                                                  }
                                                                                                                };
                                                                                                        }
                                                                                                        var e$1 = agentCode._0;
                                                                                                        return {
                                                                                                                TAG: /* Error */1,
                                                                                                                _0: {
                                                                                                                  path: ".agentCode" + e$1.path,
                                                                                                                  message: e$1.message,
                                                                                                                  value: e$1.value
                                                                                                                }
                                                                                                              };
                                                                                                      }
                                                                                                      var e$2 = salesmanName._0;
                                                                                                      return {
                                                                                                              TAG: /* Error */1,
                                                                                                              _0: {
                                                                                                                path: ".salesmanName" + e$2.path,
                                                                                                                message: e$2.message,
                                                                                                                value: e$2.value
                                                                                                              }
                                                                                                            };
                                                                                                    }
                                                                                                    var e$3 = phase._0;
                                                                                                    return {
                                                                                                            TAG: /* Error */1,
                                                                                                            _0: {
                                                                                                              path: ".phase" + e$3.path,
                                                                                                              message: e$3.message,
                                                                                                              value: e$3.value
                                                                                                            }
                                                                                                          };
                                                                                                  }
                                                                                                  var e$4 = versionID._0;
                                                                                                  return {
                                                                                                          TAG: /* Error */1,
                                                                                                          _0: {
                                                                                                            path: ".versionID" + e$4.path,
                                                                                                            message: e$4.message,
                                                                                                            value: e$4.value
                                                                                                          }
                                                                                                        };
                                                                                                }
                                                                                                var e$5 = modelID._0;
                                                                                                return {
                                                                                                        TAG: /* Error */1,
                                                                                                        _0: {
                                                                                                          path: ".modelID" + e$5.path,
                                                                                                          message: e$5.message,
                                                                                                          value: e$5.value
                                                                                                        }
                                                                                                      };
                                                                                              }
                                                                                              var e$6 = carSaleAvailibilityDateMADC._0;
                                                                                              return {
                                                                                                      TAG: /* Error */1,
                                                                                                      _0: {
                                                                                                        path: ".carSaleAvailibilityDateMADC" + e$6.path,
                                                                                                        message: e$6.message,
                                                                                                        value: e$6.value
                                                                                                      }
                                                                                                    };
                                                                                            }
                                                                                            var e$7 = carSaleAvailibilityDateMADA._0;
                                                                                            return {
                                                                                                    TAG: /* Error */1,
                                                                                                    _0: {
                                                                                                      path: ".carSaleAvailibilityDateMADA" + e$7.path,
                                                                                                      message: e$7.message,
                                                                                                      value: e$7.value
                                                                                                    }
                                                                                                  };
                                                                                          }
                                                                                          var e$8 = renaultInvoiceDate._0;
                                                                                          return {
                                                                                                  TAG: /* Error */1,
                                                                                                  _0: {
                                                                                                    path: ".renaultInvoiceDate" + e$8.path,
                                                                                                    message: e$8.message,
                                                                                                    value: e$8.value
                                                                                                  }
                                                                                                };
                                                                                        }
                                                                                        var e$9 = orderDate._0;
                                                                                        return {
                                                                                                TAG: /* Error */1,
                                                                                                _0: {
                                                                                                  path: ".orderDate" + e$9.path,
                                                                                                  message: e$9.message,
                                                                                                  value: e$9.value
                                                                                                }
                                                                                              };
                                                                                      }
                                                                                      var e$10 = parcEndDate._0;
                                                                                      return {
                                                                                              TAG: /* Error */1,
                                                                                              _0: {
                                                                                                path: ".parcEndDate" + e$10.path,
                                                                                                message: e$10.message,
                                                                                                value: e$10.value
                                                                                              }
                                                                                            };
                                                                                    }
                                                                                    var e$11 = parcStartDate._0;
                                                                                    return {
                                                                                            TAG: /* Error */1,
                                                                                            _0: {
                                                                                              path: ".parcStartDate" + e$11.path,
                                                                                              message: e$11.message,
                                                                                              value: e$11.value
                                                                                            }
                                                                                          };
                                                                                  }
                                                                                  var e$12 = customerFirstName._0;
                                                                                  return {
                                                                                          TAG: /* Error */1,
                                                                                          _0: {
                                                                                            path: ".customerFirstName" + e$12.path,
                                                                                            message: e$12.message,
                                                                                            value: e$12.value
                                                                                          }
                                                                                        };
                                                                                }
                                                                                var e$13 = shortCustomerName._0;
                                                                                return {
                                                                                        TAG: /* Error */1,
                                                                                        _0: {
                                                                                          path: ".shortCustomerName" + e$13.path,
                                                                                          message: e$13.message,
                                                                                          value: e$13.value
                                                                                        }
                                                                                      };
                                                                              }
                                                                              var e$14 = easyPack._0;
                                                                              return {
                                                                                      TAG: /* Error */1,
                                                                                      _0: {
                                                                                        path: ".easyPack" + e$14.path,
                                                                                        message: e$14.message,
                                                                                        value: e$14.value
                                                                                      }
                                                                                    };
                                                                            }
                                                                            var e$15 = customerDiscount._0;
                                                                            return {
                                                                                    TAG: /* Error */1,
                                                                                    _0: {
                                                                                      path: ".customerDiscount" + e$15.path,
                                                                                      message: e$15.message,
                                                                                      value: e$15.value
                                                                                    }
                                                                                  };
                                                                          }
                                                                          var e$16 = planPerfLabel3._0;
                                                                          return {
                                                                                  TAG: /* Error */1,
                                                                                  _0: {
                                                                                    path: ".planPerfLabel3" + e$16.path,
                                                                                    message: e$16.message,
                                                                                    value: e$16.value
                                                                                  }
                                                                                };
                                                                        }
                                                                        var e$17 = planPerfLabel2._0;
                                                                        return {
                                                                                TAG: /* Error */1,
                                                                                _0: {
                                                                                  path: ".planPerfLabel2" + e$17.path,
                                                                                  message: e$17.message,
                                                                                  value: e$17.value
                                                                                }
                                                                              };
                                                                      }
                                                                      var e$18 = planPerfLabel1._0;
                                                                      return {
                                                                              TAG: /* Error */1,
                                                                              _0: {
                                                                                path: ".planPerfLabel1" + e$18.path,
                                                                                message: e$18.message,
                                                                                value: e$18.value
                                                                              }
                                                                            };
                                                                    }
                                                                    var e$19 = planPerfID3._0;
                                                                    return {
                                                                            TAG: /* Error */1,
                                                                            _0: {
                                                                              path: ".planPerfID3" + e$19.path,
                                                                              message: e$19.message,
                                                                              value: e$19.value
                                                                            }
                                                                          };
                                                                  }
                                                                  var e$20 = planPerfID2._0;
                                                                  return {
                                                                          TAG: /* Error */1,
                                                                          _0: {
                                                                            path: ".planPerfID2" + e$20.path,
                                                                            message: e$20.message,
                                                                            value: e$20.value
                                                                          }
                                                                        };
                                                                }
                                                                var e$21 = planPerfID._0;
                                                                return {
                                                                        TAG: /* Error */1,
                                                                        _0: {
                                                                          path: ".planPerfID" + e$21.path,
                                                                          message: e$21.message,
                                                                          value: e$21.value
                                                                        }
                                                                      };
                                                              }
                                                              var e$22 = planPerfAmount3._0;
                                                              return {
                                                                      TAG: /* Error */1,
                                                                      _0: {
                                                                        path: ".planPerfAmount3" + e$22.path,
                                                                        message: e$22.message,
                                                                        value: e$22.value
                                                                      }
                                                                    };
                                                            }
                                                            var e$23 = planPerfAmount2._0;
                                                            return {
                                                                    TAG: /* Error */1,
                                                                    _0: {
                                                                      path: ".planPerfAmount2" + e$23.path,
                                                                      message: e$23.message,
                                                                      value: e$23.value
                                                                    }
                                                                  };
                                                          }
                                                          var e$24 = planPerfAmount._0;
                                                          return {
                                                                  TAG: /* Error */1,
                                                                  _0: {
                                                                    path: ".planPerfAmount" + e$24.path,
                                                                    message: e$24.message,
                                                                    value: e$24.value
                                                                  }
                                                                };
                                                        }
                                                        var e$25 = bonusActionTypePERF3._0;
                                                        return {
                                                                TAG: /* Error */1,
                                                                _0: {
                                                                  path: ".bonusActionTypePERF3" + e$25.path,
                                                                  message: e$25.message,
                                                                  value: e$25.value
                                                                }
                                                              };
                                                      }
                                                      var e$26 = bonusActionTypePERF2._0;
                                                      return {
                                                              TAG: /* Error */1,
                                                              _0: {
                                                                path: ".bonusActionTypePERF2" + e$26.path,
                                                                message: e$26.message,
                                                                value: e$26.value
                                                              }
                                                            };
                                                    }
                                                    var e$27 = bonusActionTypePERF._0;
                                                    return {
                                                            TAG: /* Error */1,
                                                            _0: {
                                                              path: ".bonusActionTypePERF" + e$27.path,
                                                              message: e$27.message,
                                                              value: e$27.value
                                                            }
                                                          };
                                                  }
                                                  var e$28 = comment._0;
                                                  return {
                                                          TAG: /* Error */1,
                                                          _0: {
                                                            path: ".comment" + e$28.path,
                                                            message: e$28.message,
                                                            value: e$28.value
                                                          }
                                                        };
                                                }
                                                var e$29 = customerInvoiceNumber._0;
                                                return {
                                                        TAG: /* Error */1,
                                                        _0: {
                                                          path: ".customerInvoiceNumber" + e$29.path,
                                                          message: e$29.message,
                                                          value: e$29.value
                                                        }
                                                      };
                                              }
                                              var e$30 = scrapyardIndicator._0;
                                              return {
                                                      TAG: /* Error */1,
                                                      _0: {
                                                        path: ".scrapyardIndicator" + e$30.path,
                                                        message: e$30.message,
                                                        value: e$30.value
                                                      }
                                                    };
                                            }
                                            var e$31 = estimatedTradeAmount._0;
                                            return {
                                                    TAG: /* Error */1,
                                                    _0: {
                                                      path: ".estimatedTradeAmount" + e$31.path,
                                                      message: e$31.message,
                                                      value: e$31.value
                                                    }
                                                  };
                                          }
                                          var e$32 = realTradeAmount._0;
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: {
                                                    path: ".realTradeAmount" + e$32.path,
                                                    message: e$32.message,
                                                    value: e$32.value
                                                  }
                                                };
                                        }
                                        var e$33 = vinUsedCar._0;
                                        return {
                                                TAG: /* Error */1,
                                                _0: {
                                                  path: ".vinUsedCar" + e$33.path,
                                                  message: e$33.message,
                                                  value: e$33.value
                                                }
                                              };
                                      }
                                      var e$34 = versionUsedCar._0;
                                      return {
                                              TAG: /* Error */1,
                                              _0: {
                                                path: ".versionUsedCar" + e$34.path,
                                                message: e$34.message,
                                                value: e$34.value
                                              }
                                            };
                                    }
                                    var e$35 = modelUsedCar._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".modelUsedCar" + e$35.path,
                                              message: e$35.message,
                                              value: e$35.value
                                            }
                                          };
                                  }
                                  var e$36 = brandUsedCar._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".brandUsedCar" + e$36.path,
                                            message: e$36.message,
                                            value: e$36.value
                                          }
                                        };
                                }
                                var e$37 = usedCarFirstReleaseDate._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".usedCarFirstReleaseDate" + e$37.path,
                                          message: e$37.message,
                                          value: e$37.value
                                        }
                                      };
                              }
                              var e$38 = usedCarLastRegistrationDate._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".usedCarLastRegistrationDate" + e$38.path,
                                        message: e$38.message,
                                        value: e$38.value
                                      }
                                    };
                            }
                            var e$39 = usedCarRegistrationID._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".usedCarRegistrationID" + e$39.path,
                                      message: e$39.message,
                                      value: e$39.value
                                    }
                                  };
                          }
                          var e$40 = usedCarPoliceNumber._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".usedCarPoliceNumber" + e$40.path,
                                    message: e$40.message,
                                    value: e$40.value
                                  }
                                };
                        }
                        var e$41 = customerInvoiceDate._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".customerInvoiceDate" + e$41.path,
                                  message: e$41.message,
                                  value: e$41.value
                                }
                              };
                      }
                      var e$42 = newCarRegistrationDate._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".newCarRegistrationDate" + e$42.path,
                                message: e$42.message,
                                value: e$42.value
                              }
                            };
                    }
                    var e$43 = newCarRegistrationID._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".newCarRegistrationID" + e$43.path,
                              message: e$43.message,
                              value: e$43.value
                            }
                          };
                  }
                  var e$44 = budgetType._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".budgetType" + e$44.path,
                            message: e$44.message,
                            value: e$44.value
                          }
                        };
                }
                var e$45 = acquisitionType._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".acquisitionType" + e$45.path,
                          message: e$45.message,
                          value: e$45.value
                        }
                      };
              }
              var e$46 = companyDiscountPercentage._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".companyDiscountPercentage" + e$46.path,
                        message: e$46.message,
                        value: e$46.value
                      }
                    };
            }
            var e$47 = sirenFinalCustomerLLD._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".sirenFinalCustomerLLD" + e$47.path,
                      message: e$47.message,
                      value: e$47.value
                    }
                  };
          }
          var e$48 = siret._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".siret" + e$48.path,
                    message: e$48.message,
                    value: e$48.value
                  }
                };
        }
        var e$49 = editor._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".editor" + e$49.path,
                  message: e$49.message,
                  value: e$49.value
                }
              };
      }
      var e$50 = timeStamp._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".timeStamp" + e$50.path,
                message: e$50.message,
                value: e$50.value
              }
            };
    }
    var e$51 = orderType._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".orderType" + e$51.path,
              message: e$51.message,
              value: e$51.value
            }
          };
  }
  var e$52 = orderID._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".orderID" + e$52.path,
            message: e$52.message,
            value: e$52.value
          }
        };
}

function searchResult_encode(v) {
  return Js_dict.fromArray([
              [
                "content",
                Decco.listToJson(orderCorrection_encode, v.content)
              ],
              [
                "total",
                Decco.intToJson(v.total)
              ]
            ]);
}

function searchResult_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var content = Decco.listFromJson(orderCorrection_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "content"), null));
  if (content.TAG === /* Ok */0) {
    var total = Decco.intFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "total"), null));
    if (total.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                content: content._0,
                total: total._0
              }
            };
    }
    var e = total._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".total" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = content._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".content" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function perfActionItem_encode(v) {
  return Js_dict.fromArray([
              [
                "actionId",
                Decco.stringToJson(v.actionId)
              ],
              [
                "actionLabel",
                Decco.optionToJson(Decco.stringToJson, v.actionLabel)
              ]
            ]);
}

function perfActionItem_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var actionId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionId"), null));
  if (actionId.TAG === /* Ok */0) {
    var actionLabel = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionLabel"), null));
    if (actionLabel.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                actionId: actionId._0,
                actionLabel: actionLabel._0
              }
            };
    }
    var e = actionLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".actionLabel" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = actionId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".actionId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function perfActionList_encode(v) {
  return Decco.listToJson(perfActionItem_encode, v);
}

function perfActionList_decode(v) {
  return Decco.listFromJson(perfActionItem_decode, v);
}

export {
  orderCorrection_encode ,
  orderCorrection_decode ,
  searchResult_encode ,
  searchResult_decode ,
  perfActionItem_encode ,
  perfActionItem_decode ,
  perfActionList_encode ,
  perfActionList_decode ,
}
/* App_Types_Date-DvmAdminFrontendFr Not a pure module */
