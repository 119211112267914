// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_json from "rescript/lib/es6/js_json.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Flash_Types$DvmAdminFrontendFr from "../Flash_Types.bs.js";
import * as App_Types_Date$DvmAdminFrontendFr from "../../../common/types/App_Types_Date.bs.js";
import * as App_Types_Json$DvmAdminFrontendFr from "../../../common/types/App_Types_Json.bs.js";
import * as App_Types_Input$DvmAdminFrontendFr from "../../../common/types/App_Types_Input.bs.js";

function toString(status) {
  switch (status) {
    case /* MADA */0 :
        return "MADA";
    case /* MADC */1 :
        return "MADC";
    case /* NOTSET */2 :
        return "";
    
  }
}

function fromString(status) {
  switch (status) {
    case "MADA" :
        return /* MADA */0;
    case "MADC" :
        return /* MADC */1;
    default:
      return /* NOTSET */2;
  }
}

var Decco$1 = App_Types_Json$DvmAdminFrontendFr.MakeStringableJsonType({
      toString: toString,
      fromString: fromString
    });

var StocDate = {
  toString: toString,
  fromString: fromString,
  Decco: Decco$1
};

function toString$1(status) {
  switch (status) {
    case /* SO */0 :
        return "SO";
    case /* CL */1 :
        return "CL";
    case /* VE */2 :
        return "VE";
    case /* VD */3 :
        return "VD";
    case /* RR */4 :
        return "RR";
    case /* RC */5 :
        return "RC";
    case /* ES */6 :
        return "ES";
    case /* EP */7 :
        return "EP";
    case /* NOTSET */8 :
        return "";
    
  }
}

function fromString$1(status) {
  switch (status) {
    case "CL" :
        return /* CL */1;
    case "EP" :
        return /* EP */7;
    case "ES" :
        return /* ES */6;
    case "RC" :
        return /* RC */5;
    case "RR" :
        return /* RR */4;
    case "SO" :
        return /* SO */0;
    case "VD" :
        return /* VD */3;
    case "VE" :
        return /* VE */2;
    default:
      return ;
  }
}

var Decco$2 = App_Types_Json$DvmAdminFrontendFr.MakeStringableJsonType({
      toString: toString$1,
      fromString: fromString$1
    });

var OrderType = {
  toString: toString$1,
  fromString: fromString$1,
  Decco: Decco$2
};

function toString$2(status) {
  switch (status) {
    case /* ALL */0 :
        return "ALL";
    case /* SELECTION */1 :
        return "SELECTION";
    case /* NOTSET */2 :
        return "NONE";
    
  }
}

function fromString$2(status) {
  switch (status) {
    case "ALL" :
        return /* ALL */0;
    case "SELECTION" :
        return /* SELECTION */1;
    default:
      return /* NOTSET */2;
  }
}

var Decco$3 = App_Types_Json$DvmAdminFrontendFr.MakeStringableJsonType({
      toString: toString$2,
      fromString: fromString$2
    });

var FinancialCodeOrientation = {
  toString: toString$2,
  fromString: fromString$2,
  Decco: Decco$3
};

function toString$3(status) {
  switch (status) {
    case /* VP */0 :
        return "VP";
    case /* VU */1 :
        return "VU";
    case /* VPVU */2 :
        return "VPVU";
    case /* NOTSET */3 :
        return "";
    
  }
}

function fromString$3(status) {
  switch (status) {
    case "VP" :
        return /* VP */0;
    case "VPVU" :
        return /* VPVU */2;
    case "VU" :
        return /* VU */1;
    default:
      return /* NOTSET */3;
  }
}

var Decco$4 = App_Types_Json$DvmAdminFrontendFr.MakeStringableJsonType({
      toString: toString$3,
      fromString: fromString$3
    });

var VehicleType = {
  toString: toString$3,
  fromString: fromString$3,
  Decco: Decco$4
};

function amountType_encode(v) {
  return Js_dict.fromArray([
              [
                "value",
                Decco.optionToJson(Decco.floatToJson, v.value)
              ],
              [
                "numberType",
                Decco.optionToJson(Flash_Types$DvmAdminFrontendFr.ValueType.Decco.t_encode, v.numberType)
              ]
            ]);
}

function amountType_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var value = Decco.optionFromJson(Decco.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "value"), null));
  if (value.TAG === /* Ok */0) {
    var numberType = Decco.optionFromJson(Flash_Types$DvmAdminFrontendFr.ValueType.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "numberType"), null));
    if (numberType.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                value: value._0,
                numberType: numberType._0
              }
            };
    }
    var e = numberType._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".numberType" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = value._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".value" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function brandsType_encode(v) {
  return Js_dict.fromArray([
              [
                "brands",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.brands)
              ],
              [
                "ie",
                Decco.optionToJson(Flash_Types$DvmAdminFrontendFr.Acceptance.Decco.t_encode, v.ie)
              ]
            ]);
}

function brandsType_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var brands = Decco.optionFromJson((function (param) {
          return Decco.listFromJson(Decco.stringFromJson, param);
        }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "brands"), null));
  if (brands.TAG === /* Ok */0) {
    var ie = Decco.optionFromJson(Flash_Types$DvmAdminFrontendFr.Acceptance.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "ie"), null));
    if (ie.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                brands: brands._0,
                ie: ie._0
              }
            };
    }
    var e = ie._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".ie" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = brands._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".brands" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "actionId",
                Decco.stringToJson(v.actionId)
              ],
              [
                "actionLabel",
                Decco.stringToJson(v.actionLabel)
              ],
              [
                "actionType",
                Decco.optionToJson(Decco.stringToJson, v.actionType)
              ],
              [
                "budgetType",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.budgetType)
              ],
              [
                "orderTypes",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco$2.t_encode, param);
                      }), v.orderTypes)
              ],
              [
                "orderStartDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.orderStartDate)
              ],
              [
                "orderEndDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.orderEndDate)
              ],
              [
                "deliveryStartDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.deliveryStartDate)
              ],
              [
                "deliveryEndDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.deliveryEndDate)
              ],
              [
                "deliveryDateRequired",
                Decco.optionToJson(Decco.boolToJson, v.deliveryDateRequired)
              ],
              [
                "registrationStartDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.registrationStartDate)
              ],
              [
                "registrationEndDate",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.registrationEndDate)
              ],
              [
                "registrationDateRequired",
                Decco.optionToJson(Decco.boolToJson, v.registrationDateRequired)
              ],
              [
                "stocDateStart",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.stocDateStart)
              ],
              [
                "stocDateEnd",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.stocDateEnd)
              ],
              [
                "stocDateType",
                Decco.optionToJson(Decco$1.t_encode, v.stocDateType)
              ],
              [
                "amount",
                Decco.optionToJson(amountType_encode, v.amount)
              ],
              [
                "carAgeAtOrderDate",
                Decco.optionToJson(Decco.intToJson, v.carAgeAtOrderDate)
              ],
              [
                "carAgeAtDeliveryDate",
                Decco.optionToJson(Decco.intToJson, v.carAgeAtDeliveryDate)
              ],
              [
                "possesionPeriodAtOrderDate",
                Decco.optionToJson(Decco.intToJson, v.possesionPeriodAtOrderDate)
              ],
              [
                "possesionPeriodAtDeliveryDate",
                Decco.optionToJson(Decco.intToJson, v.possesionPeriodAtDeliveryDate)
              ],
              [
                "brands",
                Decco.optionToJson(brandsType_encode, v.brands)
              ],
              [
                "perfType",
                Decco.optionToJson(Decco.stringToJson, v.perfType)
              ],
              [
                "carAgeAtOrderSelected",
                Decco.optionToJson(Decco.boolToJson, v.carAgeAtOrderSelected)
              ],
              [
                "carAgeAtDeliverySelected",
                Decco.optionToJson(Decco.boolToJson, v.carAgeAtDeliverySelected)
              ],
              [
                "possessionPeriodAtOrderSelected",
                Decco.optionToJson(Decco.boolToJson, v.possessionPeriodAtOrderSelected)
              ],
              [
                "possessionPeriodAtDeliverySelected",
                Decco.optionToJson(Decco.boolToJson, v.possessionPeriodAtDeliverySelected)
              ],
              [
                "financialCodeOrientation",
                Decco.optionToJson(Decco$3.t_encode, v.financialCodeOrientation)
              ],
              [
                "financialCodes",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.financialCodes)
              ],
              [
                "vehicleType",
                Decco.optionToJson(Decco$4.t_encode, v.vehicleType)
              ],
              [
                "usedCarModels",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.usedCarModels)
              ],
              [
                "withUsedCar",
                Decco.optionToJson(Decco.boolToJson, v.withUsedCar)
              ],
              [
                "entryParcDatePeriodStart",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.entryParcDatePeriodStart)
              ],
              [
                "entryParcDatePeriodEnd",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.entryParcDatePeriodEnd)
              ],
              [
                "exitParcDatePeriodStart",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.exitParcDatePeriodStart)
              ],
              [
                "exitParcDatePeriodEnd",
                Decco.optionToJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_encode, v.exitParcDatePeriodEnd)
              ],
              [
                "detentionMin",
                Decco.optionToJson(Decco.intToJson, v.detentionMin)
              ],
              [
                "detentionMax",
                Decco.optionToJson(Decco.intToJson, v.detentionMax)
              ],
              [
                "priceType",
                Decco.optionToJson(Decco.stringToJson, v.priceType)
              ],
              [
                "isPARCExit",
                Decco.optionToJson(Decco.boolToJson, v.isPARCExit)
              ],
              [
                "dealerIds",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.dealerIds)
              ],
              [
                "dealerWithAgents",
                Decco.optionToJson(App_Types_Input$DvmAdminFrontendFr.parentChildList_encode, v.dealerWithAgents)
              ],
              [
                "requestType",
                Decco.optionToJson(Decco.stringToJson, v.requestType)
              ],
              [
                "clientTypes",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.clientTypes)
              ],
              [
                "saleTypes",
                Decco.optionToJson((function (param) {
                        return Decco.listToJson(Decco.stringToJson, param);
                      }), v.saleTypes)
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var actionId = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionId"), null));
  if (actionId.TAG === /* Ok */0) {
    var actionLabel = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionLabel"), null));
    if (actionLabel.TAG === /* Ok */0) {
      var actionType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "actionType"), null));
      if (actionType.TAG === /* Ok */0) {
        var budgetType = Decco.optionFromJson((function (param) {
                return Decco.listFromJson(Decco.stringFromJson, param);
              }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "budgetType"), null));
        if (budgetType.TAG === /* Ok */0) {
          var orderTypes = Decco.optionFromJson((function (param) {
                  return Decco.listFromJson(Decco$2.t_decode, param);
                }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderTypes"), null));
          if (orderTypes.TAG === /* Ok */0) {
            var orderStartDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderStartDate"), null));
            if (orderStartDate.TAG === /* Ok */0) {
              var orderEndDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "orderEndDate"), null));
              if (orderEndDate.TAG === /* Ok */0) {
                var deliveryStartDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "deliveryStartDate"), null));
                if (deliveryStartDate.TAG === /* Ok */0) {
                  var deliveryEndDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "deliveryEndDate"), null));
                  if (deliveryEndDate.TAG === /* Ok */0) {
                    var deliveryDateRequired = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "deliveryDateRequired"), null));
                    if (deliveryDateRequired.TAG === /* Ok */0) {
                      var registrationStartDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "registrationStartDate"), null));
                      if (registrationStartDate.TAG === /* Ok */0) {
                        var registrationEndDate = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "registrationEndDate"), null));
                        if (registrationEndDate.TAG === /* Ok */0) {
                          var registrationDateRequired = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "registrationDateRequired"), null));
                          if (registrationDateRequired.TAG === /* Ok */0) {
                            var stocDateStart = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "stocDateStart"), null));
                            if (stocDateStart.TAG === /* Ok */0) {
                              var stocDateEnd = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "stocDateEnd"), null));
                              if (stocDateEnd.TAG === /* Ok */0) {
                                var stocDateType = Decco.optionFromJson(Decco$1.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "stocDateType"), null));
                                if (stocDateType.TAG === /* Ok */0) {
                                  var amount = Decco.optionFromJson(amountType_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "amount"), null));
                                  if (amount.TAG === /* Ok */0) {
                                    var carAgeAtOrderDate = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "carAgeAtOrderDate"), null));
                                    if (carAgeAtOrderDate.TAG === /* Ok */0) {
                                      var carAgeAtDeliveryDate = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "carAgeAtDeliveryDate"), null));
                                      if (carAgeAtDeliveryDate.TAG === /* Ok */0) {
                                        var possesionPeriodAtOrderDate = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "possesionPeriodAtOrderDate"), null));
                                        if (possesionPeriodAtOrderDate.TAG === /* Ok */0) {
                                          var possesionPeriodAtDeliveryDate = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "possesionPeriodAtDeliveryDate"), null));
                                          if (possesionPeriodAtDeliveryDate.TAG === /* Ok */0) {
                                            var brands = Decco.optionFromJson(brandsType_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "brands"), null));
                                            if (brands.TAG === /* Ok */0) {
                                              var perfType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "perfType"), null));
                                              if (perfType.TAG === /* Ok */0) {
                                                var carAgeAtOrderSelected = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "carAgeAtOrderSelected"), null));
                                                if (carAgeAtOrderSelected.TAG === /* Ok */0) {
                                                  var carAgeAtDeliverySelected = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "carAgeAtDeliverySelected"), null));
                                                  if (carAgeAtDeliverySelected.TAG === /* Ok */0) {
                                                    var possessionPeriodAtOrderSelected = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "possessionPeriodAtOrderSelected"), null));
                                                    if (possessionPeriodAtOrderSelected.TAG === /* Ok */0) {
                                                      var possessionPeriodAtDeliverySelected = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "possessionPeriodAtDeliverySelected"), null));
                                                      if (possessionPeriodAtDeliverySelected.TAG === /* Ok */0) {
                                                        var financialCodeOrientation = Decco.optionFromJson(Decco$3.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "financialCodeOrientation"), null));
                                                        if (financialCodeOrientation.TAG === /* Ok */0) {
                                                          var financialCodes = Decco.optionFromJson((function (param) {
                                                                  return Decco.listFromJson(Decco.stringFromJson, param);
                                                                }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "financialCodes"), null));
                                                          if (financialCodes.TAG === /* Ok */0) {
                                                            var vehicleType = Decco.optionFromJson(Decco$4.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "vehicleType"), null));
                                                            if (vehicleType.TAG === /* Ok */0) {
                                                              var usedCarModels = Decco.optionFromJson((function (param) {
                                                                      return Decco.listFromJson(Decco.stringFromJson, param);
                                                                    }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "usedCarModels"), null));
                                                              if (usedCarModels.TAG === /* Ok */0) {
                                                                var withUsedCar = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "withUsedCar"), null));
                                                                if (withUsedCar.TAG === /* Ok */0) {
                                                                  var entryParcDatePeriodStart = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "entryParcDatePeriodStart"), null));
                                                                  if (entryParcDatePeriodStart.TAG === /* Ok */0) {
                                                                    var entryParcDatePeriodEnd = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "entryParcDatePeriodEnd"), null));
                                                                    if (entryParcDatePeriodEnd.TAG === /* Ok */0) {
                                                                      var exitParcDatePeriodStart = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "exitParcDatePeriodStart"), null));
                                                                      if (exitParcDatePeriodStart.TAG === /* Ok */0) {
                                                                        var exitParcDatePeriodEnd = Decco.optionFromJson(App_Types_Date$DvmAdminFrontendFr.Decco.t_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "exitParcDatePeriodEnd"), null));
                                                                        if (exitParcDatePeriodEnd.TAG === /* Ok */0) {
                                                                          var detentionMin = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "detentionMin"), null));
                                                                          if (detentionMin.TAG === /* Ok */0) {
                                                                            var detentionMax = Decco.optionFromJson(Decco.intFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "detentionMax"), null));
                                                                            if (detentionMax.TAG === /* Ok */0) {
                                                                              var priceType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "priceType"), null));
                                                                              if (priceType.TAG === /* Ok */0) {
                                                                                var isPARCExit = Decco.optionFromJson(Decco.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "isPARCExit"), null));
                                                                                if (isPARCExit.TAG === /* Ok */0) {
                                                                                  var dealerIds = Decco.optionFromJson((function (param) {
                                                                                          return Decco.listFromJson(Decco.stringFromJson, param);
                                                                                        }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerIds"), null));
                                                                                  if (dealerIds.TAG === /* Ok */0) {
                                                                                    var dealerWithAgents = Decco.optionFromJson(App_Types_Input$DvmAdminFrontendFr.parentChildList_decode, Belt_Option.getWithDefault(Js_dict.get(dict$1, "dealerWithAgents"), null));
                                                                                    if (dealerWithAgents.TAG === /* Ok */0) {
                                                                                      var requestType = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict$1, "requestType"), null));
                                                                                      if (requestType.TAG === /* Ok */0) {
                                                                                        var clientTypes = Decco.optionFromJson((function (param) {
                                                                                                return Decco.listFromJson(Decco.stringFromJson, param);
                                                                                              }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "clientTypes"), null));
                                                                                        if (clientTypes.TAG === /* Ok */0) {
                                                                                          var saleTypes = Decco.optionFromJson((function (param) {
                                                                                                  return Decco.listFromJson(Decco.stringFromJson, param);
                                                                                                }), Belt_Option.getWithDefault(Js_dict.get(dict$1, "saleTypes"), null));
                                                                                          if (saleTypes.TAG === /* Ok */0) {
                                                                                            return {
                                                                                                    TAG: /* Ok */0,
                                                                                                    _0: {
                                                                                                      actionId: actionId._0,
                                                                                                      actionLabel: actionLabel._0,
                                                                                                      actionType: actionType._0,
                                                                                                      budgetType: budgetType._0,
                                                                                                      orderTypes: orderTypes._0,
                                                                                                      orderStartDate: orderStartDate._0,
                                                                                                      orderEndDate: orderEndDate._0,
                                                                                                      deliveryStartDate: deliveryStartDate._0,
                                                                                                      deliveryEndDate: deliveryEndDate._0,
                                                                                                      deliveryDateRequired: deliveryDateRequired._0,
                                                                                                      registrationStartDate: registrationStartDate._0,
                                                                                                      registrationEndDate: registrationEndDate._0,
                                                                                                      registrationDateRequired: registrationDateRequired._0,
                                                                                                      stocDateStart: stocDateStart._0,
                                                                                                      stocDateEnd: stocDateEnd._0,
                                                                                                      stocDateType: stocDateType._0,
                                                                                                      amount: amount._0,
                                                                                                      carAgeAtOrderDate: carAgeAtOrderDate._0,
                                                                                                      carAgeAtDeliveryDate: carAgeAtDeliveryDate._0,
                                                                                                      possesionPeriodAtOrderDate: possesionPeriodAtOrderDate._0,
                                                                                                      possesionPeriodAtDeliveryDate: possesionPeriodAtDeliveryDate._0,
                                                                                                      brands: brands._0,
                                                                                                      perfType: perfType._0,
                                                                                                      carAgeAtOrderSelected: carAgeAtOrderSelected._0,
                                                                                                      carAgeAtDeliverySelected: carAgeAtDeliverySelected._0,
                                                                                                      possessionPeriodAtOrderSelected: possessionPeriodAtOrderSelected._0,
                                                                                                      possessionPeriodAtDeliverySelected: possessionPeriodAtDeliverySelected._0,
                                                                                                      financialCodeOrientation: financialCodeOrientation._0,
                                                                                                      financialCodes: financialCodes._0,
                                                                                                      vehicleType: vehicleType._0,
                                                                                                      usedCarModels: usedCarModels._0,
                                                                                                      withUsedCar: withUsedCar._0,
                                                                                                      entryParcDatePeriodStart: entryParcDatePeriodStart._0,
                                                                                                      entryParcDatePeriodEnd: entryParcDatePeriodEnd._0,
                                                                                                      exitParcDatePeriodStart: exitParcDatePeriodStart._0,
                                                                                                      exitParcDatePeriodEnd: exitParcDatePeriodEnd._0,
                                                                                                      detentionMin: detentionMin._0,
                                                                                                      detentionMax: detentionMax._0,
                                                                                                      priceType: priceType._0,
                                                                                                      isPARCExit: isPARCExit._0,
                                                                                                      dealerIds: dealerIds._0,
                                                                                                      dealerWithAgents: dealerWithAgents._0,
                                                                                                      requestType: requestType._0,
                                                                                                      clientTypes: clientTypes._0,
                                                                                                      saleTypes: saleTypes._0
                                                                                                    }
                                                                                                  };
                                                                                          }
                                                                                          var e = saleTypes._0;
                                                                                          return {
                                                                                                  TAG: /* Error */1,
                                                                                                  _0: {
                                                                                                    path: ".saleTypes" + e.path,
                                                                                                    message: e.message,
                                                                                                    value: e.value
                                                                                                  }
                                                                                                };
                                                                                        }
                                                                                        var e$1 = clientTypes._0;
                                                                                        return {
                                                                                                TAG: /* Error */1,
                                                                                                _0: {
                                                                                                  path: ".clientTypes" + e$1.path,
                                                                                                  message: e$1.message,
                                                                                                  value: e$1.value
                                                                                                }
                                                                                              };
                                                                                      }
                                                                                      var e$2 = requestType._0;
                                                                                      return {
                                                                                              TAG: /* Error */1,
                                                                                              _0: {
                                                                                                path: ".requestType" + e$2.path,
                                                                                                message: e$2.message,
                                                                                                value: e$2.value
                                                                                              }
                                                                                            };
                                                                                    }
                                                                                    var e$3 = dealerWithAgents._0;
                                                                                    return {
                                                                                            TAG: /* Error */1,
                                                                                            _0: {
                                                                                              path: ".dealerWithAgents" + e$3.path,
                                                                                              message: e$3.message,
                                                                                              value: e$3.value
                                                                                            }
                                                                                          };
                                                                                  }
                                                                                  var e$4 = dealerIds._0;
                                                                                  return {
                                                                                          TAG: /* Error */1,
                                                                                          _0: {
                                                                                            path: ".dealerIds" + e$4.path,
                                                                                            message: e$4.message,
                                                                                            value: e$4.value
                                                                                          }
                                                                                        };
                                                                                }
                                                                                var e$5 = isPARCExit._0;
                                                                                return {
                                                                                        TAG: /* Error */1,
                                                                                        _0: {
                                                                                          path: ".isPARCExit" + e$5.path,
                                                                                          message: e$5.message,
                                                                                          value: e$5.value
                                                                                        }
                                                                                      };
                                                                              }
                                                                              var e$6 = priceType._0;
                                                                              return {
                                                                                      TAG: /* Error */1,
                                                                                      _0: {
                                                                                        path: ".priceType" + e$6.path,
                                                                                        message: e$6.message,
                                                                                        value: e$6.value
                                                                                      }
                                                                                    };
                                                                            }
                                                                            var e$7 = detentionMax._0;
                                                                            return {
                                                                                    TAG: /* Error */1,
                                                                                    _0: {
                                                                                      path: ".detentionMax" + e$7.path,
                                                                                      message: e$7.message,
                                                                                      value: e$7.value
                                                                                    }
                                                                                  };
                                                                          }
                                                                          var e$8 = detentionMin._0;
                                                                          return {
                                                                                  TAG: /* Error */1,
                                                                                  _0: {
                                                                                    path: ".detentionMin" + e$8.path,
                                                                                    message: e$8.message,
                                                                                    value: e$8.value
                                                                                  }
                                                                                };
                                                                        }
                                                                        var e$9 = exitParcDatePeriodEnd._0;
                                                                        return {
                                                                                TAG: /* Error */1,
                                                                                _0: {
                                                                                  path: ".exitParcDatePeriodEnd" + e$9.path,
                                                                                  message: e$9.message,
                                                                                  value: e$9.value
                                                                                }
                                                                              };
                                                                      }
                                                                      var e$10 = exitParcDatePeriodStart._0;
                                                                      return {
                                                                              TAG: /* Error */1,
                                                                              _0: {
                                                                                path: ".exitParcDatePeriodStart" + e$10.path,
                                                                                message: e$10.message,
                                                                                value: e$10.value
                                                                              }
                                                                            };
                                                                    }
                                                                    var e$11 = entryParcDatePeriodEnd._0;
                                                                    return {
                                                                            TAG: /* Error */1,
                                                                            _0: {
                                                                              path: ".entryParcDatePeriodEnd" + e$11.path,
                                                                              message: e$11.message,
                                                                              value: e$11.value
                                                                            }
                                                                          };
                                                                  }
                                                                  var e$12 = entryParcDatePeriodStart._0;
                                                                  return {
                                                                          TAG: /* Error */1,
                                                                          _0: {
                                                                            path: ".entryParcDatePeriodStart" + e$12.path,
                                                                            message: e$12.message,
                                                                            value: e$12.value
                                                                          }
                                                                        };
                                                                }
                                                                var e$13 = withUsedCar._0;
                                                                return {
                                                                        TAG: /* Error */1,
                                                                        _0: {
                                                                          path: ".withUsedCar" + e$13.path,
                                                                          message: e$13.message,
                                                                          value: e$13.value
                                                                        }
                                                                      };
                                                              }
                                                              var e$14 = usedCarModels._0;
                                                              return {
                                                                      TAG: /* Error */1,
                                                                      _0: {
                                                                        path: ".usedCarModels" + e$14.path,
                                                                        message: e$14.message,
                                                                        value: e$14.value
                                                                      }
                                                                    };
                                                            }
                                                            var e$15 = vehicleType._0;
                                                            return {
                                                                    TAG: /* Error */1,
                                                                    _0: {
                                                                      path: ".vehicleType" + e$15.path,
                                                                      message: e$15.message,
                                                                      value: e$15.value
                                                                    }
                                                                  };
                                                          }
                                                          var e$16 = financialCodes._0;
                                                          return {
                                                                  TAG: /* Error */1,
                                                                  _0: {
                                                                    path: ".financialCodes" + e$16.path,
                                                                    message: e$16.message,
                                                                    value: e$16.value
                                                                  }
                                                                };
                                                        }
                                                        var e$17 = financialCodeOrientation._0;
                                                        return {
                                                                TAG: /* Error */1,
                                                                _0: {
                                                                  path: ".financialCodeOrientation" + e$17.path,
                                                                  message: e$17.message,
                                                                  value: e$17.value
                                                                }
                                                              };
                                                      }
                                                      var e$18 = possessionPeriodAtDeliverySelected._0;
                                                      return {
                                                              TAG: /* Error */1,
                                                              _0: {
                                                                path: ".possessionPeriodAtDeliverySelected" + e$18.path,
                                                                message: e$18.message,
                                                                value: e$18.value
                                                              }
                                                            };
                                                    }
                                                    var e$19 = possessionPeriodAtOrderSelected._0;
                                                    return {
                                                            TAG: /* Error */1,
                                                            _0: {
                                                              path: ".possessionPeriodAtOrderSelected" + e$19.path,
                                                              message: e$19.message,
                                                              value: e$19.value
                                                            }
                                                          };
                                                  }
                                                  var e$20 = carAgeAtDeliverySelected._0;
                                                  return {
                                                          TAG: /* Error */1,
                                                          _0: {
                                                            path: ".carAgeAtDeliverySelected" + e$20.path,
                                                            message: e$20.message,
                                                            value: e$20.value
                                                          }
                                                        };
                                                }
                                                var e$21 = carAgeAtOrderSelected._0;
                                                return {
                                                        TAG: /* Error */1,
                                                        _0: {
                                                          path: ".carAgeAtOrderSelected" + e$21.path,
                                                          message: e$21.message,
                                                          value: e$21.value
                                                        }
                                                      };
                                              }
                                              var e$22 = perfType._0;
                                              return {
                                                      TAG: /* Error */1,
                                                      _0: {
                                                        path: ".perfType" + e$22.path,
                                                        message: e$22.message,
                                                        value: e$22.value
                                                      }
                                                    };
                                            }
                                            var e$23 = brands._0;
                                            return {
                                                    TAG: /* Error */1,
                                                    _0: {
                                                      path: ".brands" + e$23.path,
                                                      message: e$23.message,
                                                      value: e$23.value
                                                    }
                                                  };
                                          }
                                          var e$24 = possesionPeriodAtDeliveryDate._0;
                                          return {
                                                  TAG: /* Error */1,
                                                  _0: {
                                                    path: ".possesionPeriodAtDeliveryDate" + e$24.path,
                                                    message: e$24.message,
                                                    value: e$24.value
                                                  }
                                                };
                                        }
                                        var e$25 = possesionPeriodAtOrderDate._0;
                                        return {
                                                TAG: /* Error */1,
                                                _0: {
                                                  path: ".possesionPeriodAtOrderDate" + e$25.path,
                                                  message: e$25.message,
                                                  value: e$25.value
                                                }
                                              };
                                      }
                                      var e$26 = carAgeAtDeliveryDate._0;
                                      return {
                                              TAG: /* Error */1,
                                              _0: {
                                                path: ".carAgeAtDeliveryDate" + e$26.path,
                                                message: e$26.message,
                                                value: e$26.value
                                              }
                                            };
                                    }
                                    var e$27 = carAgeAtOrderDate._0;
                                    return {
                                            TAG: /* Error */1,
                                            _0: {
                                              path: ".carAgeAtOrderDate" + e$27.path,
                                              message: e$27.message,
                                              value: e$27.value
                                            }
                                          };
                                  }
                                  var e$28 = amount._0;
                                  return {
                                          TAG: /* Error */1,
                                          _0: {
                                            path: ".amount" + e$28.path,
                                            message: e$28.message,
                                            value: e$28.value
                                          }
                                        };
                                }
                                var e$29 = stocDateType._0;
                                return {
                                        TAG: /* Error */1,
                                        _0: {
                                          path: ".stocDateType" + e$29.path,
                                          message: e$29.message,
                                          value: e$29.value
                                        }
                                      };
                              }
                              var e$30 = stocDateEnd._0;
                              return {
                                      TAG: /* Error */1,
                                      _0: {
                                        path: ".stocDateEnd" + e$30.path,
                                        message: e$30.message,
                                        value: e$30.value
                                      }
                                    };
                            }
                            var e$31 = stocDateStart._0;
                            return {
                                    TAG: /* Error */1,
                                    _0: {
                                      path: ".stocDateStart" + e$31.path,
                                      message: e$31.message,
                                      value: e$31.value
                                    }
                                  };
                          }
                          var e$32 = registrationDateRequired._0;
                          return {
                                  TAG: /* Error */1,
                                  _0: {
                                    path: ".registrationDateRequired" + e$32.path,
                                    message: e$32.message,
                                    value: e$32.value
                                  }
                                };
                        }
                        var e$33 = registrationEndDate._0;
                        return {
                                TAG: /* Error */1,
                                _0: {
                                  path: ".registrationEndDate" + e$33.path,
                                  message: e$33.message,
                                  value: e$33.value
                                }
                              };
                      }
                      var e$34 = registrationStartDate._0;
                      return {
                              TAG: /* Error */1,
                              _0: {
                                path: ".registrationStartDate" + e$34.path,
                                message: e$34.message,
                                value: e$34.value
                              }
                            };
                    }
                    var e$35 = deliveryDateRequired._0;
                    return {
                            TAG: /* Error */1,
                            _0: {
                              path: ".deliveryDateRequired" + e$35.path,
                              message: e$35.message,
                              value: e$35.value
                            }
                          };
                  }
                  var e$36 = deliveryEndDate._0;
                  return {
                          TAG: /* Error */1,
                          _0: {
                            path: ".deliveryEndDate" + e$36.path,
                            message: e$36.message,
                            value: e$36.value
                          }
                        };
                }
                var e$37 = deliveryStartDate._0;
                return {
                        TAG: /* Error */1,
                        _0: {
                          path: ".deliveryStartDate" + e$37.path,
                          message: e$37.message,
                          value: e$37.value
                        }
                      };
              }
              var e$38 = orderEndDate._0;
              return {
                      TAG: /* Error */1,
                      _0: {
                        path: ".orderEndDate" + e$38.path,
                        message: e$38.message,
                        value: e$38.value
                      }
                    };
            }
            var e$39 = orderStartDate._0;
            return {
                    TAG: /* Error */1,
                    _0: {
                      path: ".orderStartDate" + e$39.path,
                      message: e$39.message,
                      value: e$39.value
                    }
                  };
          }
          var e$40 = orderTypes._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: ".orderTypes" + e$40.path,
                    message: e$40.message,
                    value: e$40.value
                  }
                };
        }
        var e$41 = budgetType._0;
        return {
                TAG: /* Error */1,
                _0: {
                  path: ".budgetType" + e$41.path,
                  message: e$41.message,
                  value: e$41.value
                }
              };
      }
      var e$42 = actionType._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".actionType" + e$42.path,
                message: e$42.message,
                value: e$42.value
              }
            };
    }
    var e$43 = actionLabel._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".actionLabel" + e$43.path,
              message: e$43.message,
              value: e$43.value
            }
          };
  }
  var e$44 = actionId._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".actionId" + e$44.path,
            message: e$44.message,
            value: e$44.value
          }
        };
}

var Flash = {
  amountType_encode: amountType_encode,
  amountType_decode: amountType_decode,
  brandsType_encode: brandsType_encode,
  brandsType_decode: brandsType_decode,
  t_encode: t_encode,
  t_decode: t_decode
};

function brandsType_encode$1(v) {
  return Js_dict.fromArray([
              [
                "brandCode",
                Decco.stringToJson(v.brandCode)
              ],
              [
                "brandName",
                Decco.stringToJson(v.brandName)
              ]
            ]);
}

function brandsType_decode$1(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var brandCode = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "brandCode"), null));
  if (brandCode.TAG === /* Ok */0) {
    var brandName = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "brandName"), null));
    if (brandName.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: {
                brandCode: brandCode._0,
                brandName: brandName._0
              }
            };
    }
    var e = brandName._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".brandName" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = brandCode._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".brandCode" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function t_encode$1(v) {
  return Decco.listToJson(brandsType_encode$1, v);
}

function t_decode$1(v) {
  return Decco.listFromJson(brandsType_decode$1, v);
}

var Brands = {
  brandsType_encode: brandsType_encode$1,
  brandsType_decode: brandsType_decode$1,
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

function responseType_encode(v) {
  return Js_dict.fromArray([[
                "response",
                Decco.optionToJson(Decco.stringToJson, v.response)
              ]]);
}

function responseType_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var response = Decco.optionFromJson(Decco.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(dict._0, "response"), null));
  if (response.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: {
              response: response._0
            }
          };
  }
  var e = response._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".response" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function toString$4(actionType) {
  if (typeof actionType !== "number") {
    return actionType._0;
  }
  switch (actionType) {
    case /* STOC */0 :
        return "STOC";
    case /* PERF */1 :
        return "PERF";
    case /* REPR */2 :
        return "REPR";
    case /* NOTSET */3 :
        return "";
    
  }
}

function fromString$4(actionType, orderType) {
  if (orderType !== /* SO */0) {
    return /* NonFleet */{
            _0: actionType
          };
  }
  switch (actionType) {
    case "PERF" :
        return /* PERF */1;
    case "REPR" :
        return /* REPR */2;
    case "STOC" :
        return /* STOC */0;
    default:
      return /* NOTSET */3;
  }
}

var ActionTypeEdit = {
  toString: toString$4,
  fromString: fromString$4
};

export {
  StocDate ,
  OrderType ,
  FinancialCodeOrientation ,
  VehicleType ,
  Flash ,
  Brands ,
  responseType_encode ,
  responseType_decode ,
  ActionTypeEdit ,
}
/* Decco Not a pure module */
